<template>
  <v-card
    color="yellow"
    class="highlighted-card"
    :class="{ 'highlighted-card--right': this.bestsellerImgPositionIsRight }"
    elevation="0"
  >
    <v-card-text class="py-1">
      <img
        v-if="this.useBestsellerImg"
        :src="urlImg"
        alt="best seller"
        class="highlighted-card__img"
        :class="{ 'highlighted-card__img--right': this.bestsellerImgPositionIsRight }"
      />
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    useBestsellerImg: {
      type: Boolean,
      default: false
    },
    bestsellerImgPosition: {
      type: String,
      default: 'left',
      validate: (value) => ['left', 'right'].includes(value)
    }
  },
  computed: {
    urlImg () {
      try {
        return require(`@/assets/${this.$i18n.locale}-bestseller.png`)
      } catch (e) {
        return require('@/assets/en-bestseller.png')
      }
    },
    bestsellerImgPositionIsRight () {
      return this.bestsellerImgPosition === 'right'
    }
  }
}
</script>

<style lang="scss" scoped>
.highlighted-card {
  border: 1px solid #333 !important;
  border-radius: 0.25em;
  margin-left: -25px;
  margin-right: -25px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: none;

  &--right {
   // padding-right: 50px;
  }

  .v-card__text {
    font-weight: bold;
    font-size: 12px;
  }

  &__img {
    position: absolute;
    left: 10px;
    top: 60%;
    transform: translate(-50%, -50%);

    &--right {
      left: unset;
      right: -6%;
      transform: translate(0%, -50%);

      @include media('sm-and-down') {
        right: -10px
      }
    }
  }
}
</style>
