<template>
  <div class="article mb-5">
    <h3>{{$t('landingPage.stepOne')}}</h3>
    <div class="article__list">
      <v-row class="article__list__header" justify="space-between" no-gutters>
        <v-col cols="9"> {{$t('landingPage.article')}} </v-col>
        <v-col cols="auto"> {{$t('landingPage.price')}} </v-col>
      </v-row>
      <ul class="article__list__items">
        <li
          class="article__list__item"
          v-for="(item, i) in $store.state.Product.find(p => p.routerName === this.$route.name).list.filter(l => !l.hide_checkout)"
          :key="i"
        >
          <template v-if="item.isBestSeller">
            <label :for="item.name" class="article__list__item__label">
              <HighlightedCard class="mb-3" use-bestseller-img :bestseller-img-position="$i18n.locale === 'il' ? 'right' : 'left'">
                <v-row align="center" justify="center">
                  <v-col cols="auto" class="ml-0 ml-md-3" style="line-height: 0;">
                    <input
                      type="radio"
                      name="article"
                      :id="item.name"
                      @change="
                          $store.dispatch('SET_ARTICLE', $event.target.value)
                        "
                      :value="item.name"
                      :checked="$store.state.Order.article === item.name"
                      required
                    />
                  </v-col>
                  <v-col>
                    <v-row justify="space-between">
                      <v-col class="pa-1 flex-shrink-1">
                        {{ item.name }}
                      </v-col>
                      <v-col cols="auto" class="py-0 d-flex align-center">
                        {{ item.label_price }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </HighlightedCard>
            </label>
          </template>

          <template v-else>
            <label :for="item.name" class="article__list__item__label">
              <v-row align="center" justify="center">
                <v-col cols="auto" class="ml-0 ml-md-3" style="line-height: 0">
                  <input
                    type="radio"
                    name="article"
                    :id="item.name"
                    :value="item.name"
                    @change="
                      $store.dispatch('SET_ARTICLE', $event.target.value)
                    "
                      :checked="$store.state.Order.article === item.name"
                  />
                </v-col>
                <v-col>
                  <v-row justify="space-between">
                    <v-col class="px-0 flex-shrink-1">
                      {{ item.name }}
                    </v-col>
                    <v-col cols="auto">
                      {{ item.label_price }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </label>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HighlightedCard from '@/components/Checkout/elements/HighlightedCard.vue'

export default {
  components: {
    HighlightedCard
  },
  async mounted () {
    const isSelected = this.$store.state.Product.find(p => p.routerName === this.$route.name).list.find(l => l.isSelected).name
    if (isSelected) { await this.$store.dispatch('SET_ARTICLE', isSelected) }
  },
  data () {
    return {
      headers: [
        {
          text: 'Article',
          align: 'start',
          value: 'name',
          sortable: false
        },
        {
          text: 'Prix total',
          align: 'end',
          value: 'price',
          sortable: false
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.article__list {
  &__header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.25em;
    margin-bottom: 0.5em !important;
    font-weight: bold;
  }

  &__items {
    margin: 0;
    padding: 0 !important;
    list-style: none;
  }

  &__item {
    input {
      cursor: pointer;
    }

    &__label {
      cursor: pointer;
      font-size: 12px;
    }
  }

  & .highlighted-card__img {
    @include media('sm-and-down') {
      width: 30px;
    }
  }
}
</style>
