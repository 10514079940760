<template>
  <div>
  <div class="header" v-if="active">
    <v-container class="container">
      <v-row class="row justify-end">
        <v-col cols="auto" class="text-center text-lg-left d-flex flex-row align-center">
          <v-img :src="product.bannerImg.src"  class="logo text-center" contain>
          </v-img>
          <div class="divider mx-4 my-0" />
          <v-img src="@/assets/CheckoutOrder/shield.svg" alt="shield" contain  height="20" max-width="20" class="mr-2"/>
          <div class="text-left secureCheckout">
            {{$t('landingPage2.secureCheckout')}}
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="icons align-end">
          <v-img src="@/assets/CheckoutOrder/header-icons.svg" alt="header-icons" contain max-width="320px" max-height="48px"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
    <div class="icons__mobile">
        <img class="text-center align-center justify-center" src="@/assets/CheckoutOrder/header-icons.svg" alt="header-icons"  width="75%" height="auto"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean
    },
    product: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>

.header{
  display: flex;
  position: relative;
  padding: 10px 0;
  background: #fff;
  border-bottom: 1px solid #f7f7f7;
  z-index: 10;
  -webkit-box-shadow: 1px 1px 7px rgb(0 0 0 / 37%);
  box-shadow: 1px 1px 7px rgb(0 0 0 / 37%);
  margin: 0;
  @media screen and (max-width: 678px) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.divider {
  content: "";
  background: #cecece;
  width: 1px;
  height: 30px;
}

.container {
  max-width: 970px;
  padding: 0 15px!important;
}

.row {
  padding: 10px 0;
  @media screen and (max-width: 992px) {
    padding:0
  }
}

.icons {
  max-width: 350px;
  display: block;
  @media screen and (max-width: 767px) {
   display:none
  }
}

.icons__mobile {
  background: #f7f7f7;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 768px) {
    display:none
  }
}

.logo {
  width: auto;
  max-width: 180px;
  height: auto;

  @media screen and (max-width: 678px) {
    max-width: 130px;
  }
}
.secureCheckout {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  font-family: Work Sans,sans-serif!important;
}
</style>
