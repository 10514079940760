import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { formatPrice } from '@/utils/utils'
import moment from 'moment/moment'
import CryptoJS from 'crypto-js'
import router from '@/router'

const getDefaultState = () => {
  return {
    id: null,
    public_id: null,
    token: null,
    token_card: null,
    click_id: null,
    fbclid: null,
    article: null,
    routerName: null,
    warranty: false,
    session_id: null,
    upsells: [],
    variants: [],
    finish: false,
    payment_method: 'card',
    payment_type: null,
    ip: '',
    contact: {
      last_name: null,
      first_name: null,
      email: null,
      phone: null
    },
    address: {
      currency_code: 'EUR',
      country: 'FR',
      street: null,
      city: null,
      zip_code: null,
      vat: null
    },
    customer_id: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSelectedProduct (state, getters, rootState) {
      return rootState.Product.find(p => p.list.find(p => p.name === state.article)) || {}
    },
    getSelectedArticle (state, getters, rootState) {
      return rootState.Product.find(p => p.list.find(p => p.name === state.article)).list.filter(p => p.name === state.article) || []
    },
    getArticle (state, getters, rootState) {
      return rootState.Product.find(p => p.list.find(p => p.name === state.article))
    },
    getSelectedWarranty (state, getters, rootState) {
      return state.warranty ? [rootState.Product.find(p => p.list.find(p => p.name === state.article)).warranty] : []
    },
    getSubscriptionByRouterName: (state, getters, rootState) => (routerName) => {
      const product = rootState.Product.find(p =>
        p.list.find(item => item.name === state.article)
      )
      if (!product) return false

      const upsell = product.upsell.find(u => u.routerName === routerName)
      if (upsell) return upsell.subscribe

      const abtesting = product.upsell.find(u => u.abtesting && u.abtesting.routerName === routerName)
      if (abtesting) return abtesting.abtesting.subscribe

      const downsell = product.upsell.find(u => u.downsell && u.downsell.routerName === routerName)
      if (downsell) return downsell.downsell.subscribe

      return false
    },
    getStripeByRouterName: (state, getters, rootState) => (routerName) => {
      return rootState.Product.find(p => p.routerName === routerName).stripe | false
    },
    getSelectedUpsell (state) {
      return state.upsells
    },
    getRecap (state, getters) {
      const article = getters.getSelectedArticle
      const upsell = getters.getSelectedUpsell
      const warranty = getters.getSelectedWarranty.length && getters.getSelectedWarranty[0].multiply_by_product
        ? [{
            ...getters.getSelectedWarranty[0],
            price: getters.getSelectedArticle[0].quantity * getters.getSelectedWarranty[0].price,
            label_price: formatPrice(getters.getSelectedArticle[0].quantity * getters.getSelectedWarranty[0].price / 100) + ' €',
            quantity: getters.getSelectedArticle[0].quantity
          }]
        : getters.getSelectedWarranty
      return [...article, ...warranty, ...upsell]
    },
    getCommand (state, getters) {
      const article = getters.getSelectedArticle[0]
      const product = getters.getSelectedProduct
      let lineItems = []
      if (!state.variants.length) {
        lineItems.push({
          name: article.name,
          title: article.name,
          variant_id: article.shopify_id ? article.shopify_id : product.shopify_id,
          quantity: article.quantity,
          price: (article.price / article.quantity) / 100
        })
      } else {
        lineItems = state.variants.map(variant => ({
          name: variant.type,
          title: product.name,
          variant_id: variant.shopify_id,
          quantity: 1,
          price: (article.price / article.quantity) / 100
        }))

        lineItems = Object.values(lineItems.reduce((value, object) => {
          if (value[object.variant_id]) {
            ['quantity'].forEach(key => {
              value[object.variant_id][key] = value[object.variant_id][key] + object[key]
            })
          } else {
            value[object.variant_id] = { ...object }
          }
          return value
        }, {}))
      }
      return {
        email: state.contact.email,
        line_items: lineItems,
        first_name: state.contact.first_name,
        last_name: state.contact.last_name,
        phone: state.contact.phone,
        address: state.address.street,
        city: state.address.city,
        zip: state.address.zip_code,
        country: state.address.country,
        product: state.article,
        price: article.price.toString(),
        order_id: state.contact.email,
        currency: state.address.currency_code,
        session_id: state.session_id,
        vat: state.address.vat,
        application: state.payment_type
      }
    },
    getAmount (state, getters, rootState) {
      const productList = rootState.Product.find(p => p.list.find(p => p.name === state.article))
      const product = productList.list.find(item => item.name === state.article)
      let amount = product.price
      if (state.warranty) {
        amount = productList.warranty.multiply_by_product ? amount + (productList.warranty.price * product.quantity) : amount + productList.warranty.price
      }
      return amount
    },
    getUpsellByRouterName: (state) => (routerName) => {
      return state.upsells.find(elem => elem.routerName === routerName) || {}
    },
    getVariantsOrder (state) {
      return state.variants
    }
  },
  mutations: {
    SET_ORDER (state, payload) {
      Object.assign(state, payload)
    },
    SET_ORDER_BY_CHECKOUT (state, payload) {
      state.session_id = payload.id
      state.article = payload.description
      state.token_card = payload.source.id
      state.warranty = payload.metadata.warranty.includes('true')
      state.vat = payload.metadata.vat
      state.contact.last_name = payload.customer.name.split(' ')[0]
      state.contact.first_name = payload.customer.name.split(' ')[1]
      state.contact.email = payload.customer.email
      state.contact.phone = payload.source.phone.number
      state.address.currency_code = payload.currency
      state.address.country = payload.source.billing_address.country
      state.address.street = payload.source.billing_address.address_line1
      state.address.city = payload.source.billing_address.city
      state.address.zip_code = payload.source.billing_address.zip
    },
    SET_IP (state, payload) {
      state.ip = payload
    },
    SET_CUSTOMER_ID (state, payload) {
      state.customer_id = payload
    },
    SET_PAYMENT_METHOD (state, payload) {
      state.payment_method = payload
    },
    SET_ADDRESS (state, payload) {
      state.address = payload
    },
    SET_CONTACT (state, payload) {
      state.contact = payload
    },
    SET_FINISH (state, payload) {
      state.finish = payload
    },
    SET_ARTICLE (state, value) {
      state.article = value
    },
    SET_ROUTERNAME (state, value) {
      state.routerName = value
    },
    SET_WARRANTY (state, value) {
      state.warranty = value
    },
    SET_ID (state, value) {
      state.id = value
    },
    SET_PUBLIC_ID (state, value) {
      state.public_id = value
    },
    SET_CHECKOUT (state, value) {
      state.checkout = value
    },
    SET_CLICK_ID (state, value) {
      state.click_id = value
    },
    SET_FBCLID (state, value) {
      state.fbclid = value
    },
    SET_TOKEN (state, value) {
      state.token = value
    },
    SET_UPSELL (state, value) {
      state.upsells = value
    },
    REMOVE_UPSELL (state, value) {
      state.upsells = state.upsells.map(upsell => upsell.routerName !== value)
    },
    UPDATE_UPSELL (state, value) {
      state.upsells = [...state.upsells.map(item => item.routerName !== value.routerName ? item : { ...item, ...value })]
    },
    UPDATE_VARIANT (state, value) {
      state.variants = [...state.variants.map(item => item.type !== value.type ? item : { ...item, ...value })]
    },
    SET_VARIANT (state, value) {
      state.variants = value
    },
    SET_PAYMENT_TYPE (state, value) {
      state.payment_type = value
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    SET_PAYMENT_METHOD ({ commit }, value) {
      commit('SET_PAYMENT_METHOD', value)
    },
    SET_IP ({ commit }, value) {
      commit('SET_IP', value)
    },
    SET_PAYMENT_TYPE ({ commit }, value) {
      commit('SET_PAYMENT_TYPE', value)
    },
    SET_CUSTOMER_ID ({ commit }, value) {
      commit('SET_CUSTOMER_ID', value)
    },
    SET_UPSELL ({ commit }, value) {
      commit('SET_UPSELL', value)
    },
    REMOVE_UPSELL ({ commit }, value) {
      commit('REMOVE_UPSELL', value)
    },
    SET_ADDRESS_BY_KEY ({ state, commit }, { key, value }) {
      commit('SET_ADDRESS', { ...state.address, [key]: value })
    },
    SET_CONTACT_BY_KEY ({ state, commit }, { key, value }) {
      commit('SET_CONTACT', { ...state.contact, [key]: value })
    },
    SET_ARTICLE ({ commit }, value) {
      commit('SET_ARTICLE', value)
    },
    SET_WARRANTY ({ commit }, value) {
      commit('SET_WARRANTY', value)
    },
    SET_ID ({ commit }, value) {
      commit('SET_ID', value)
    },
    SET_PUBLIC_ID ({ commit }, value) {
      commit('SET_PUBLIC_ID', value)
    },
    SET_VARIANT ({ commit }, value) {
      commit('SET_VARIANT', value)
    },
    UPDATE_UPSELL ({ commit }, value) {
      commit('UPDATE_UPSELL', value)
    },
    UPDATE_VARIANT ({ commit }, value) {
      commit('UPDATE_VARIANT', value)
    },
    async SEND_COMMAND_OVERSEE ({ getters, state, dispatch }) {
      const command = getters.getCommand
      let data = [command]
      if (state.warranty) {
        const warranty = getters.getSelectedWarranty[0]
        const lineItems = [{
          title: warranty.name,
          name: warranty.name,
          variant_id: warranty.shopify_id,
          quantity: warranty.multiply_by_product ? command.line_items[0].quantity : 1,
          price: warranty.price / 100
        }]
        const commandWarranty = {
          ...command,
          price: warranty.multiply_by_product ? (lineItems[0].quantity * lineItems[0].price).toString() : warranty.price.toString(),
          product: warranty.name,
          line_items: lineItems
        }
        data = [command, commandWarranty]
      }
      await dispatch('SAVE_OVERSEE', data)
      await dispatch('SAVE_API_OVERSEE', data)
      if (state.fbclid) {
        await dispatch('SEND_TRACKFB', { data: data, type: 'Purchase' })
      }
    },
    async SEND_UPSELL_OVERSEE ({ getters, dispatch, state }, upsellName) {
      const command = getters.getCommand
      const upsell = getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName)
      const lineItems = [{
        title: upsell.name,
        name: upsell.name,
        variant_id: upsell.shopify_id,
        quantity: upsell.quantity,
        price: (upsell.price / upsell.quantity) / 100
      }]
      const data = [{
        ...command,
        price: upsell.price.toString(),
        product: upsell.name,
        session_id: upsell.session_id,
        line_items: lineItems
      }]
      await dispatch('SAVE_OVERSEE', data)
      await dispatch('SAVE_API_OVERSEE', data)
      if (state.fbclid) {
        await dispatch('SEND_TRACKFB', { data: data, type: 'Upsell' })
      }
    },
    async GET_PAYMENTS_BY_CHECKOUT ({ state }, id) {
      try {
        const { data: responseCheckout } = await axios.get(process.env.VUE_APP_OVERSEE_CHECKOUT + '/' + id)
        return responseCheckout
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'GET_PAYMENTS_BY_CHECKOUT')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async GET_IP ({ dispatch }) {
      try {
        const { data } = await axios.get('https://overseee-api.herokuapp.com/auth' + '/' + 'ip')
        await dispatch('SET_IP', data)
      } catch (e) {
        console.log(e)
      }
    },
    async CHECKOUT_PAYMENTS_BY_CARD ({ getters, state, dispatch }, { productName, template }) {
      try {
        if (state.fbclid) {
          await dispatch('SEND_TRACKFB', {
            data: [{
              email: state.contact.email,
              phone: state.contact.phone,
              product: getters.getArticle.name + 'product',
              currency: getters.getArticle.currency,
              price: getters.getAmount,
            }],
            type: 'AddPaymentInfo'
          })
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKFB_AddPaymentInfo')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
      try {
        return await axios.post(process.env.VUE_APP_OVERSEE_CHECKOUT, {
          source: {
            type: 'token',
            token: state.token,
            billing_address: {
              address_line1: state.address.street,
              city: state.address.city,
              state: state.address.city,
              zip: state.address.zip_code,
              country: state.address.country
            },
            phone: {
              number: state.contact.phone
            }
          },
          billing_descriptor: {
            name: `${process.env.VUE_APP_APPLICATION_NAME}.com`,
            city: '+447488863584'
          },
          metadata: { warranty: state.warranty.toString(), vat: state.address.vat },
          success_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}`,
          failure_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=false`,
          processing_channel_id: process.env.VUE_APP_PROCESSING_CHANNEL_ID,
          currency: getters.getArticle.currency,
          amount: getters.getAmount,
          payment_type: 'Regular',
          merchant_initiated: false,
          reference: getters.getArticle.name + ' product',
          description: state.article,
          store_for_future_use: true,
          '3ds': {
            enabled: false,
            allow_upgrade: true
          },
          customer: {
            email: state.contact.email,
            name: state.contact.last_name + ' ' + state.contact.first_name
          }
        })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYMENTS_BY_CARD')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async SAVE_OVERSEE ({ state }, data) {
      try {
        await axios(process.env.VUE_APP_APPLICATION_COMMANDS,
          {
            method: 'POST',
            data: data
          })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SAVE_OVERSEE')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          scope.setExtra('data', data)
          Sentry.captureException(e)
        })
      }
    },
    async SAVE_API_OVERSEE ({ state }, data) {
      try {
        await axios(process.env.VUE_APP_OVERSEE_COMMANDS,
          {
            method: 'POST',
            data: { ...data[0], order_id: null }
          })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SAVE_API_OVERSEE')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          scope.setExtra('data', data)
          Sentry.captureException(e)
        })
      }
    },
    async COMMAND_OVERSEE_EXIST ({ state }) {
      try {
        return await axios(process.env.VUE_APP_COMMAND_EXIST + state.session_id, { method: 'get' })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'COMMAND_OVERSEE_EXIST')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async UPSELL_OVERSEE_EXIST ({ state, getters }, upsell) {
      const sessionId = getters.getUpsellByRouterName(upsell.routerName).session_id || null
      try {
        return await axios(process.env.VUE_APP_COMMAND_EXIST + sessionId, { method: 'get' })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'UPSELL_OVERSEE_EXIST')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async SEND_TRACK ({ state }) {
      try {
        await axios(process.env.VUE_APP_SEND_TRACK + state.contact.email, { method: 'POST', data: { id: state.click_id } })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACK')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async SEND_TRACKFB ({ state }, { data, type }) {
      try {
        const payload = {
          data: [
            {
              event_name: type,
              event_time: Math.floor(new Date().getTime() / 1000),
              action_source: 'website',
              event_source_url: process.env.VUE_APP_URL + router.app._route.path.substring(1),
              user_data: {
                em: [CryptoJS.SHA256(data[0].email).toString()],
                ph: [
                  CryptoJS.SHA256(data[0].email).toString()],
                fbc: state.fbclid,
                ...(
                  state.ip ? { client_ip_address: state.ip } : {}
                )
              },
              custom_data: {
                content_name: data[0].product,
                currency: data[0].currency,
                value: Number(data[0].price) / 100
              }
            }
          ],
          ...(
            process.env.VUE_APP_ENV !== 'PRODUCTION' ? { test_event_code: 'TEST34639' } : {}
          )
        }
        await axios(process.env.VUE_APP_FB_URL, { method: 'POST', data: { ...payload } })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKFB')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async FINISH ({ state }) {
      try {
        await axios(process.env.VUE_APP_ORDER_FINISH + state.contact.email, { method: 'GET' })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'FINISH')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYMENTS_BY_TOKEN ({ getters, state, dispatch, rootState }, upsellName) {
      try {
        const { data: UpsellResponse } = await axios.post(process.env.VUE_APP_OVERSEE_CHECKOUT, {
          source: {
            type: 'id',
            id: state.token_card
          },
          processing_channel_id: process.env.VUE_APP_PROCESSING_CHANNEL_ID,
          currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
          amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
          reference: upsellName + ' upsell',
          merchant_initiated: false,
          payment_type: 'Regular',
          billing_descriptor: {
            name: `${process.env.VUE_APP_APPLICATION_NAME}.com`,
            city: '+447488863584'
          },
          description: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).name
        })
        await dispatch('UPDATE_UPSELL', {
          ...getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName),
          session_id: UpsellResponse.id
        })
        return UpsellResponse
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYMENTS_BY_TOKEN')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYMENTS_BY_TOKEN_RECURRING ({ getters, state, dispatch, rootState, rootGetters }, {
      productName,
      upsellName
    }) {
      try {
        const indexMax = rootGetters.getUpsell(productName).length
        const indexUpsell = rootGetters.getUpsell(productName).findIndex(upsell => upsell.routerName === upsellName)
        const nextUrlUpsell = rootGetters.getRouterNameUpsellByProductRouterName(productName, indexUpsell + 1)
        const successUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=true&click_id=${state.click_id ? state.click_id : ''}&previousUpsell=${upsellName}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`
        const failureUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=false&click_id=${state.click_id ? state.click_id : ''}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=false&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`
        const { data: UpsellResponse } = await axios.post(process.env.VUE_APP_OVERSEE_CHECKOUT, {
          source: {
            type: 'id',
            id: state.token_card
          },
          payment_type: 'Recurring',
          processing_channel_id: process.env.VUE_APP_PROCESSING_CHANNEL_ID,
          currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
          amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
          success_url: successUrl,
          failure_url: failureUrl,
          reference: upsellName + ' upsell',
          merchant_initiated: false,
          previous_payment_id: state.session_id,
          billing_descriptor: {
            name: `${process.env.VUE_APP_APPLICATION_NAME}.com`,
            city: '+447488863584'
          },
          '3ds': {
            enabled: true,
            challenge_indicator: 'challenge_requested_mandate'
          },
          description: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).name
        })
        await dispatch('UPDATE_UPSELL', {
          ...getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName),
          session_id: UpsellResponse.id
        })
        return UpsellResponse
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYMENTS_BY_TOKEN')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYMENTS_BY_SOFORT ({ getters, state, dispatch }, { productName, template }) {
      try {
        return await axios.post(process.env.VUE_APP_OVERSEE_CHECKOUT, {
          source: {
            type: 'sofort',
            billing_address: {
              address_line1: state.address.street,
              city: state.address.city,
              state: state.address.city,
              zip: state.address.zip_code,
              country: state.address.country
            },
            phone: {
              number: state.contact.phone
            }
          },
          metadata: { warranty: state.warranty.toString(), vat: state.address.vat },
          success_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}`,
          failure_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=false`,
          processing_channel_id: process.env.VUE_APP_PROCESSING_CHANNEL_ID,
          description: state.article,
          reference: getters.getArticle.name + ' product',
          merchant_initiated: false,
          payment_type: 'Regular',
          currency: getters.getArticle.currency,
          amount: getters.getAmount,
          customer: {
            email: state.contact.email,
            name: state.contact.last_name + ' ' + state.contact.first_name
          }
        })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYMENTS_BY_SOFORT')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYMENTS_UPSELL_BY_SOFORT ({ getters, state, dispatch }, { successUrl, failureUrl }) {
      try {
        return await axios.post(process.env.VUE_APP_OVERSEE_CHECKOUT, {
          source: {
            type: 'sofort',
            billing_address: {
              address_line1: state.address.street,
              city: state.address.city,
              state: state.address.city,
              zip: state.address.zip_code,
              country: state.address.country
            },
            phone: {
              number: state.contact.phone
            }
          },
          metadata: { warranty: state.warranty.toString(), vat: state.address.vat },
          success_url: `${process.env.VUE_APP_URL}` + successUrl,
          failure_url: `${process.env.VUE_APP_URL}` + failureUrl,
          processing_channel_id: process.env.VUE_APP_PROCESSING_CHANNEL_ID,
          description: state.article,
          reference: getters.getArticle.name + ' product',
          payment_type: 'Regular',
          currency: getters.getArticle.currency,
          amount: getters.getAmount,
          customer: {
            email: state.contact.email,
            name: state.contact.last_name + ' ' + state.contact.first_name
          }
        })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYMENTS_BY_SOFORT')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },

    async SEND_TRACKING ({ getters, state, dispatch }, { type, tags, id, countryCode }) {
      try {
        const data = {
          tags: [tags, countryCode],
          identifiers: [
            {
              type: type,
              id: id,
              channels: {
                [type === 'phone' ? 'sms' : type]: {
                  status: 'subscribed',
                  statusDate: new Date()
                }
              }
            }]
        }
        await axios.post(process.env.VUE_APP_OMNISEND_URL, data, { headers: { 'x-api-key': process.env.VUE_APP_OMNISEND_API_KEY } })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKING')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },

    async SEND_CUSTOM_TRACKING ({ getters, state, dispatch }, { tags, countryCode }) {
      try {
        const data = {
          tags: [tags, countryCode],
          identifiers: [
            {
              type: 'email',
              id: state.contact.email,
              channels: {
                email: {
                  status: 'subscribed',
                  statusDate: new Date()
                }
              }
            },
            {
              type: 'phone',
              id: state.contact.phone,
              channels: {
                sms: {
                  status: 'subscribed',
                  statusDate: new Date()
                }
              }
            }
          ]
        }
        await axios.post(process.env.VUE_APP_OMNISEND_URL, data, { headers: { 'x-api-key': process.env.VUE_APP_OMNISEND_API_KEY } })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKING')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },

    async SEND_RECUP_EMAIL ({ getters, state, dispatch }, { url, infos, product }) {
      try {
        let template = 7
        if (product.locales === 'fr') {
          template = 6
        }
        const data = {
          to: [{
            email: infos.email
          }],
          templateId: template,
          params: {
            name: infos.first_name,
            imgproduct: product.mainImg.src,
            productname: product.name,
            producturl: url,
            logo: product.bannerImg.src,
            productdescription: product.caracteristics[0]
          },
          headers: {
            charset: 'iso-8859-1'
          }
        }
        await axios.post('https://api.sendinblue.com/v3/smtp/email', data, { headers: { 'api-key': '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' } })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_RECUP_EMAIL')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },

    async SEND_CHECKOUT_ORDER ({ getters, state, dispatch }, data) {
      try {
        await axios(process.env.VUE_APP_APPLICATION + 'orders',
          {
            method: 'POST',
            data: data
          })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_CHECKOUT_ORDER')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async GET_ORDER ({ getters, state, dispatch }, id) {
      try {
        return await axios(process.env.VUE_APP_APPLICATION + 'order/' + id,
          {
            method: 'GET'
          })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_CHECKOUT_ORDER')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async GET_APPLE_PAY_SESSION ({ state }) {
      try {
        return await axios(process.env.VUE_APP_OVERSEE_APPLE_PAY,
          {
            method: 'GET'
          })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'GET_APPLE_PAY_SESSION')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async POST_TOKEN_APPLE_PAY ({ state, dispatch, getters }, data) {
      try {
        if (state.fbclid) {
          await dispatch('SEND_TRACKFB', {
            data: [{
              email: state.contact.email,
              phone: state.contact.phone,
              product: getters.getArticle.name + 'product',
              currency: getters.getArticle.currency,
              price: getters.getAmount,
            }],
            type: 'AddPaymentInfoApple'
          })
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKFB_AddPaymentInfoAPPLE')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
      try {
        return await axios(process.env.VUE_APP_CHECKOUT_TOKEN,
          {
            method: 'POST',
            data: {
              type: 'applepay',
              token_data: {
                ...data
              }
            }
          })
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'POST_TOKEN_APPLE_PAY')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async POST_TOKEN_GOOGLE_PAY ({ state, getters, dispatch }, data) {
      try {
        if (state.fbclid) {
          await dispatch('SEND_TRACKFB', {
            data: [{
              email: state.contact.email,
              phone: state.contact.phone,
              product: getters.getArticle.name + 'product',
              currency: getters.getArticle.currency,
              price: getters.getAmount,
            }],
            type: 'AddPaymentInfoGoogle'
          })
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'SEND_TRACKFB_AddPaymentInfoGoogle')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
      try {
        return await axios(process.env.VUE_APP_CHECKOUT_TOKEN,
          {
            method: 'POST',
            data: {
              type: 'googlepay',
              token_data: {
                ...data
              }
            }
          })
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'POST_TOKEN_GOOGLE_PAY')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    SEND_MAIL_3D_SECURE_FAILED ({ state }, {
      mail,
      locale,
      name,
      productImg,
      productName,
      productUrl,
      logo,
      productDescription
    }) {
      const data = JSON.stringify({
        to: [
          {
            email: mail
          }
        ],
        templateId: locale.toLowerCase() === 'fr' ? 6 : 7,
        params: {
          name: name,
          imgproduct: productImg,
          productname: productName,
          producturl: productUrl,
          logo: logo,
          productdescription: productDescription
        },
        headers: {
          'X-Mailin-custom': 'custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3',
          charset: 'iso-8859-1'
        }
      })

      const config = {
        method: 'post',
        url: 'https://api.sendinblue.com/v3/smtp/email',
        headers: {
          accept: 'application/json',
          'api-key': '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',
          'content-type': 'application/json'
        },
        data: data
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async POST_SUBSCRIPTION ({ state, getters, dispatch }, { data, upsellName, method }) {
      try {
        const command = getters.getCommand
        const upsell = getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName)
        const lineItems = [{
          title: upsell.name,
          name: upsell.name,
          variant_id: upsell.shopify_id,
          quantity: 1,
          price: upsell.price / 100
        }]
        const commandShopify = [{
          ...command,
          price: upsell.price.toString(),
          product: upsell.name,
          session_id: upsell.session_id,
          line_items: lineItems
        }]
        await axios(process.env.VUE_APP_OVERSEE_SUBSCRIPTIONS,
          {
            method: 'POST',
            data: {
              environment: process.env.VUE_APP_ENV !== 'PRODUCTION' ? 'TEST' : 'PRODUCTION',
              date_subscription: moment().add(1, 'months').format('DD/MM/YYYY'),
              data: {
                ...data,
                command_shopify: commandShopify,
              },
              order_to_ship: upsell?.order_to_ship ?? false,
              subscription_price: upsell?.subscription_price ?? 0,
              method: method,
              application: process.env.VUE_APP_APPLICATION
            }
          })
        if (state.fbclid) {
          await dispatch('SEND_TRACKFB', { data: commandShopify, type: 'Upsell_Subscription' })
        }
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'POST_TOKEN_GOOGLE_PAY')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_STRIPE ({ getters, state, dispatch, rootState }, routerName) {
      try {
        const product = rootState.Product.find(p => p.routerName === routerName)
        const urlPath = product?.stripe ? 'stripe/create-payment-intent' : 'stripe2/create-payment-intent'
        const url = `${process.env.VUE_APP_OVERSEE_AUTH}${urlPath}`

        // Faire la requête HTTP POST
        const response = await axios.post(url, {
          address: state.address,
          customer: state.contact,
          currency: getters.getArticle.currency,
          amount: getters.getAmount,
          product: state.article
        }, {
          headers: { 'Content-Type': 'application/json' }
        })

        const responseSession = response.data

        // Dispatch des actions
        await dispatch('SET_CUSTOMER_ID', responseSession.customer)
        await dispatch('SET_PAYMENT_TYPE', 'stripe')

        return responseSession
      } catch (e) {
        console.log('CHECKOUT_STRIPE ERROR', e)

        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_STRIPE')
          scope.setExtra('state', state)
          scope.setExtra('response', e.response ? JSON.stringify(e.response.data) : 'No response data')
          Sentry.captureException(e)
        })
      }
    },

    async CHECKOUT_STRIPE_UPSELL ({ getters, state, rootState }, upsellName) {
      try {
        const product = rootState.Product.find(p => p.routerName === upsellName)
        const urlPath = product?.stripe ? 'stripe/upsells' : 'stripe2/upsells'
        const url = `${process.env.VUE_APP_OVERSEE_AUTH}${urlPath}`
        const response = await axios(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            address: state.address,
            customer: state.customer_id,
            currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
            amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
            upsell: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName)
          }),
        })
        return response.data
      } catch (e) {
        console.log('CHECKOUT_STRIPE_UPSELL', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_STRIPE_UPSELL')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_STRIPE_UPSELL_SUBSCRIPTION ({ getters, state, rootState }, upsellName) {
      try {
        const product = rootState.Product.find(p => p.routerName === upsellName)
        const urlPath = product?.stripe ? 'stripe/upsells/subscription' : 'stripe2/upsells/subscription'
        const url = `${process.env.VUE_APP_OVERSEE_AUTH}${urlPath}`
        const response = await axios(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            contact: state.contact,
            address: state.address,
            customer: state.customer_id,
            currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
            amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
            upsell: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName),
            application: process.env.VUE_APP_APPLICATION_NAME,
          }),
        })
        return response.data
      } catch (e) {
        console.log('CHECKOUT_STRIPE_UPSELL_SUBSCRIPTION', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_STRIPE_UPSELL_SUBSCRIPTION')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYPAL ({ getters, state, dispatch }, { productName, template }) {
      try {
        const response = await axios(process.env.VUE_APP_OVERSEE_AUTH + 'paypal/order', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            application: process.env.VUE_APP_APPLICATION_NAME,
            success_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}`,
            failure_url: `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/${template}/?success=false`,
            address: state.address,
            customer: state.contact,
            currency: getters.getArticle.currency,
            amount: getters.getAmount,
            product: state.article,
            order: state
          }),
        })
        return response.data
      } catch (e) {
        console.log('CHECKOUT_STRIPE ERROR', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_STRIPE')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async CHECKOUT_PAYPAL_UPSELL ({ getters, state, dispatch, rootGetters }, {
      productName,
      upsellName
    }) {
      try {
        const indexMax = rootGetters.getUpsell(productName).length
        const indexUpsell = rootGetters.getUpsell(productName).findIndex(upsell => upsell.routerName === upsellName)
        const nextUrlUpsell = rootGetters.getRouterNameUpsellByProductRouterName(productName, indexUpsell + 1)
        const successUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=true&click_id=${state.click_id ? state.click_id : ''}&previousUpsell=${upsellName}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`
        const failureUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=false&click_id=${state.click_id ? state.click_id : ''}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=false&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`

        const response = await axios(process.env.VUE_APP_OVERSEE_AUTH + 'paypal/order', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            application: process.env.VUE_APP_APPLICATION_NAME,
            success_url: successUrl,
            failure_url: failureUrl,
            address: state.address,
            customer: state.contact,
            currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
            amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
            product: state.article,
            order: state
          }),
        })
        return response.data
      } catch (e) {
        console.log('CHECKOUT_PAYPAL_UPSELL ERROR', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT_PAYPAL_UPSELL')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },

    async CHECKOUT_PAYPAL_SUBSCRIPTION ({ getters, state, dispatch, rootGetters }, {
      productName,
      upsellName
    }) {
      try {
        const indexMax = rootGetters.getUpsell(productName).length
        const indexUpsell = rootGetters.getUpsell(productName).findIndex(upsell => upsell.routerName === upsellName)
        const nextUrlUpsell = rootGetters.getRouterNameUpsellByProductRouterName(productName, indexUpsell + 1)
        const successUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=true&click_id=${state.click_id ? state.click_id : ''}&previousUpsell=${upsellName}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=true&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`
        const failureUrl = indexUpsell + 1 === indexMax
          ? `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}/thank/?success=false&click_id=${state.click_id ? state.click_id : ''}`
          : `${process.env.VUE_APP_URL}${getters.getArticle.locales}/${productName}${nextUrlUpsell}?success=false&click_id=${state.click_id ? state.click_id : ''}&fbclid=${state.fbclid ? state.fbclid : ''}&previousUpsell=${upsellName}`

        const response = await axios(process.env.VUE_APP_OVERSEE_AUTH + 'paypal/subscription', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({
            application: process.env.VUE_APP_APPLICATION_NAME,
            success_url: successUrl,
            failure_url: failureUrl,
            address: state.address,
            customer: state.contact,
            upsell: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName),
            currency: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).currency,
            amount: getters.getSelectedUpsell.find(upsell => upsell.routerName === upsellName).price,
            product: state.article,
            order: state
          }),
        })
        return response.data
      } catch (e) {
        console.log('PAYPAL_SUBSCRIPTION ERROR', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'PAYPAL_SUBSCRIPTION')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async GET_SUBSCRIPTION_BY_PAYPAL ({ state }, id) {
      try {
        const { data: responseCheckout } = await axios.get(process.env.VUE_APP_OVERSEE_AUTH + 'paypal/subscription' + '/' + id)
        return responseCheckout
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'GET_PAYMENTS_BY_CHECKOUT')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
    async GET_PAYMENTS_BY_PAYPAL ({ state }, id) {
      try {
        const { data: responseCheckout } = await axios.get(process.env.VUE_APP_OVERSEE_AUTH + 'paypal/order' + '/' + id)
        return responseCheckout
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'GET_PAYMENTS_BY_CHECKOUT')
          scope.setExtra('state', state)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
      }
    },
  }
}
