<template>
  <section class="section__messageBox">
    <v-container class="container__box justify-center align-center">
      <v-row class="row__messageBox justify-center align-center">
        <div class="text-center">
          <div class="card__messageBox">
            <div class="text__messageBox" v-if="message" v-html="message" />
          </div>
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
.row__messageBox {
  @media (max-width: 800px) {
    margin: -20px!important;
  }
}
.container__box {
  max-width: 960px;
}
.section__messageBox {
  padding-top: 22px;
  padding-bottom: 22px;
}
.card__messageBox {
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 8px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  background-color: rgb(242, 241, 243);
  border-color: rgba(155, 155, 155, 0.67);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 25px, rgba(0, 0, 0, 0.2) 0px 0px 15px, rgba(0, 0, 0, 0.4) 0px 0px 3px;
  @media (max-width: 800px) {
    padding: 5px
  }

}
.text__messageBox {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 22px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  text-align: left;
  line-height: normal;
  @media (max-width: 800px) {
    font-size: 18px;
  }
}
</style>
