<template>
  <div>
    <section>
      <header class="d-flex header">
        <v-container class="container_banner justify-center align-center">
          <v-row class="justify-center align-center">
            <v-img class="banner_image_product" :src="upsell.bannerImg.src ? upsell.bannerImg.src :product.bannerImg.src" />
          </v-row>
          <v-row class="justify-center align-center">
            <v-img class="banner_image_step" src="@/assets/Etape.png"/>
          </v-row>
        </v-container>
      </header>
      <UpsellBannerWarning v-if="upsell.alertMessage && upsell.reloadDescription" :upsell="upsell"/>
    </section>
  </div>
</template>

<script>
import UpsellBannerWarning from '@/components/Upsell/UpsellBannerWarning'
export default {
  components: { UpsellBannerWarning },
  props: {
    product: {
      type: Object,
      required: true,
    },
    upsell: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style lang="scss" scoped>

.header {
  position: static;
  display: flex;
  padding: 0px 10px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-attachment: initial;
  background-image: url(@/assets/Fondbleu.jpeg);
  @media (max-width: 960px) {
    padding: 20px 20px;
  }
}

.container_banner {
  padding-bottom: 8px;
  @media (max-width: 960px) {
    padding: 2px!important;
    margin:0!important;
  }
}

.banner_image_product,
.banner_image_step {
  display: block;
  object-fit: contain;
  width: 100%;
  max-width: 100%;
}

.banner_image_step {
  max-width: 370px;
  @media (min-width: 960px) {
    height: 109px;
      max-width: 586px;
  }
}

.banner_image_product {
  max-width: 370px;
  @media (min-width: 960px) {
    max-width: 503px;
  }
}

</style>
