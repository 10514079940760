<template>
  <section class="img-text">
    <div class="container">
      <v-card elevation="0">
        <v-card-text>
          <v-row class="img-text__row">
            <v-col cols="12" sm="6">
              <div class="img-text__img-container">
                <img
                  :src="$store.state.Product.find(p => p.routerName === this.$route.name).mainImg.src"
                  :alt="$store.state.Product.find(p => p.routerName === this.$route.name).mainImg.alt"
                  class="img-text__img-container__img"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <ul class="img-text__list">
                <li v-for="(item, i) in $store.state.Product.find(p => p.routerName === this.$route.name).caracteristics" :key="i">
                  {{ item }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    img: {
      type: Object
    },
    items: {
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.img-text {
  &__row {
    background: #fff;
    border-radius: 10px;
    min-height: 255px;
    padding: 10px 0;
  }

  &__img-container {
    width: 100%;
    height: 100%;
    position: relative;

    &__img {
      height: 100%;
      width: 100%;
      position: absolute;
      object-fit: contain;

      @include media('xs-only') {
        position: static;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-flow: column wrap;
    font-size: 16px;

    @include media('sm-and-down') {
      font-size: 12px;
    }

    li {
      text-align: center;
      background: #ebebeb;
      padding: 4px 8px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
