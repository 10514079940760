<template>
  <section class="special-offer">
    <div class="container">
      <v-row>
        <v-col>
          <p>
            <b>{{ $t("landingPage.specialOffer") }}</b>:
            {{ $store.state.Product.find(p => p.routerName === this.$route.name).name }}
            {{ $t('landingPage.available')}}
            <span class="text-decoration-line-through">
              {{$store.state.Product.find(p => p.routerName === this.$route.name).initialPrice }}</span>
            <span style="color: #35CB33">
              <b>
                {{$store.state.Product.find(p => p.routerName === this.$route.name).remisedPrice }}
              </b>
            </span>
            <b>* <i> {{$t('landingPage.reduction')}}</i></b>
            <br />
            <span>{{$t('landingPage.beforeFreeDelivery')}}</span>
            <span style="color: #35CB33">
              <b>{{$t('landingPage.freeDelivery')}}</b>
            </span>{{$t('landingPage.onEachOrder')}}<br />
            <i>{{$t('landingPage.subjectToAvailability')}}</i>
          </p>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.special-offer {
  text-align: center;
  line-height: 1;
}
</style>
