import en from './en.json'
import fr from './fr.json'
import br from './br.json'
import de from './de.json'
import es from './es.json'
import il from './il.json'
import it from './it.json'
import jp from './jp.json'

export const languages = {
  en: en,
  fr: fr,
  de: de,
  br: br,
  es: es,
  il: il,
  it: it,
  jp: jp
}
