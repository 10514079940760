/* global sendinblue */

class Brevo {
  constructor (eventName, store, route) {
    this.route = route
    this.store = store
    this.event_name = eventName
    this.properties = {
      email: this._getContact().email,
      PRENOM: this._getContact().first_name,
      NOM: this._getContact().last_name
    }
    this.event_data = {
      data: {
        logo: this._getProduct().bannerImg.src,
        productname: this._getProduct().name,
        productdescription: this._getProduct().caracteristics[0],
        producturl: process.env.VUE_APP_URL.slice(0, -1) + route.path,
        imgproduct: this._getProduct().mainImg.src,
        language: this._getProduct().locales
      }
    }
  }

  _getProduct () {
    return this.store.state.Product.find(p => p.routerName === this.route.name)
  }

  _getContact () {
    return this.store.state.Order.contact
  }

  track () {
    if (this.properties.email) {
      sendinblue.track(
        this.event_name,
        this.properties,
        this.event_data
      )
    }
  }
}

export default Brevo
