<template>
  <section class="section_alert">
    <v-container class="container__warning justify-center align-center">
      <v-row class="justify-center align-center">
        <div class="text-center">
          <p class="text_alert" v-if="upsell">
            {{ upsell.alertMessage }}
          </p>
          <p class="text_alert_description" v-if="upsell">
            {{ upsell.reloadDescription }}
          </p>
          <div class="text_alert_message" v-if="message" v-html="message" />
        </div>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    upsell: {
      type: Object,
      required: false,
    },
    message: {
      type: String,
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
.container__warning {
  @media (min-width: 1904px){
    max-width: 1500px;
  }
}
.section_alert {
  background-color: rgb(9, 124, 157);
  padding: 11px;
  padding-bottom: 9px;

  @media (max-width: 960px) {
    font-size: 17px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 9px;
    padding-top: 11px;
  }
}

.text_alert {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  margin-bottom: 0px;
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "open-sans", sans-serif;
  font-weight: 700; /* Poids de police "Bold" */
  color: rgba(252, 245, 27, 1);
  line-height: 1.35;
  @media (max-width: 960px) {
    font-size: 17px;
  }
}
.text_alert_description {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  margin-bottom: 0px;
  word-break: break-word;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-weight: 700;
  font-family: "open-sans", sans-serif;
  font-style: normal; /* Style de police normal */
  line-height: 1.35;
  margin-top: 1px;

  @media (max-width: 960px) {
    font-size: 16px;
  }
}
.text_alert_message {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  font-family: "lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: 34px;

  @media (max-width: 960px) {
    font-size: 16px;
  }
}
</style>
