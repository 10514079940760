<template>
  <div>
    <header>
      <div>
        <v-app-bar color="white">
          <v-img
            xs12 md4
            :src="application === 'NativeDiscount' ? 'https://d1yei2z3i6k35z.cloudfront.net/893893/633ed7e34d43b_logo-native-discount-1.jpg' : require('@/assets/my-onlinemarket-logo.png')"
            :max-width="$vuetify.breakpoint.smAndUp ? '300px' : '150px'"
            max-height="200"
            class="mr-5"
          ></v-img>
          <v-menu bottom offset-y mandatory >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="chip"
                :color="attrs['aria-expanded'] === 'true'? '#0C1BE6' : '#f1f1f1'"
                v-bind="attrs"
                v-on="on"
                :style="$vuetify.breakpoint.smAndUp ? {'width': '90px'} : {'width':'60px'}"
              >
                <span class="typoChip" :style="attrs['aria-expanded'] === 'true'? {'color': 'white', 'font-weight': '700'} : {}"
                >{{languages.find(lang => lang.locale === selected).locale}}</span>
                <div class="ml-8 chipIcon" :style="attrs['aria-expanded'] === 'true' ? {  'transform': 'rotate(-135deg)','margin-top': '-2px', 'border-color': 'white'}: {}"/>
              </v-chip>
            </template>
            <v-list elevation="1">
              <v-col
                v-for="(item, index) in languages"
                :key="index"
                cols="6"
                class="pa-2"
              >
                <v-list-item-title :class="selected === item.locale ? 'typoLocal selected' :'typoLocal'" @click="changeLocales(item.locale)">{{ item.value }}</v-list-item-title>
              </v-col>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn outlined style="margin-right: 10px;" :href="`https://legal.${application.toLowerCase()}.com/contact`" target="_blank" :color="application === 'NativeDiscount' ? 'light-blue darken-3':'#FF9000'">
            {{$t('home.contactUs')}}
          </v-btn>
        </v-app-bar>
      </div>
    </header>
    <a :href="application === 'NativeDiscount' ? 'https://www.nativediscount.com/en/smarthealth-uk/checkout' : 'https://www.my-onlinemarket.com/en/smartwatch-uk/checkout'" target="_blank">
      <img
        :src="application === 'NativeDiscount' ? 'https://d1yei2z3i6k35z.cloudfront.net/893893/63401d1e4a740_banner-montre-en.jpg' : require('@/assets/bannerOnlineMarket.jpg')"
        target="_blank"
        height="650"
        width="100%"
        />
      </a>

    <v-container class="my-5" grid-list-xl>
      <h1>{{$t('home.ourBrands')}}</h1>
      <v-layout row wrap justify-center>
        <v-flex xs6 md4
        v-for="item in allProducts"
        :key="item.id">
          <v-img
            :src="item.bannerImg.src"
            height="auto"
            width="auto"
          ></v-img>
        </v-flex>
      </v-layout>

    </v-container>

    <v-container class="my-5" grid-list-xl>

      <h1>{{$t('home.ourBestSellingProducts')}}</h1>
      <v-layout row wrap justify-center>
        <v-flex xs6 md4
        v-for="item in bestSellingProducts"
        :key="item.id">
          <v-card
            height="100%"
            class="pa-2"
            max-width="500"
            v-bind:href="url+item.locales+'/'+item.routerName+'/checkout'"
            target="_blank"
          >
            <v-img
              :src="item.mainImg.src"
              height="auto"
              width="auto"
            ></v-img>

            <v-card-title>
              {{ item.name }}
            </v-card-title>

            <v-card-subtitle class="black--text">
              {{ item.caracteristics[0] }}
            </v-card-subtitle>
            <v-card-subtitle class="red--text">
              {{ item.remisedPrice }}
            </v-card-subtitle>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>

    <v-container>
      <a :href="application === 'NativeDiscount' ? 'https://www.nativediscount.com/en/thermaly-uk/checkout' : 'https://www.my-onlinemarket.com/en/slimsync-uk/checkout'" target="_blank">
        <img
          :src=" application === 'NativeDiscount' ?  'https://d1yei2z3i6k35z.cloudfront.net/893893/63401d123010f_banner-thermaly-en-1.jpg' : require('@/assets/seeProductOnlineMarket.jpg')"
          height="100%"
          width="100%"
          style="margin-bottom: 20px;"
          />
      </a>
    </v-container>
    <v-row justify="space-around" style="background: linear-gradient(253.77deg,hsla(0,0%,100%,.3) -1.6%,hsla(0,0%,100%,0) 167.37%),#2e2e2e">
      <v-card-title  class="white--text">
         <v-icon :color="application === 'NativeDiscount' ?'blue' : '#FF9000'" left>
           mdi-truck-fast
         </v-icon>
        {{$t('home.freeInternationalDelivery')}}
       </v-card-title>
       <v-card-title class="white--text">
        <v-icon :color="application === 'NativeDiscount' ?'blue':'#FF9000'" left>
          mdi-credit-card-marker
        </v-icon>
         {{$t('home.fastAndSecurePayments')}}
      </v-card-title>
      <v-card-title class="white--text">
       <v-icon :color="application === 'NativeDiscount' ?'blue': '#FF9000'" left>
         mdi-face-agent
       </v-icon>
        {{$t('home.friendlyGlobalSupport')}}
     </v-card-title>
    </v-row>

    <v-container class="my-5" grid-list-xl>

      <h1>{{$t('home.allOurProducts')}}</h1>
      <v-layout row wrap justify-center>
        <v-flex xs6 md4
        v-for="item in allProducts"
        :key="item.id">
          <v-card
            height="100%"
            class="pa-2"
            max-width="500"
            v-bind:href="url+item.locales+'/'+item.routerName+'/checkout'"
            target="_blank"
          >
            <v-img
              :src="item.mainImg.src"
              height="auto"
              width="auto"
            ></v-img>

            <v-card-title>
              {{ item.name }}
            </v-card-title>

            <v-card-subtitle class="black--text">
              {{ item.caracteristics[0] }}
            </v-card-subtitle>
            <v-card-subtitle class="red--text">
              {{ item.remisedPrice }}
            </v-card-subtitle>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
  </div>
</template>

<script>
export default {
  name: 'home-view',
  computed: {
    allProducts () {
      return this.$store.state.Product.filter(item => item.locales === this.selected && !item.hideOnHomepage)
    },
    bestSellingProducts () {
      return this.allProducts.filter(item => item.bestSelling)
    }
  },
  methods: {
    changeLocales (local) {
      this.selected = local
      this.$i18n.locale = this.selected
    }
  },
  data () {
    return {
      selected: 'en',
      application: process.env.VUE_APP_APPLICATION_NAME,
      url: process.env.VUE_APP_URL,
      languages: [
        { locale: 'en', value: 'EN United Kingdom' },
        { locale: 'fr', value: 'FR France' },
        { locale: 'de', value: 'DE Germany' },
        { locale: 'br', value: 'BR Portuguese' },
        { locale: 'es', value: 'ES Span' },
        { locale: 'il', value: 'IL Hebrew' },
        { locale: 'it', value: 'IT Italy' },
        { locale: 'jp', value: 'JP Japan' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.chip {
  width: 90px;
}
.chipIcon {
  content: "";
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  will-change: transform;
  transition: transform .15s linear;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 10px;
}
.typoChip{
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  line-height: 17px;
}

.v-menu__content {
  top:60px!important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%)!important;
  border-radius: 16px!important;;

}

.v-list {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  top: 56px;
  background: #fff;
  border-radius: 16px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -.02em;
  color: #000;
  padding: 16px;
}
.v-list:after {
  position: absolute;
  display: block;
  content: "";
  top: 48px;
  left: 30px;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 0.5rem 0.5rem;
}

.typoLocal {
  font-size: 13px;
  line-height: 24px;font-family: Montserrat,Verdana,Arial,Helvetica,sans-serif;
  cursor: pointer;
}

.typoLocal.selected {
  color: #0C1BE6;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
}
.typoLocal:hover {
  color: #0C1BE6;
}
</style>
