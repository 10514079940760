<template>
  <footer class="footer">
    <div class="container">
      <nav class="footer__nav">
        <ul>
          <li v-for="(link,i) in links" :key="i">
            <a  :href="link.href" :target="link.target" :rel="link.rel" class="footer__link">{{ link.label }}</a>
          </li>
        </ul>
      </nav>
      <div class="footer__copyright">
      {{$t('landingPage.allRightsReserved',{applicationName})}}
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  created () {
    this.links = this.$store.state.Product.find(p => p.routerName === this.$route.name).links.length > 0 ? this.$store.state.Product.find(p => p.routerName === this.$route.name).links : this.links
  },
  data () {
    return {
      applicationName: process.env.VUE_APP_APPLICATION_NAME,
      links: [
        {
          label: this.$t('landingPage.legalNotice'),
          href: `https://legal.${process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase()}.com/legal-notices`,
          target: '_blank',
          rel: 'nofollow'
        },
        {
          label: this.$t('landingPage.cgv'),
          href: `https://legal.${process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase()}.com/terms`,
          target: '_blank',
          rel: 'nofollow'
        },
        {
          label: this.$t('landingPage.deliveryReturns'),
          href: `https://legal.${process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase()}.com/shipping`,
          target: '_blank',
          rel: 'nofollow'
        },
        {
          label: this.$t('landingPage.contactUs'),
          href: `https://legal.${process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase()}.com/contact`,
          target: '_blank',
          rel: 'nofollow'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding-bottom: 40px;
  background: #f1f4f8;
  color: #c2c2c2;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;

  &__nav,
  &__copyright {
    text-align: center;
  }

  &__nav {
    ul {
      padding: 0;
      margin: 0;
    }

    li {
      position: relative;
      display: inline-block;
      padding: 0 6px;

      &:not(:last-child) {
        &::after {
          content: "|";
          position: absolute;
          right: -1px;
          color: #c2c2c2;
        }
      }
    }
  }

  &__link {
    color: #c2c2c2;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
</style>
