<template>
  <v-card elevation="0" class="card">
    <div :class="selected ? 'card__title card__title__selected' : 'card__title'">
      <span class="card__title__text">{{ product.quantity }}x {{getSelectedProduct.name}} </span>
      <v-spacer v-if="this.$vuetify.breakpoint.width <= 678" />
      <v-chip
        class="card__title__chip text--start"
        label
        color="#00c306"
        small
      >
        {{$t('landingPage2.save')}} {{ product.save_percent }}%
      </v-chip>
      <v-spacer v-if="this.$vuetify.breakpoint.width <= 678 && product.BestSeller_checkoutOrder" />
      <v-chip
        v-if="product.BestSeller_checkoutOrder"
        class="card__title__chip d-flex align-items-center"
        style="font-weight: 500; letter-spacing: -1px;line-height: 16px;"
        label
        color="#cc1212"
        small
      >
        <img src="@/assets/CheckoutOrder/star.svg" alt="star" height="12px" style="margin-right: 4px;" />
       {{$t('landingPage2.bestSeller')}}
      </v-chip>
      <v-spacer v-if="this.$vuetify.breakpoint.width >= 768"/>
      <div class="card__title__delivery d-flex">
        <img src="@/assets/CheckoutOrder/shipping-truck.svg" alt="truck" height="20px" width="20px"/>
        <div class="ml-1">{{ $t('landingPage2.fastDelivery') }}</div>
      </div>
    </div>
    <v-radio-group :hide-details="true" v-model="$store.state.Order.article" class="pa-0 ma-0" :class="selected ? 'radio__selected': ''">
      <div class="card__product" @click="choiceProduct(product.name)">
        <v-row align="center" justify="center">
          <v-col cols="auto" class="pr-0" style="padding-left: 15px">
            <v-radio
              class=""
              color="black"
              name="card"
              :id="product.name"
              @change="$store.dispatch('SET_ARTICLE', $event.target.value)"
              :value="product.name"
            />
          </v-col>
          <v-col>
            <v-row justify="space-between">
              <v-col class="pa-3 pl-0 flex-shrink-1" style="padding-top: 15px; padding-bottom:0px!important;">
                <div style="position: relative">
                  <img :src="product.src_img" v-if="this.$vuetify.breakpoint.width >= 678" />
                  <div class="mb-3" v-else>
                  <img :src="list[0].src_img" :height="this.$vuetify.breakpoint.width <= 450 ?'80px':'' "/>
                    <div class="product__amount">x{{product.quantity}}</div>
                  </div>
                </div>
              </v-col>
              <v-col cols="auto" class="py-0 d-flex align-center">
                <div class="card__product_columns">
                  <div class="card__product__container pb-1 pt-1">
                    <span class="card__product__price">{{ formatPriceAndCalcul }}</span>
                    <span class="card__product__each">/{{$t('landingPage2.each')}}</span>
                  </div>
                  <div class="card__product__orig">
                    {{$t('landingPage2.orig')}} {{formatPriceAndCaculOrigin}}
                  </div>
                  <div class="card__product__free">
                    + {{$t('landingPage2.freeShipping')}}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-radio-group>
    <div v-if="selected">
      <div class="warranty">
        <div class="d-flex" style="position: relative!important;margin: 0;margin-left: 45px;">
          <div class="total-arrow">
          <img style="max-width: 100%;vertical-align: middle;"
               src="@/assets/CheckoutOrder/arrow.svg" alt="arrow"
               width="20px">
          </div>
          <input
            style="margin-right: 5px; margin-top: 2px"
            type="checkbox"
            id="input-warranty"
            @change="$store.dispatch('SET_WARRANTY', $event.target.checked)"
            :checked="$store.state.Order.warranty"
          />
        </div>

        <span  @click="selectWarranty(!$store.state.Order.warranty)" class="warranty__yes" >{{$t('landingPage2.lifetimeProtection')}}</span>
      </div>
      <div class="warranty__text">
        <span style="font-weight: 700!important;">{{$t('landingPage2.oneTimeOffer')}}:</span>
        {{$t('landingPage2.textWarranty', {priceWarranty: getSelectedProduct.warranty.label_price})}}
      </div>
    </div>
    <div class="card__list">
      <div>{{$t('landingPage2.sellOutRisk')}}: <span style="color: #e20000; font-weight: 600;">{{$t('landingPage2.high')}}</span></div>
      <div style="color: green;">{{$t('landingPage2.fastDelivery')}}</div>
      <div  v-if="this.$vuetify.breakpoint.width >= 678">{{$t('landingPage2.discount')}}: {{ product.save_percent }}%</div>
      <div class="d-flex align-center" style="font-weight: 600;text-transform: capitalize;"><img src="@/assets/CheckoutOrder/signal-bars.svg" alt="signal" height="10px" width="10px" style="margin-right: 2px"/>{{$t('landingPage2.popular')}}</div>
    </div>
  </v-card>
</template>

<script>
import { formatPrice } from '@/utils/utils'
export default {
  props: {
    product: {
      type: Object
    },
    list: {
      type: Array
    }
  },
  components: {},
  async mounted () {
    const isSelected = this.$store.state.Product.find(p => p.routerName === this.$route.name).list.find(l => l.isSelected).name
    if (isSelected) { await this.$store.dispatch('SET_ARTICLE', isSelected) }
  },
  methods: {
    async choiceProduct (value) {
      await this.$store.dispatch('SET_ARTICLE', value)
    },
    async selectWarranty (value) {
      await this.$store.dispatch('SET_WARRANTY', value)
    }
  },
  computed: {
    getSelectedProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
    selected () {
      return this.$store.state.Order.article === this.product.name
    },
    formatPriceAndCalcul () {
      const calcul = (this.product.price / this.product.quantity) / 100
      return `${formatPrice(calcul)} €`
    },
    formatPriceAndCaculOrigin () {
      const calcul = this.product.price * (this.product.quantity / 100) + (this.product.quantity === 4 ? 0 : (this.product.price / 100 / this.product.quantity))
      return `${formatPrice(calcul)} €`
    }
  }
}
</script>
<style lang="scss">
.v-icon.v-icon {
  font-size: 22px;
}
</style>
<style lang="scss" scoped>
.card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 15px;

  &__title {
    padding: 10px;
    border-radius: 5px 5px 0 0;
    background: #656565;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;

    &__text {
      font-family: Work Sans, sans-serif !important;
      font-weight: 600;
      font-size: 16px;
      color: white;
    }

    &__chip {
      margin-left: 7px;
      font-family: Work Sans, sans-serif !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #fff;
      padding: 2px 6px 5px 5px !important;
      border-radius: 2px !important;
      height: 23px;
    }

    &__delivery {
      font-size: 14px;
      font-family: Work Sans, sans-serif !important;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 1.467;
      @media screen and (max-width: 768px) {
        display:none!important;
      }
    }

    &__selected {
      background: #01335e;
    }
  }

  &__product {
    padding: 15px 10px;
    cursor: pointer;
    &__price {
      font-size: 31px;
      font-weight: 600;
      line-height: 1.3;
      word-break: break-word;
      cursor: pointer;
      font-family: Work Sans,sans-serif!important;
    }
    &__each {
      font-family: Work Sans,sans-serif!important;
      font-size: 12px;
      font-weight: 400;
      alignment-baseline: bottom;
    }
    &__columns {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-pack: center;
      justify-content: center;
    }
    &__container{
      border-bottom: 1px solid #cecece;
      margin-bottom: 5px;
      border-radius: 0 !important;
    }
    &__orig {
      line-height: 1.3;
      color: #960101;
      text-decoration: line-through;
      font-size: 14px;
      font-family: Work Sans,sans-serif!important;
    }
    &__free {
      font-size: 18px!important;
      font-weight: 600!important;
      color: green;
      font-family: Work Sans,sans-serif!important;
      @media screen and (max-width: 768px) {
        font-size: 14px!important;
      }
    }
  }
  &__list {
    background: #fff;
    padding: 6px 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: -.5px;
    color: #232323;
    font-family: Work Sans,sans-serif!important;
    border-top: 1px solid #e0e0e0;
  }
}
.warranty {
  background: #fff3c9;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  padding: 5px;

  &__yes {
    font-weight: 700;
    font-family: Work Sans,sans-serif!important;
    cursor: pointer;
    color: #333;
    line-height: 1.467;
    font-size: 14px;
  }

  &__text {
    background: #FDFCEB;
    padding: 10px;
    font-family: Work Sans,sans-serif!important;
    font-size: 14px!important;
    line-height: 1.467;
  }
}
.total-arrow {
  transform: translate(-50%,-50%);
  left: -15px;
  top: 50%;
  animation: move 1s linear infinite;
  position: absolute!important;
}

.product__amount {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 90%;
  left: 70px;
  width: 40px;
  height: 40px;
  padding: 9px;
  background: hsla(0,0%,100%,.9490196078431372);
  border: 1px solid #bbb;
  font-size: 15px;
  border-radius: 50%;
  color: #000;
  text-align: center;
  font-weight: 600;
}
@keyframes move {
  50%   {left:-25px;}
  100%  {left:-15px;}
}
.radio__selected {
  background: #DDEFFF
}
</style>
