<template>
  <div class ="main">
    <OverlayLoading :overlay="overlay" :loading="loading" />
    <UpsellBanner :upsell="currentUpsell" :product="currentProduct" />
    <UpsellProduct :upsell="currentUpsell"/>
  </div>
</template>

<script>

import UpsellBanner from '@/components/Upsell/UpsellBanner.vue'
import UpsellProduct from '@/components/Upsell/UpsellProduct.vue'
import OverlayLoading from '@/components/OverlayLoading'
import * as Sentry from '@sentry/browser'

export default {
  name: 'view-inter2',
  components: { UpsellBanner, UpsellProduct, OverlayLoading },
  data () {
    return {
      overlay: false,
      loading: false,
      card: null,
      amount: 0
    }
  },
  async beforeDestroy () {
    this.$root.$off('btn_accept', await this.submitEvent)
    this.$root.$off('btn_refused', await this.goToThankEvent)
  },
  async mounted () {
    this.$root.$on('btn_accept', await this.submitEvent)
    this.$root.$on('btn_refused', await this.goToThankEvent)

    const previousUpsellQuery = this.$route?.query?.previousUpsell
    const previousUpsell = previousUpsellQuery ? this.currentUpsellByProductAndUpsellName(this.$route.name, previousUpsellQuery) : null

    if (this.$route.query.click_id) {
      this.$store.commit('SET_CLICK_ID', this.$route.query.click_id)
    }

    if (this.$route.query.token) {
      this.overlay = true
      this.loading = true
      try {
        const responsePaypal = this.$route.query.subscription_id ? await this.$store.dispatch('GET_SUBSCRIPTION_BY_PAYPAL', this.$route.query.subscription_id) : await this.$store.dispatch('GET_PAYMENTS_BY_PAYPAL', this.$route.query.token)
        if (responsePaypal.status === 'APPROVED' || responsePaypal.status === 'ACTIVE') {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], payment_type: 'paypal' })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responsePaypal, upsellName: previousUpsell.routerName, method: 'paypal' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.overlay = false
          this.loading = false
          const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => upsell.routerName === this.upsellName)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'PAYPAL_UPSELL')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    if (this.$route.query['cko-session-id']) {
      this.overlay = true
      this.loading = true
      try {
        const responseCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', this.$route.query['cko-session-id'])
        if (responseCheckout.approved) {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: responseCheckout.source.id })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responseCheckout, upsellName: previousUpsell.routerName, method: 'checkout' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.overlay = false
          this.loading = false
          if (responseCheckout.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
          const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => upsell.routerName === this.upsellName)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        console.log('eeorr : ', e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    this.overlay = false
    this.loading = false
  },
  computed: {
    currentProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
    upsellName () {
      return this.$route.path.split('/upsell/')[1]
    },
    currentUpsell () {
      const product = this.$store.state.Product.find(p => p.routerName === this.$route.name)
      if (!product) return null

      const upsell = product.upsell.find(u => u.routerName === this.upsellName)
      if (upsell) return upsell

      const abtesting = product.upsell.find(u => u.abtesting && u.abtesting.routerName === this.upsellName)
      if (abtesting) return abtesting.abtesting

      const downsell = product.upsell.find(u => u.downsell && u.downsell.routerName === this.upsellName)
      if (downsell) return downsell.downsell

      return null
    },
    upsellBanner () {
      return this.currentUpsell.bannerImg
    },
    upsellMain () {
      return this.currentUpsell.mainImg
    }
  },
  methods: {
    async submitEvent () {
      await this.submit()
    },
    async goToThankEvent () {
      await this.goToThank(true)
    },
    currentUpsellByProductAndUpsellName (productRouterName, upsellName) {
      const product = this.$store.state.Product.find(p => p.routerName === productRouterName)
      if (!product) return null

      const upsell = product.upsell.find(u => u.routerName === upsellName)
      if (upsell) return upsell

      const abtesting = product.upsell.find(u => u.abtesting && u.abtesting.routerName === upsellName)
      if (abtesting) return abtesting.abtesting

      const downsell = product.upsell.find(u => u.downsell && u.downsell.routerName === upsellName)
      if (downsell) return downsell.downsell

      return null
    },
    async checkoutSubmit () {
      this.overlay = true
      this.loading = true
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })
      if (this.$store.state.Order.token_card) {
        const { data: upsellOverseeExist } = await this.$store.dispatch('UPSELL_OVERSEE_EXIST', this.currentUpsell)
        if (!upsellOverseeExist.length) {
          if (this.$store.getters.getSubscriptionByRouterName(this.$route.path.split('/upsell/')[1])) {
            await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
            const response = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_TOKEN_RECURRING', {
              productName: this.$route.name,
              upsellName: this.$route.path.split('/upsell/')[1]
            })
            await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: response.id, data: this.$store.state.Order })
            // eslint-disable-next-line no-return-assign
            return window.location.href = response._links.redirect.href
          } else {
            await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
            const response = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_TOKEN', this.upsellName)
            response.approved
              ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
              : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
          }
        }
      }
      this.overlay = false
      this.loading = false
      if (indexUpsell + 1 === indexMax) {
        await this.goToThank()
      } else {
        await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }
    },
    async stripeSubmit () {
      if (this.$store.getters.getSubscriptionByRouterName(this.upsellName)) {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const responseSession = await this.$store.dispatch('CHECKOUT_STRIPE_UPSELL_SUBSCRIPTION', this.upsellName)
          responseSession?.status === 'succeeded'
            ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
            : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      } else {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const responseSession = await this.$store.dispatch('CHECKOUT_STRIPE_UPSELL', this.upsellName)
          responseSession?.status === 'succeeded'
            ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
            : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      }
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })
      if (indexUpsell + 1 === indexMax) {
        await this.goToThank()
      } else {
        await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }
    },
    async paypalSubmit () {
      this.overlay = true
      this.loading = true
      if (this.$store.getters.getSubscriptionByRouterName(this.upsellName)) {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const paypalResponse = await this.$store.dispatch('CHECKOUT_PAYPAL_SUBSCRIPTION', {
            productName: this.$route.name,
            upsellName: this.upsellName
          })
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: paypalResponse.id, data: this.$store.state.Order })
          window.location.href = paypalResponse.links.find(l => l.rel === 'approve').href
          return window.location.href
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      } else {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const paypalResponse = await this.$store.dispatch('CHECKOUT_PAYPAL_UPSELL', {
            productName: this.$route.name,
            upsellName: this.upsellName
          })
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: paypalResponse.id, data: this.$store.state.Order })
          window.location.href = paypalResponse.links.find(l => l.rel === 'payer-action').href
          return window.location.href
        } catch (e) {
          console.log('error paypal upsell', e)
        }
      }
    },
    async submit () {
      this.$store.state.Order.upsell = true
      if (this.$store.state.Order.payment_type === 'stripe') {
        return await this.stripeSubmit()
      }
      if (this.$store.state.Order.payment_type === 'paypal') {
        return await this.paypalSubmit()
      }
      return await this.checkoutSubmit()
    },
    async goToThank (refused) {
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })
      const upsell = this.$store.getters.getUpsell(this.$route.name)[indexUpsell]

      if (refused && upsell.downsell?.routerName && upsell?.downsell?.routerName !== this.upsellName) {
        const downsell = this.$store.getters.getDownsell(this.$route.name, indexUpsell + 1)
        return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}${downsell}`)
      }

      if (indexUpsell + 1 === indexMax) {
        return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}/thank`)
      }
      if (refused && upsell.downsell?.routerName && upsell?.downsell?.routerName === this.upsellName) {
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }
      const downsell = this.$store.getters.getDownsell(this.$route.name, indexUpsell + 1)
      return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}${downsell}`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
