<template>
  <v-col cols="12">
    <v-row  :class="$store.state.Order.payment_method === 'paypal' ? 'sofort__isActive d-flex': 'sofort d-flex'">
      <div class="methodCard" @click="choicePaymentMethod('paypal')">
        <v-col type="radio" cols="6">
          <v-radio :class="$store.state.Order.payment_method === 'paypal' ? 'sofortLabel__isActive' : 'sofortLabel' " label="Paypal" value="paypal">
          </v-radio>
        </v-col>
        <v-col cols="6" style="padding: 0; margin: 0" class="align-content-end" align="right">
          <img  src="@/assets/PayPal.svg"
                alt="paypal pay" class="paypal"/>
        </v-col>
      </div>
      <transition name="fade">
        <v-col  v-if="$store.state.Order.payment_method === 'paypal'"  cols="12" class="textSofort"  @click="choicePaymentMethod('paypal')">
          {{ $t('landingPage.sofort') }}
        </v-col>
      </transition>
    </v-row>
  </v-col>
</template>

<script>

export default {
  data () {
    return {}
  },
  async mounted () {

  },
  beforeDestroy () {},
  methods: {
    async choicePaymentMethod (value) {
      await this.$store.dispatch('SET_PAYMENT_METHOD', value)
    },
  }
}
</script>
<style lang="scss" scoped>

.paypal {
  margin-right: 30px;
  height: 25px;
  margin-top: 10px;
  width: 50px;
}
.size{
  height: 50px;
}
.textSofort {
  font-size: 14px;
  line-height: 1.43;
  margin: 0;
  padding: 0 40px 10px 43px;
&:hover {
   cursor: pointer;
 }
}

.labelCreditCard{
&__isActive {
   font-size: 16px;
   font-weight: 500;
   transition: text-decoration-color 300ms;
 }
}
.sofortLabel {
&__isActive {
   font-size: 16px;
   font-weight: 500;
   transition: text-decoration-color 300ms;
 }
}
.sofort {
  border: solid 1px #c2c2c2;
  border-top: none;
  border-bottom-left-radius: 4px ;
  border-bottom-right-radius: 4px ;

&:hover {
   cursor: pointer;
 }

&__isActive {
   border-bottom-left-radius: 4px ;
   border-bottom-right-radius: 4px ;
   border: solid 2px black;
 }

}

.one-liner {
  width: 100%;
}
.methodCard {
  display: flex;
  width: 100%;
&:hover {
   cursor: pointer;
 }
}
.creditLabel {
  border-left:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
  border-top-left-radius: 4px ;
&__isActive {
   border-top-left-radius: 4px ;
   border-left: solid 2px black;
   border-top: solid 2px black;
 }
&__base {
   border-top-left-radius: 4px ;
   border-left: solid 1px #c2c2c2;
   border-top: solid 1px #c2c2c2;
 }
&__noActive {
   border-bottom:solid 1px #c2c2c2; ;
 }
}
.creditLogo {
  border-top-right-radius: 4px ;
  border-right:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
&__isActive {
   border-top-right-radius: 4px ;
   border-right: solid 2px black;
   border-top: solid 2px black;
 }
&__base {
   border-top-right-radius: 4px ;
   border-right: solid 1px #c2c2c2;
   border-top: solid 1px #c2c2c2;
 }
&__noActive {
   border-bottom:solid 1px #c2c2c2; ;
 }
}

.cardCredit {
  width: 100%;
  display: flex;
  border-top: solid 1px #c2c2c2;
&__isActive{
   border: solid 2px black;
 }
&__base {
   border: solid 1px #c2c2c2;
 }
}
.checkout {
  height: 100px;
}
.credit {
  border: solid 1px #c2c2c2;
  border-radius: 4px;
&__isActive {
   border-bottom: none;
   border-left: solid 2px black;
   border-right: solid 2px black;
   border-top: solid 2px black;
 }
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: gray;
}

.checkout {
  height: 50px;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 15px;
  color: #9b9b9b;
  font-family: Work Sans,sans-serif!important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  width: 280px;
  margin: 0 auto;
}

label {
  display: block;
  height: 10px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 8px;
}

.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  flex: 1 1 auto;
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}
.icon-container img {
  width: 20px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px lightgray;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}
.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}
</style>
