<template>
  <header class="d-flex header" v-if="active">
    <div class="header__discount white--text mx-auto text-uppercase h2 text-center mb-0">
      {{$t('landingPage2.discountApplied')}}
    </div>
  </header>
</template>

<script>
export default {
  props: {
    percent: {
      type: String
    },
    active: {
      type: Boolean
    }
  }
}
</script>
<style>
/*::-webkit-scrollbar {*/
/*  width: 10px;*/
/*}*/

</style>

<style lang="scss" scoped>

.header {
  background: #1fba34;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #f7f7f7;
  box-shadow: 1px 1px 7px rgb(0 0 0 / 37%);

  &__discount {
    line-height: 1.2;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;

    @media screen and (max-width: 678px) {
      font-size: 14px;
    }

  }
}

</style>
