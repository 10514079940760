<template>
  <div>
  <v-card elevation="0" class="card__payment">

    <div v-if="!$store.state.Product.find(p => p.routerName === this.$route.name).payment_methods_bottom">
      <div class="card__title d-flex" >
        <img class="card__title__icon" height="20px" width="20px"   src="@/assets/CheckoutOrder/secure-payment.svg" alt="secure"/>
        <span class="text-uppercase">{{$t('landingPage2.paymentMethods')}}</span>
      </div>

      <FormCheckout />
    </div>

    <div class="card__personal d-flex" >
      <img class="card__title__icon" height="20px" width="20px"  src="@/assets/CheckoutOrder/user.svg" alt="user"/>
      <span class="text-uppercase">{{$t('landingPage2.personalInformation')}}</span>
    </div>
    <FormContact />

    <div class="card__shipping d-flex mt-5" >
      <img class="card__title__icon" height="20px" width="20px" src="@/assets/CheckoutOrder/shipping-truck-bl.svg" alt="truck"/>
      <span class="text-uppercase">{{$t('landingPage2.shippingInformation')}}</span>
    </div>
    <FormAddress />

    <div v-if="$store.state.Product.find(p => p.routerName === this.$route.name).payment_methods_bottom" class="mt-4">
      <div class="card__title d-flex" >
        <img class="card__title__icon" height="20px" width="20px"   src="@/assets/CheckoutOrder/secure-payment.svg" alt="secure"/>
        <span class="text-uppercase">{{$t('landingPage2.paymentMethods')}}</span>
      </div>

      <FormCheckout />
    </div>

    <button class="btn__purchase" type="submit">
      <span class="btn__purchase__text text-uppercase">{{$t('landingPage2.completePurchase')}}</span>
    </button>
    <div class="encrypted mx-auto">
      <img class="mr-1" src="@/assets/CheckoutOrder/lock.svg" alt="lock" height="14px" width="auto">
      <span>{{$t('landingPage2.encryptedCheckout')}}</span>
    </div>
    <div class="d-flex justify-center align-center mb-4">
      <img  src="@/assets/CheckoutOrder/credit-cards-btn.svg" alt="credit" width="100%" style="max-width: 339px;">
    </div>
    <div v-if="!this.$store.state.Product.find(p => p.routerName === this.$route.name).no_display_guarantee">
      <div class="divider"><span class="span__divider">{{$t('landingPage2.safeCheckout')}}</span></div>

      <div class="garantee d-flex flex-column align-items-center border mb-4 p-4">
        <span class="h3 garantee__text d-flex justify-center align-items-center">{{$t('landingPage2.ourGuarantee')}}</span>
        <div class="garantee__subtitle d-flex flex-row justify-center align-items-center my-3">
          <img src="@/assets/CheckoutOrder/check.svg" alt="check" width="20px">
          <span class="ml-1">{{$t('landingPage2.noHiddenFees')}}</span>
        </div>
        <div class="garantee__subtitle d-flex flex-row justify-center align-items-center">
          <img src="@/assets/CheckoutOrder/check.svg" alt="check" width="20px">
          <span class="ml-1">{{$t('landingPage2.dayReturnPolicy')}}</span>
        </div>
      </div>
        <div  v-for="(card, i) in guarantee" :key="i" class="garantee__list d-flex flex-column align-items-start border rounded-lg mb-4 p-3">
          <div class="d-flex flex-row align-items-center justify-content-center" style=" align-items: center;">
            <div class="d-flex mr-3">
              <img :src="card.src_img"  height="50px" style="max-width: 50px"/>
            </div>
            <div class="texts">
              <div class="texts__title">{{card.title}}</div>
              <p class="texts__p">{{card.text}}</p>
            </div>
          </div>
        </div>
      </div>
  </v-card>
    <div v-if="getProductTestimonials.length && this.$vuetify.breakpoint.width <= 959">
      <CardTestimonial v-for="(item, i) in getProductTestimonials" :key="i"
                       :testimonial="item"/>
    </div>
  </div>
</template>

<script>
import FormContact from '@/components/CheckoutOrder/FormElements/FormContact.vue'
import FormAddress from '@/components/CheckoutOrder/FormElements/FormAddress.vue'
import CardTestimonial from '@/components/CheckoutOrder/elements/CardTestimonial.vue'
import FormCheckout from '@/components/CheckoutOrder/FormElements/FormCheckout'
export default {
  components: {
    FormCheckout,
    FormContact,
    FormAddress,
    CardTestimonial
  },

  data () {
    return {
      guarantee: [
        {
          src_img: require('@/assets/CheckoutOrder/30-day.png'),
          title: this.$t('landingPage2.30dayMoney'),
          text: this.$t('landingPage2.30dayMoneyText')
        },
        {
          src_img: require('@/assets/CheckoutOrder/lock.png'),
          title: this.$t('landingPage2.shopWithConfidence'),
          text: this.$t('landingPage2.shopWithConfidenceText')
        },
        {
          src_img: require('@/assets/CheckoutOrder/shield.png'),
          title: this.$t('landingPage2.yourPrivacyIsImportant'),
          text: this.$t('landingPage2.yourPrivacyIsImportantText')
        },
        {
          src_img: require('@/assets/CheckoutOrder/eu.svg'),
          title: this.$t('landingPage2.operatinInEurope'),
          text: this.$t('landingPage2.operatinInEuropeText')
        }
      ]
    }
  },
  computed: {
    getProductTestimonials () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name).testimonials || []
    },
    getSelectedArticle () {
      return this.$store.getters.getSelectedArticle
    },
    getVariantsProduct () {
      return this.$store.getters.getVariantsProduct(this.$route.name)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card {
  &__payment {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 0 !important;

    &__icon {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__creditCard {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 0 !important;
    padding: 0px 0px 15px;
  }
  &__personal {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    border-radius: 0 !important;
  }

  &__shipping {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;
    margin-bottom: 30px;
  }
}
.divider {
  color: #333;
  margin: 5px 0 20px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.divider:before {
   background-color: #ddd;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.span__divider {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  font-weight: 400;
  line-height: 1.467;
}
.btn__purchase {
  background-color: #29af5c;
  border-radius: 3px;
  box-shadow: 0 3px 0 #0f6a28;
  color: #fff;
  display: block;
  font-weight: 700;
  line-height: 1;
  padding: 18px 5px 17px!important;
  font-size: 18px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;
  &__text{
    font-family: Work Sans,sans-serif!important;
    font-weight: 700;
    line-height: 1;
  }
}
.btn__purchase:hover{
    transform: scale(1.025);
  outline: 0;
  transition: all .25s ease;
}

.encrypted {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.5px;
  font-weight: 400;
  color: #333;
  line-height: 1.467;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  margin-bottom: 15px;
}
.garantee {
  background: #f8f9fa!important;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #dee2e6!important;
  border-radius: 3px;
  &__text {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: #333;
    font-family: Work Sans,sans-serif!important;
  }
  &__subtitle {
    font-size: 16px;
    letter-spacing: -.5px;
    line-height: 1.467;
    font-weight: 400;
    font-family: Work Sans,sans-serif!important;
    margin: 0px;
  }

  &__list {
    background: #f8f9fa!important;
    border-radius: 3px;
    border: 1px solid #dee2e6!important;
    padding: 10px;
  }
}
.texts{
  &__title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: #333;
    line-height: 1.467;
    font-family: Work Sans,sans-serif!important;
  }
  &__p {
    font-family: Work Sans,sans-serif!important;
    line-height: 1.467;
    font-size: 11px;
    margin-bottom: 0;
  }
}

</style>
