<template>
  <div class="variant" style="margin-bottom: 3rem" >
    <v-container class="pa-1" >
        <v-row>
          <v-col
            v-for="(item, i) in getVariantsOrder"
            cols="12"
            sm="6"
            style="padding: 5px"
            :key="i"
          >
              <VariantCard :variant="{...item, index:i}" :key="getVariantsOrder[i].type"/>
          </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import VariantCard from '@/components/Checkout/elements/variantCard'
export default {
  components: { VariantCard },
  methods: {},
  props: {
    article: {
      type: Object
    }
  },
  async mounted () {
    if (this.getVariantsProduct.length && !this.$store.state.Order.session_id) await this.$store.dispatch('SET_VARIANT', Array(this.article.quantity).fill(this.getVariantsProduct[0]))
  },
  computed: {
    getVariantsProduct () {
      return this.$store.getters.getVariantsProduct(this.$route.name)
    },
    getVariantsOrder () {
      return this.$store.getters.getVariantsOrder
    }
  },
  data: () => ({})
}
</script>

<style lang="scss" scoped>
</style>
