<template>
  <section class="section__message">
    <v-container class="container__message">
      <div class="textRed" v-if="metadata.textRed" v-html="metadata.textRed"/>
      <div class="lastMessage" v-if="metadata.finalMessage" v-html="metadata.finalMessage"/>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    metadata: {
      type: Object,
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
.container__message {
  max-width: 960px;
}

.lastMessage {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 37px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  text-align: center;
  line-height: 41px;
  font-weight: bold;

  @media (max-width: 800px) {
    padding: 0px;
    font-size: 20px;
    line-height: 22px;
  }
}
.textRed{
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 25px;
  color:rgba(242, 76, 118, 1);
  font-family: "open-sans", sans-serif;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;

  @media (max-width: 800px) {
    margin-bottom: 15px;
    padding: 0px;
    font-size: 20px;
    line-height: 22px;
  }

}
</style>
