<template>
  <div class="contact">
    <v-row>
      <v-col cols="12" class="py-1">
        <v-text-field
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.firstNameLabel')"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'first_name',
              value: $event,
            })
          "
          filled
          :value="$store.state.Order.contact.first_name"
          :rules="[
            required
          ]"
        />
        <v-text-field
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.lastNameLabel')"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'last_name',
              value: $event,
            })
          "
          :value="$store.state.Order.contact.last_name"
          :rules="[
            required
          ]"
          dense
          filled
        />
        <v-text-field
          class="textField"
          type="email"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.emailLabel')"
          @blur="SendEmail"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'email',
              value: $event,
            })
          "
          :value="$store.state.Order.contact.email"
          :rules="[
            required,
            isEmail
          ]"
          dense
          filled
        />
        <v-text-field
          class="textField"
          type="phone"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.phoneLabel')"
          @blur="SendPhone"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'phone',
              value: $event,
            })
          "
          :value="$store.state.Order.contact.phone"
          :rules="[
            required,
            minPhoneNumber,
            maxPhoneNumber,
            onlyDigit
          ]"
          dense
          filled
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, isEmail, minPhoneNumber, maxPhoneNumber, onlyDigit } from '@/utils/validation'
import Brevo from '@/utils/Brevo'
export default {
  methods: {
    required,
    isEmail,
    minPhoneNumber,
    maxPhoneNumber,
    onlyDigit,
    async SendEmail () {
      if (isEmail(this.$store.state.Order.contact.email) === true && (minPhoneNumber(this.$store.state.Order.contact.phone) === false &&
        maxPhoneNumber(this.$store.state.Order.contact.phone) === false &&
        onlyDigit(this.$store.state.Order.contact.phone) === false) && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_TRACKING', {
        //   type: 'email',
        //   tags: this.$route.name,
        //   id: this.$store.state.Order.contact.email,
        //   countryCode: this.$i18n.locale
        // })
      }
      if ((isEmail(this.$store.state.Order.contact.email) === true) && (minPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        maxPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        onlyDigit(this.$store.state.Order.contact.phone) === true) && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_CUSTOM_TRACKING', { tags: this.$route.name, countryCode: this.$i18n.locale })
      }
    },
    async SendPhone () {
      if (minPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        maxPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        onlyDigit(this.$store.state.Order.contact.phone) === true && isEmail(this.$store.state.Order.contact.email) === false && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_TRACKING', {
        //   type: 'phone',
        //   tags: this.$route.name,
        //   id: this.$store.state.Order.contact.phone,
        //   countryCode: this.$i18n.locale
        // })
      }
      if ((isEmail(this.$store.state.Order.contact.email) === true) && (minPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        maxPhoneNumber(this.$store.state.Order.contact.phone) === true &&
        onlyDigit(this.$store.state.Order.contact.phone) === true) && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_CUSTOM_TRACKING', { tags: this.$route.name, countryCode: this.$i18n.locale })
      }
    }
  }
}
</script>

<style lang="scss" >
.v-label {
  font-size: 15px;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot{
  background: white;
  border-radius: 2px;
}
.textField {
  color: #9b9b9b;
  line-height: 1.467;
  font-family: Work Sans,sans-serif!important;
}

.v-application--is-ltr .v-text-field .v-label {
  transform-origin: -14px -5px;
}
</style>
