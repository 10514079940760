import Vue from 'vue'
import Vuex from 'vuex'
import Order from './modules/Order'
import Product from './modules/Product'

Vue.use(Vuex)

const PluginStorage = store => {
  store.subscribe((mutation, state) => {
    const newState = { address: state.Order.address, contact: state.Order.contact }
    localStorage.setItem('store', JSON.stringify(newState))
  })
}

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    initialiseStore (state) {
      if (localStorage.getItem('store')) {
        state.Order.contact = JSON.parse(localStorage.getItem('store')).contact
        state.Order.address = JSON.parse(localStorage.getItem('store')).address
      }
    },
    setProducts (state, products) {
      state.Product = products
    },
  },
  actions: {},
  plugins: [PluginStorage],
  modules: {
    Order,
    Product
  }
})
