<template>
  <v-card elevation="0" class="card__testimonial">
    <div style="position: relative">
    <div class="d-flex flex-row justify-content-start align-items-start mb-3">
      <div class="testimonial-image mr-3 d-flex"  style="align-items: center;">
        <img :src="testimonial.src_img" height="65px" width="65px" />
      </div>
      <div class="pt-3">
        <div class="verified-mobile d-flex" v-if="this.$vuetify.breakpoint.width <= 959">
          <img src="@/assets/CheckoutOrder/proof-icon.svg" alt="proof" height="12px" width="12px" />
          <span class="ml-1">{{$t('landingPage2.verifiedCustomer')}}</span>
        </div>
        <div class="d-flex testimonial-name mb-1 font-weight-medium"  style="align-items: center;">
          <div class="mr-1 d-flex">
          <img src="https://getecowarm.com/global/flags/fr.png" height="15px" width="auto">
          </div>

          {{testimonial.username}}
          <div class="ml-1">
            <span class="testimonial-country">{{testimonial.country}}</span>
          </div>
        </div>
        <div class="testimonial-stars mb-3">
          <img  src="@/assets/CheckoutOrder/stars.svg" alt="stars" width="100px">
        </div>
      </div>
      <v-spacer />
      <div class="verified d-flex" v-if="this.$vuetify.breakpoint.width >= 960">
        <img src="@/assets/CheckoutOrder/proof-icon.svg" alt="proof" height="16px" width="16px" />
        <span class="ml-1">{{$t('landingPage2.verifiedBuyer')}}</span>
      </div>
    </div>
    <p class="testimonial-text">{{testimonial.text}}</p>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    testimonial: {
      type: Object
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card {
  &__testimonial {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
.testimonial-name {
  font-weight: 500;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
}
.testimonial-country {
  font-size: 10px;
  font-weight: 400!important;
  font-family: Work Sans,sans-serif!important;
  line-height: 1.467;
}
.testimonial-text {
  font-weight: 400!important;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  line-height: 1.467;
}
.verified {
  position: absolute;
  color: #01b157;
  font-weight: 500;
  letter-spacing: -0.1px;
  padding: 5px 10px;
  background: #f9f9f9;
  border: 1px solid #e4e2e2;
  border-radius: 15px;
  font-size: 12px;
  top: 0;
  right: 0;
}

.verified-mobile {

  font-size: 9px;
  padding: 0px 8px;
  padding-top: 2px;
  width: 110px;
  margin-left: -7px;
  color: #01b157;
  font-weight: 500;
  letter-spacing: -0.1px;
  background: #f9f9f9;
  border: 1px solid #e4e2e2;
  border-radius: 15px;
  line-height: 1.467;
  top: 0;
  right: 0;
  margin-bottom: 5px;
}
</style>
