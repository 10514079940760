<template>
  <div>
    <v-card elevation="0" class="card__selectedOrder">
      <div class="card__title d-flex">
        <img class="card__title__icon" height="20px" width="20px" src="@/assets/CheckoutOrder/layers.svg" alt="layers"/>
        <span class="text-uppercase">{{ $t('landingPage2.selectYourOrderQuantity') }}</span>
      </div>
      <CardProduct v-for="(item, i) in getProductList" :key="i" :product="item" :list="getProductList"/>
    </v-card>
    <OrderSummary/>
    <div v-if="getProductTestimonials.length && this.$vuetify.breakpoint.width >= 960">
    <CardTestimonial v-for="(item, i) in getProductTestimonials" :key="i"
                     :testimonial="item"/>
    </div>
  </div>
</template>

<script>

import CardProduct from '@/components/CheckoutOrder/elements/CardProduct.vue'
import OrderSummary from '@/components/CheckoutOrder/elements/OrderSummary.vue'
import CardTestimonial from '@/components/CheckoutOrder/elements/CardTestimonial.vue'

export default {
  components: { CardTestimonial, OrderSummary, CardProduct },

  data () {
    return {}
  },
  computed: {
    getProductTestimonials () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name).testimonials || []
    },
    getProductList () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name).list.filter(l => !l.hide_checkoutOrder)
    },
    getSelectedArticle () {
      return this.$store.getters.getSelectedArticle
    },
    getVariantsProduct () {
      return this.$store.getters.getVariantsProduct(this.$route.name)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card {
  &__selectedOrder {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 25px;

    @media screen and (max-width: 768px) {
      padding: 0;
      border: none;
      border-radius: unset;
      background: #F7F7F7;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 0 !important;

    &__icon {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
