<template>
  <div class="main">
    <OverlayLoading :overlay="overlay" :loading="loading" />
    <div class="container" style="max-width: 960px">
      <v-card class="card">
        <v-card-text class="text-center">
          <v-img
            loading="lazy"
            :src="upsellBanner.src"
            :alt="upsellBanner.alt"
            width="444"
            class="banner__img"
          />

          <p class="upsell__validation mb-5 mt-4" v-if="currentUpsell.reloadDescription">
            {{currentUpsell.reloadDescription}}
          </p>

          <p class="mt-3 text-h5 font-weight-bold" v-if="currentUpsell.addUpsell">
            <span class="red--text text-uppercase">{{currentUpsell.alertMessage}}</span>
            {{currentUpsell.addUpsell}}
            <span class="red--text">{{currentUpsell.remisedPrice}}</span> <u><span>{{currentUpsell.initialPrice}}</span></u>
          </p>
          <div v-for="(meta, i) in currentUpsell.metadata" :key="i">
            <img v-if="meta.img"
              loading="lazy"
              :src="meta.img.src"
              :alt="meta.img.alt"
              class="product-img"
            />

            <h2 class="my-5 upsell__what" v-if="meta.title" v-html="meta.title" style=" line-height: 30px;"/>
            <p class="my-5 upsell__description"  v-if="meta.description" v-html="meta.description"/>
            <p class="my-5 font-weight-bold" v-if="meta.encouragedMessage" v-html="meta.encouragedMessage"/>

            <div v-if="meta.buttonAccept">
            <v-btn :x-large="$vuetify.breakpoint.smAndUp" min-height="50px" color="#1fba34" dark elevation="0" tile @click="submit()">
              <v-icon dark>
                mdi-arrow-right-bold
              </v-icon>
              {{ $t('upsell.buttonAccept') }}
            </v-btn>
            </div>
          </div>
          <p class="my-5">
            {{ $t('upsell.thisIs') }}
            <u><b>{{ $t('upsell.buyersOnly') }}
            </b></u
            >{{ $t('upsell.notAvailableShops') }}
          </p>

          <v-btn text color="primary" class="mb-10" plain small @click="goToThank(true)"> <u>{{ $t('upsell.buttonRefused') }}</u> </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

import * as Sentry from '@sentry/browser'
import OverlayLoading from '@/components/OverlayLoading'

export default {
  name: 'view-inter',

  components: { OverlayLoading },
  data () {
    return {
      overlay: false,
      loading: false,
      card: null,
      amount: 0
    }
  },
  async mounted () {
    const previousUpsellQuery = this.$route?.query?.previousUpsell
    const previousUpsell = previousUpsellQuery ? this.currentUpsellByProductAndUpsellName(this.$route.name, previousUpsellQuery) : null

    if (this.$route.query.click_id) {
      this.$store.commit('SET_CLICK_ID', this.$route.query.click_id)
    }

    if (this.$route.query.token) {
      this.overlay = true
      this.loading = true
      try {
        const responsePaypal = this.$route.query.subscription_id ? await this.$store.dispatch('GET_SUBSCRIPTION_BY_PAYPAL', this.$route.query.subscription_id) : await this.$store.dispatch('GET_PAYMENTS_BY_PAYPAL', this.$route.query.token)
        if (responsePaypal.status === 'APPROVED' || responsePaypal.status === 'ACTIVE') {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], payment_type: 'paypal' })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responsePaypal, upsellName: previousUpsell.routerName, method: 'paypal' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.overlay = false
          this.loading = false
          const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => upsell.routerName === this.upsellName)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        this.overlay = false
        this.loading = false
        Sentry.withScope(scope => {
          scope.setExtra('name', 'PAYPAL_UPSELL')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    if (this.$route.query['cko-session-id']) {
      this.overlay = true
      this.loading = true
      try {
        const responseCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', this.$route.query['cko-session-id'])
        if (responseCheckout.approved) {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: responseCheckout.source.id })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responseCheckout, upsellName: previousUpsell.routerName, method: 'checkout' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.overlay = false
          this.loading = false
          if (responseCheckout.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
          const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => upsell.routerName === this.upsellName)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        this.overlay = false
        this.loading = false
        this.overlay = false
        this.loading = false
        await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    this.overlay = false
    this.loading = false
  },
  computed: {
    upsellName () {
      return this.$route.path.split('/inter/')[1]
    },
    currentProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
    currentUpsell () {
      const product = this.$store.state.Product.find(p => p.routerName === this.$route.name)
      if (!product) return null

      const upsell = product.upsell.find(u => u.routerName === this.upsellName)
      if (upsell) return upsell

      const abtesting = product.upsell.find(u => u.abtesting && u.abtesting.routerName === this.upsellName)
      if (abtesting) return abtesting.abtesting

      const downsell = product.upsell.find(u => u.downsell && u.downsell.routerName === this.upsellName)
      if (downsell) return downsell.downsell

      return null
    },
    upsellBanner () {
      return this.currentUpsell.bannerImg || this.currentProduct.bannerImg
    },
    upsellMain () {
      return this.currentUpsell.mainImg
    }
  },
  methods: {
    currentUpsellByProductAndUpsellName (productRouterName, upsellName) {
      const product = this.$store.state.Product.find(p => p.routerName === productRouterName)
      if (!product) return null

      const upsell = product.upsell.find(u => u.routerName === upsellName)
      if (upsell) return upsell

      const abtesting = product.upsell.find(u => u.abtesting && u.abtesting.routerName === upsellName)
      if (abtesting) return abtesting.abtesting

      const downsell = product.upsell.find(u => u.downsell && u.downsell.routerName === upsellName)
      if (downsell) return downsell.downsell

      return null
    },
    async checkoutSubmit () {
      this.overlay = true
      this.loading = true
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })

      if (this.$store.state.Order.token_card) {
        const { data: upsellOverseeExist } = await this.$store.dispatch('UPSELL_OVERSEE_EXIST', this.currentUpsell)
        if (!upsellOverseeExist.length) {
          if (this.$store.getters.getSubscriptionByRouterName(this.$route.path.split('/inter/')[1])) {
            await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
            const response = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_TOKEN_RECURRING', {
              productName: this.$route.name,
              upsellName: this.$route.path.split('/inter/')[1]
            })
            await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: response.id, data: this.$store.state.Order })
            // eslint-disable-next-line no-return-assign
            return window.location.href = response._links.redirect.href
          } else {
            await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
            const response = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_TOKEN', this.upsellName)
            response.approved
              ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
              : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
          }
        }
      }
      this.overlay = false
      this.loading = false
      if (indexUpsell + 1 === indexMax) {
        await this.goToThank()
      } else {
        await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }
    },
    async stripeSubmit () {
      if (this.$store.getters.getSubscriptionByRouterName(this.upsellName)) {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const responseSession = await this.$store.dispatch('CHECKOUT_STRIPE_UPSELL_SUBSCRIPTION', this.upsellName)
          responseSession?.status === 'succeeded'
            ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
            : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      } else {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const responseSession = await this.$store.dispatch('CHECKOUT_STRIPE_UPSELL', this.upsellName)
          responseSession?.status === 'succeeded'
            ? await this.$store.dispatch('SEND_UPSELL_OVERSEE', this.upsellName)
            : await this.$store.dispatch('REMOVE_UPSELL', this.upsellName)
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      }
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })
      if (indexUpsell + 1 === indexMax) {
        await this.goToThank()
      } else {
        await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }
    },
    async paypalSubmit () {
      this.overlay = true
      this.loading = true
      if (this.$store.getters.getSubscriptionByRouterName(this.upsellName)) {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const paypalResponse = await this.$store.dispatch('CHECKOUT_PAYPAL_SUBSCRIPTION', {
            productName: this.$route.name,
            upsellName: this.upsellName
          })
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: paypalResponse.id, data: this.$store.state.Order })
          window.location.href = paypalResponse.links.find(l => l.rel === 'approve').href
          return window.location.href
        } catch (e) {
          console.log('error stripe upsell', e)
        }
      } else {
        try {
          await this.$store.dispatch('SET_UPSELL', [...this.$store.state.Order.upsells, this.currentUpsell])
          const paypalResponse = await this.$store.dispatch('CHECKOUT_PAYPAL_UPSELL', {
            productName: this.$route.name,
            upsellName: this.upsellName
          })
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: paypalResponse.id, data: this.$store.state.Order })
          window.location.href = paypalResponse.links.find(l => l.rel === 'payer-action').href
          return window.location.href
        } catch (e) {
          console.log('error paypal upsell', e)
        }
      }
    },
    async submit () {
      this.$store.state.Order.upsell = true
      if (this.$store.state.Order.payment_type === 'stripe') {
        return await this.stripeSubmit()
      }
      if (this.$store.state.Order.payment_type === 'paypal') {
        return await this.paypalSubmit()
      }
      return await this.checkoutSubmit()
    },
    async goToThank (refused) {
      const indexMax = this.$store.getters.getUpsell(this.$route.name).length
      const indexUpsell = this.$store.getters.getUpsell(this.$route.name).findIndex(upsell => {
        return upsell.routerName === this.upsellName || upsell?.abtesting?.routerName === this.upsellName || upsell?.downsell?.routerName === this.upsellName
      })
      const upsell = this.$store.getters.getUpsell(this.$route.name)[indexUpsell]
      if (refused && upsell.downsell?.routerName && upsell?.downsell?.routerName !== this.upsellName) {
        const downsell = this.$store.getters.getDownsell(this.$route.name, indexUpsell + 1)
        return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}${downsell}`)
      }

      if (indexUpsell + 1 === indexMax) {
        return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}/thank`)
      }

      if (refused && upsell.downsell?.routerName && upsell?.downsell?.routerName === this.upsellName) {
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, indexUpsell + 1))
      }

      const downsell = this.$store.getters.getDownsell(this.$route.name, indexUpsell + 1)
      return await this.$router.push(`/${this.$i18n.locale}/${this.$route.name}${downsell}`)
    }
  }
}
</script>

<style lang="scss" scoped>

.upsell {
  &__validation {
    word-break: break-word;
    transition: box-shadow 0.1s ease-in-out 0s;
    color: rgba(52, 203, 51, 1);
    text-align: center;
    font-weight: bold;
  }
  &__wait {
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    line-height: 48px;
  }
  &__what {
    font-size: 34px;
  }
  &__description {
    word-break: break-word;
    transition: box-shadow 0.1s ease-in-out 0s;
    font-size: 20px;
    color: rgb(0, 0, 0);
    text-align: center;
    line-height: 30px;
  }
}

.main {
  background: #f1f4f8;
  padding-top: 18px;
  padding-bottom: 32px;

  p {
    font-size: 20px;
    line-height: 28px;
  }
}

.banner__img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.product-img {
  padding: 10px;
  display: block;
  max-width: 100%;
  object-fit: contain;
  width: 900px;
}

.text-subtitle-1 {
  @include media("sm-and-down") {
    line-height: 1;
  }
}

.flex_row {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 200px;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
}

.twocolumn {
    float: left;
    width: 50%;
    padding: 10px;
}

.upsell_twocolumn_header {
    word-break: break-word;
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    text-align: center;
    line-height: 38.4px;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    color: rgba(74, 74, 74, 1);
}

.upsell_twocolumn_price {
    font-size: 48px !important;
    color: rgb(0, 0, 0);
    font-family: "Open Sans";
    text-align: center;
}

.upsell_twocolumn_text {
    word-break: break-word;
    font-size: 18px;
    font-family: "Open Sans";
    font-weight: 400;
    font-style: normal;
    text-align: center;
    line-height: 38.4px;
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    color: rgba(74, 74, 74, 1);
}
</style>
