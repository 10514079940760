import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/scss/global.scss'
import i18n from '@/plugins/i18n'
import VueCookies from 'vue-cookies'
import UserTracker from '@/utils/userTrack'
// eslint-disable-next-line no-new

Vue.config.productionTip = false
Vue.use(VueCookies, {})

// eslint-disable-next-line no-new
new UserTracker(router, store)
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
