<template>
  <div class="shipping mb-6">
    <v-col class="applePay" cols="12" v-if="appleCheck">
      <apple-pay-button @click="paymentByApplePay" buttonstyle="black" type="buy" :locale="`${this.$i18n.locale}-${this.$i18n.locale.toUpperCase()}`" style="height: 46px!important;width: 100%; border-radius: 5px"></apple-pay-button>
    </v-col>
    <div  v-if="appleCheck" class="divider mb-0"><span class="span__divider">{{$t('landingPage2.or')}}</span></div>
      <v-col cols="12" class="googlePay">
      <FormGooglePay/>
    </v-col>
    <div class="divider"><span class="span__divider">{{$t('landingPage2.checkoutWithCreditCard')}}</span></div>

    <div class="card__creditCard d-flex" >
      <span class="text-uppercase">{{$t('landingPage2.creditCard')}}</span>
      <v-spacer/>
      <img class="card__title__iconCards" height="auto" width="190px" src="@/assets/CheckoutOrder/credit-cards.svg" alt="cards"/>
    </div>

    <Frames
      :config="config"
      @ready="ready"
      @frameFocus="frameFocus"
      @cardTokenized="cardTokenized"
    />

    <div class="checkout pa-3 card-number-frame mb-3">
      <!-- card number will be added here -->
    </div>
    <v-row >
      <v-col cols="6">
        <div class="checkout pa-3 expiry-date-frame">
          <!-- expiry date will be added here -->
        </div>
      </v-col>
      <v-col cols="6">
        <div class="checkout pa-3 cvv-frame">
          <!-- cvv frame will be added here -->
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Frames } from 'frames-vue'
import FormGooglePay from '@/components/CheckoutOrder/FormElements/FormGooglePay'
import * as Sentry from '@sentry/browser'
import Brevo from '@/utils/Brevo'
export default {
  components: {
    Frames,
    FormGooglePay
  },
  data () {
    return {
      disable: false,
      check: true,
      checkout: '',
      appleCheck: false,
      config: {
        debug: false,
        publicKey: process.env.VUE_APP_CHECKOUT_PUBLIC_KEY,
        localization: {
          cardNumberPlaceholder: this.$t('landingPage.cardNumber'),
          expiryMonthPlaceholder: this.$t('landingPage.mm'),
          expiryYearPlaceholder: this.$t('landingPage.yy'),
          cvvPlaceholder: this.$t('landingPage.cvc')
        },
        style: {
          base: {
            fontSize: '15px',
            fontFamily: 'Work Sans'
          }
        }
      }
    }
  },
  async created () {
    if (window.ApplePaySession) {
      this.appleCheck = true
    }
  },
  methods: {
    async paymentByApplePay () {
      try {
        // Define PaymentMethodData
        const isValid = this.$parent.$parent.$parent.$parent.$refs.form.validate()
        if (!isValid) {
          return
        }
        const paymentMethodData = [{
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
            version: 3,
            merchantIdentifier: process.env.VUE_APP_MERCHANT_IDENTIFIER,
            merchantCapabilities: [
              'supports3DS'
            ],
            supportedNetworks: [
              'amex',
              'discover',
              'masterCard',
              'interac',
              'visa',
              'privateLabel'
            ],
            countryCode: this.$store.state.Order.address.country
          }
        }]
        // Define PaymentDetails
        const paymentDetails = {
          total: {
            label: process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase(),
            amount: {
              value: this.$store.getters.getAmount / 100,
              currency: this.$store.getters.getArticle.currency
            }
          }
        }
        // Define PaymentOptions
        const paymentOptions = {
          requestPayerName: false,
          requestBillingAddress: false,
          requestPayerEmail: false,
          requestPayerPhone: false,
          requestShipping: false
        }

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions)

        request.onmerchantvalidation = async event => {
          // Call your own server to request a new merchant session.
          // eslint-disable-next-line no-undef
          const merchantSessionPromise = await this.$store.dispatch('GET_APPLE_PAY_SESSION')
          event.complete(merchantSessionPromise.data)
        }

        request.onpaymentmethodchange = event => {
          if (event.methodDetails.type !== undefined) {
            // Define PaymentDetailsUpdate based on the selected payment method.
            // No updates or errors needed, pass an object with the same total.
            const paymentDetailsUpdate = {
              total: paymentDetails.total
            }
            event.updateWith(paymentDetailsUpdate)
          } else if (event.methodDetails.couponCode !== undefined) {
            // Define PaymentDetailsUpdate based on the coupon code.
            // eslint-disable-next-line no-undef
            const total = calculateTotal(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const displayItems = calculateDisplayItem(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const shippingOptions = calculateShippingOptions(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const error = calculateError(event.methodDetails.couponCode)

            event.updateWith({
              total: total,
              displayItems: displayItems,
              shippingOptions: shippingOptions,
              modifiers: [
                {
                  data: {
                    additionalShippingMethods: shippingOptions
                  }
                }
              ],
              error: error
            })
          }
        }

        const response = await request.show()
        const status = 'success'
        await response.complete(status)
        const responseCheckoutToken = await this.$store.dispatch('POST_TOKEN_APPLE_PAY', response.details.token.paymentData)
        this.$store.commit('SET_TOKEN', responseCheckoutToken.data.token)
        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'checkout' })
        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: responseCheckout.data.id, data: this.$store.state.Order })
          const response = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
          const responseOrder = await this.$store.dispatch('GET_ORDER', response.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: response.source.id })
        }
        if (responseCheckout.data.approved) {
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        }
      } catch (e) {
        Sentry.captureMessage('Error payment Apple Pay')
        // Handle errors
      }
    },
    cardTokenized (e) { this.$store.commit('SET_TOKEN', e.token) },
    ready (e) { },
    frameFocus (e) { },
    submitCard () { Frames.submitCard() }
  }
}

</script>

<style lang="scss">
.apple-pay-button {
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 46px;
}
</style>

<style lang="scss" scoped>
.applePay {
  width: 100%;
  height: 45px;
}
.googlePay {
  text-align: center;
}
.card {
  &__payment {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 0 !important;

    &__icon {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__creditCard {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    border-radius: 0 !important;
    padding: 0px 0px 15px;
  }
}
.checkout {
  height: 50px;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 15px;
  color: #9b9b9b;
  font-family: Work Sans,sans-serif!important;
}

.divider {
  color: #333;
  margin: 5px 0 20px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.divider:before {
  background-color: #ddd;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.span__divider {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  font-weight: 400;
  line-height: 1.467;
}

.checkout {
  height: 50px;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 15px;
  color: #9b9b9b;
  font-family: Work Sans,sans-serif!important;
}

.divider {
  color: #333;
  margin: 5px 0 20px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.divider:before {
  background-color: #ddd;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.span__divider {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  font-weight: 400;
  line-height: 1.467;
}
</style>
