<template>
  <v-container>
    <v-row class="text-center">
      <p class="otherProduct">{{$t('thank.otherProductsYouMightLike')}}</p>
      <v-col v-for="(product, index) in this.$props.otherProducts" :key="product.routerName" cols="12" sm="6">
        <v-card @click="redirectionAdvertorial(product, index + 1)" :class="{ 'is-hovered': isHovered[product.routerName], 'OtherProduct-card': true, 'fill-height':true }" class="d-flex flex-column" @mouseover="toggleHover(product.routerName, true)" @mouseout="toggleHover(product.routerName, false)" outlined>
          <v-card-text class="OtherProduct-card__text justify-center align-center">
            <v-img class="OtherProduct-card__img" :style="{ opacity: isHovered[product.routerName] ? 0.8 : 1 }" :src="product.mainImg.src"/>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-title class="OtherProduct-card__title text-center align-self-end">
            <a href="" class="OtherProduct-card__title__a">
              <strong class="OtherProduct-card__title__a__strong" :style="{'box-shadow': isHovered[product.routerName] ? '0px -1px 0px inset': ''}">{{ product.name }} - {{product.caracteristics[0]}}</strong>
            </a>
          </v-card-title>
          <v-card-actions>
            <v-btn block class="OtherProduct-card__action__button"  bottom elevation="0" color="primary" >{{$t('thank.learnMore')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    otherProducts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      isHovered: {},
    }
  },
  methods: {
    redirectionAdvertorial (product, index) {
      window.open(`${product.advertorial}?source=thankyoupage&position=${index}`, '_blank')
    },
    toggleHover (productId, value) {
      this.$set(this.isHovered, productId, value)
    },
  },
}
</script>

<style lang="scss" scoped>
.otherProduct {
  color: #4a90e2;
  font-size: 30px;
  font-weight: 700;
  @include media('sm-and-down') {
    font-size: 18px;
  }
}

.OtherProduct-card__text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OtherProduct-card {
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0.5) !important;

  &__img {
    transition: opacity 0.3s ease;
  }

  &.is-hovered {
    cursor: pointer;
  }
  &__title {
    font-size: 16px;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-block;
    word-break: break-word;
    &__a {
      color: rgb(0, 0, 255);
    }
  }

  &__action__button {
    cursor: pointer;
  }
}
</style>
