<template>
  <div class="main">
    <div class="container" style="max-width: 760px;">
      <v-card class="card">
        <v-card-text>
          <v-row class="banner">
            <v-col cols="12" sm="6">
              <v-img
                :src="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.src"
                :alt="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.alt"
                width="444"
                class="banner__img"
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <p class="banner__text">
                <b class="text--blue">{{$t('thank.needHelp')}}</b>{{$t('thank.contactUs')}}{{$t('thank.email', {applicationName :application})}}
              </p>
            </v-col>
          </v-row>
          <div class="box-shadow">
            <v-row class="recap d-block">
              <p class="recap__thanks">{{$t('thank.thanks')}}</p>
              <b class="recap__title">{{$t('thank.summary')}}</b>

              <div class="article__list">
                <v-row class="article__list__header" justify="space-between" no-gutters>
                  <v-col cols="9"> {{$t('thank.article')}} </v-col>
                  <v-col cols="auto"> {{$t('thank.price')}} </v-col>
                </v-row>
                <ul class="article__list__items">
                  <li
                    class="article__list__item"
                    v-for="(item, i) in $store.getters.getRecap"
                    :key="i"
                  >
                    <label :for="item.name" class="article__list__item__label_thank">
                      <v-row align="center" justify="center">
                        <v-col>
                          <v-row justify="space-between">
                            <v-col class="flex-shrink-1">
                              {{ item.name }}
                            </v-col>
                            <v-col cols="auto">
                              {{ item.label_price }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </label>
                  </li>
                </ul>
              </div>

            <div class="shipping-info-container">
              <div class="shipping-info-box">
                <div class="section-address-box">
                  <h3 class="shipment-address-heading"> {{$t('thank.deliveryAddress')}}</h3>
                  <ul class="section-edit-info__ul">
                    <li class="section-edit-info__li">{{$store.state.Order.contact.last_name}} {{$store.state.Order.contact.first_name}}</li>
                    <li class="section-edit-info__li">{{$store.state.Order.address.street}}</li>
                    <li class="section-edit-info__li">{{$store.state.Order.address.city}}, {{$store.state.Order.address.zip_code}}, {{$store.state.Order.address.country}}</li>
                    <li class="section-edit-info__li">{{$store.state.Order.contact.phone}}</li>
                    <li class="section-edit-info__li">{{$store.state.Order.contact.email}}</li>
                  </ul>
                </div>
                <p>{{$t('thank.mistakeAddress')}} <a :href="'mailto:' + $t('thank.email', {applicationName :application})">{{$t('thank.email', {applicationName :application})}}</a></p>
              </div>
            </div>

              <p class="recap__small">{{$t('thank.confirmationMail')}}<br>
                {{$t('thank.emailReceived')}}</p>
            </v-row>

            <v-row class="santeclean-card__container d-block">
              <div class="santeclean-card">
                <p class="santeclean-card__text">
                  {{ $store.state.Product.find(p => p.routerName === this.$route.name).name }} {{$t('thank.santeCleanGroup', {applicationName: application})}}<br>
                  {{$t('thank.displayOrder', {applicationName: application})}}
                </p>
                <img :src="application === 'NativeDiscount' ? 'https://d1yei2z3i6k35z.cloudfront.net/893893/633ed7e34d43b_logo-native-discount-1.jpg' : require('@/assets/bannerOnlineMarket.png')" alt="nativediscount logo" class="santeclean-card__img">
                <p class="santeclean-card__text">
                  {{$t('landingPage.yourPurchasesWillAppearAs')}}
                  <span class="font-weight-bold"> {{$t('landingPage.domainName', {applicationName: application})}} </span>
                  {{$t('landingPage.onYourCardStatement')}}
                </p>
              </div>
            </v-row>

            <v-row class="santeclean-card__container d-block">
              <OtherProduct :other-products="otherProductsList" v-if="otherProductsList.length"/>
            </v-row>

          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

import * as Sentry from '@sentry/browser'
import OtherProduct from '@/components/OtherProduct'
import axios from 'axios'

export default {
  name: 'home-view',

  components: { OtherProduct },
  computed: {
    product () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
    otherProducts () {
      return this.$store.state.Product.filter(p => p.advertorial && p.locales === this.$i18n.locale && p.currency === this.product.currency && p.routerName !== this.$route.name)
    }
  },
  data () {
    return {
      application: process.env.VUE_APP_APPLICATION_NAME,
      otherProductsList: []
    }
  },
  async mounted () {
    const { data: visitsProduct } = await axios.get(`https://logs-app-84a4327d42d8.herokuapp.com/visit/routerName/nativeDiscount/[${this.getLast30Days()}]`)

    const orderArray = visitsProduct.map(v => v.routername)
    const routernameIndex = {}
    orderArray.forEach((routername, index) => {
      routernameIndex[routername] = index
    })
    this.otherProductsList = this.otherProducts.sort((a, b) => routernameIndex[a.routerName] - routernameIndex[b.routerName])
  },
  async beforeCreate () {
    const previousUpsellQuery = this.$route?.query?.previousUpsell
    const previousUpsell = previousUpsellQuery ? this.currentUpsellByProductAndUpsellName(this.$route.name, previousUpsellQuery) : null

    if (this.$route.query.click_id) {
      this.$store.commit('SET_CLICK_ID', this.$route.query.click_id)
    }
    if (this.$route.query.token) {
      try {
        const responsePaypal = this.$route.query.subscription_id ? await this.$store.dispatch('GET_SUBSCRIPTION_BY_PAYPAL', this.$route.query.subscription_id) : await this.$store.dispatch('GET_PAYMENTS_BY_PAYPAL', this.$route.query.token)
        if (responsePaypal.status === 'APPROVED' || responsePaypal.status === 'ACTIVE') {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], payment_type: 'paypal' })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responsePaypal, upsellName: previousUpsell.routerName, method: 'paypal' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'PAYPAL_UPSELL')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        this.$store.commit('SET_FINISH', true)
        await this.$store.dispatch('FINISH')
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    if (this.$route.query['cko-session-id']) {
      this.overlay = true
      this.loading = true
      try {
        const responseCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', this.$route.query['cko-session-id'])
        if (responseCheckout.approved) {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: responseCheckout.source.id })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('POST_SUBSCRIPTION', { data: responseCheckout, upsellName: previousUpsell.routerName, method: 'checkout' })
            await this.$store.dispatch('SEND_UPSELL_OVERSEE', previousUpsell.routerName)
            await this.$router.replace({ query: null })
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          if (responseCheckout.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('REMOVE_UPSELL', previousUpsell.routerName)
        }
      } catch (e) {
        console.log(e)
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        this.$store.commit('SET_FINISH', true)
        await this.$store.dispatch('FINISH')
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    this.$store.commit('SET_FINISH', true)
    await this.$store.dispatch('FINISH')
  },
  methods: {
    getLast30Days () {
      const today = new Date()
      const last30Days = []

      for (let i = 29; i >= 0; i--) {
        const date = new Date(today)
        date.setDate(today.getDate() - i)

        const formattedDate = date.toISOString().slice(0, 10)
        last30Days.push(formattedDate)
      }

      const startDate = last30Days[0]
      const endDate = last30Days[last30Days.length - 1]

      return [startDate, endDate]
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background: #f1f4f8;
  padding-top: 18px;
  padding-bottom: 32px;
}

.shipping-info-container {
    margin: 30px auto;
    padding: 0 15px;
    width: 100%;
}

.shipping-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-address-box {
  padding: 20px;
  background: #f4f6fa;
  border-radius: 10px;
  box-shadow: 0 0 5px -1px rgba(0,0,0,.3);
  margin-bottom: 30px;
}

.shipment-address-heading {
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid #bfbfbf;
    width: 80%;
    margin: 0 auto 26px;
    line-height: 30px;
    padding-bottom: 20px;
}

.section-edit-info__ul {
    list-style: none;
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
}

.article__list__item__label_thank {
  font-size: 14px;
}

.card {
  overflow: hidden;
}

.box-shadow {
  margin-bottom: -16px;
  margin-left: -16px;
  margin-right: -16px;
  box-shadow: rgb(0 0 0 / 40%) 0 1 5;
}

.banner {
  @include media('md-and-up') {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &__img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    font-size: 20px;
    flex-flow: column wrap;

    @include media('sm-and-down') {
      font-size: 16px;
    }

    .text--blue {
      color: rgb(0, 75, 169);
    }
  }
}

.recap {
  text-align: center;
  padding-top: 63px;
  font-size: 16px;

  &__small {
    margin-bottom: 0;
  }

  @include media('sm-and-down') {
    font-size: 14px;
    padding-top: 43px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .article__list {
    padding: 0 55px 32px;
    text-align: left;

    @include media('sm-and-down') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__thanks {
    color: #4a90e2;
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 63px;
    @include media('sm-and-down') {
      margin-bottom: 43px;
      font-size: 22px;
    }
  }

  &__title {
    font-size: 18px;

    @include media('sm-and-down') {
      font-size: 16px;
    }
  }
}

.santeclean-card {
  border: 1px dashed #000000;
  border-radius: 20px;
  text-align: center;
  background: #fff;
  padding: 10px 10px 5px;
  font-size: 16px;

  &__container {
    padding: 32px 55px;

    @include media('sm-and-down') {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &__text {
    margin-bottom: 0;
  }

  &__img {
    max-width: 100%;
    width: 343px;
    margin: 0 auto;
  }
}
</style>
