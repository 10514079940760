var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipping"},[_c('h3',[_vm._v(_vm._s(_vm.$t('landingPage.stepThree')))]),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.countryLabel'),"placeholder":_vm.$t('landingPage.countryPlaceholder'),"items":_vm.countriesComputed,"item-text":"label","item-value":"value","search-input":_vm.search,"value":_vm.$store.state.Order.address.country,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":"","flat":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){return _vm.$store.dispatch('SET_ADDRESS_BY_KEY', {
            key: 'country',
            value: $event,
          })}},model:{value:(_vm.$store.state.Order.address.country),callback:function ($$v) {_vm.$set(_vm.$store.state.Order.address, "country", $$v)},expression:"$store.state.Order.address.country"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.streetLabel'),"placeholder":_vm.$t('landingPage.streetPlaceholder'),"value":_vm.$store.state.Order.address.street,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":""},on:{"input":function($event){return _vm.$store.dispatch('SET_ADDRESS_BY_KEY', {
            key: 'street',
            value: $event,
          })}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.cityLabel'),"placeholder":_vm.$t('landingPage.cityPlaceholder'),"value":_vm.$store.state.Order.address.city,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":""},on:{"input":function($event){return _vm.$store.dispatch('SET_ADDRESS_BY_KEY', {
            key: 'city',
            value: $event,
          })}}})],1)],1),(_vm.$store.state.Order.address.country === 'BR')?_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"top":"","close-delay":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-text-field',{attrs:{"label":_vm.$t('landingPage.cpf'),"placeholder":_vm.$t('landingPage.cpfPlaceholder'),"value":_vm.$store.state.Order.address.vat,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":"","append-icon":"mdi-help-circle-outline"},on:{"input":function($event){return _vm.$store.dispatch('SET_ADDRESS_BY_KEY', {
            key: 'vat',
            value: $event,
          })},"mouseover":function($event){_vm.show = !_vm.show},"mouseleave":function($event){_vm.show = !_vm.show}}})]}}],null,false,1540988540),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v(_vm._s(_vm.$t('landingPage.cpf').toUpperCase())),_c('br'),_vm._v(_vm._s(_vm.$t('landingPage.cpfText')))])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.postalCodeLabel'),"placeholder":_vm.$t('landingPage.postalCodePlaceholder'),"value":_vm.$store.state.Order.address.zip_code,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":""},on:{"input":function($event){return _vm.$store.dispatch('SET_ADDRESS_BY_KEY', {
            key: 'zip_code',
            value: $event,
          })}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }