<template>
  <div class="errorPaiement">
  <header class="banner">
    <v-container>
      <v-img
        :src="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.src"
        :alt="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.alt"
        width="444"
        class="banner__img"
      />
    </v-container>
  </header>
  <div class="main">
    <div class="container" style="max-width: 760px;">
      <v-card class="card">
        <v-card-text>
          <v-row class="banner">
            <v-col cols="12" sm="6">
              <v-img
                src="https://d1yei2z3i6k35z.cloudfront.net/893893/63d3f68d66ea2_transaction-failed.webp"
                alt="transaction failed"
                width="444"
                class="banner__img"
              />
            </v-col>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <p class="banner__text">
                <b class="text--blue">{{$t('thank.needHelp')}}</b>{{$t('thank.contactUs')}}
              </p>
            </v-col>
          </v-row>
          <div class="box-shadow">
            <v-row class="recap d-flex" align="center"
                   justify="center">
              <v-col cols="10"  class="text-left">
                <p class="recap__title">{{$t('error.paimentRefused')}}</p>
                <p class="recap__title">{{$t('error.apologies')}}</p>
                <p class="recap__title">{{$t('error.retry')}}</p>
                <v-btn
                  :href="app_url + $store.state.Product.find(p => p.routerName === this.$route.name).locales + '/' + this.$route.name + '/checkout'"
                  color="#FFD711"
                  class="black--text"
                  >
                  >> {{$t('error.button')}}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
  </div>
</template>

<script>

export default {
  name: 'home-view',

  components: {},
  data () {
    return {
      app_url: process.env.VUE_APP_URL
    }
  },
  async mounted () {
    this.$store.commit('SET_FINISH', true)
  }
}
</script>

<style lang="scss" scoped>
.errorPaiement {
  height: 90%;
  background: #f1f4f8;
}
.main {
  background: #f1f4f8;
  padding-top: 18px;
  padding-bottom: 32px;
}

.card {
  overflow: hidden;
}

.banner {
  background: white;

  @include media('md-and-up') {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &__img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    font-size: 20px;
    flex-flow: column wrap;

    @include media('sm-and-down') {
      font-size: 16px;
    }

    .text--blue {
      color: rgb(0, 75, 169);
    }
  }
}

.recap {
  text-align: center;
  font-size: 16px;
  margin-top: 25px;
  margin-bottom: 25px;

  &__small {
    margin-bottom: 0;
  }

  @include media('sm-and-down') {
    font-size: 12px;
    padding-top: 43px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .article__list {
    padding: 0 55px 32px;
    text-align: left;

    @include media('sm-and-down') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__thanks {
    color: #4a90e2;
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 63px;
    @include media('sm-and-down') {
      margin-bottom: 43px;
      font-size: 22px;
    }
  }

  &__title {
    font-size: 18px;

    @include media('sm-and-down') {
      font-size: 16px;
    }
  }
}

.santeclean-card {
  border: 1px dashed #000000;
  border-radius: 20px;
  text-align: center;
  background: #fff;
  padding: 10px 10px 5px;
  font-size: 16px;

  &__container {
    padding: 32px 55px;

    @include media('sm-and-down') {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &__text {
    margin-bottom: 0;
  }

  &__img {
    max-width: 100%;
    width: 343px;
    margin: 0 auto;
  }
}
</style>
