<template>
  <div class="variant" >
    <v-menu :offset-y="true" class="menu" :top="false" v-if="this.$vuetify.breakpoint.smAndUp"  >
      <template v-slot:activator="{ on, attrs }">
          <v-card height="90px" outlined class="variant__card" v-bind="attrs" v-on="on">
            <v-icon :class="$i18n.locale === 'il' ? 'toggle rtl': 'toggle'" color="#3F99E7" small :style="attrs['aria-expanded'] === 'true' ? {'transform': 'rotate(180deg)' }: {}">mdi-arrow-down-drop-circle-outline</v-icon>
            <v-row align="center" no-gutters class="pl-3 pr-2 fill-height"  justify="center">
              <v-col cols="1" class="variant__card__number text-right font-weight-bold">
                {{variant.index + 1}}
              </v-col>
              <v-col cols="4" class="text-center variant__img">
                <div class="variant__container">
                  <v-img class="pa-4"
                         :src="variant.src"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="7">
                <v-container class="pa-1 pl-2" :style="$i18n.locale === 'il' ? {'padding-right': '15px!important'}:{}">
                <span class="font-weight-bold">{{ variant.title }} </span> <span>{{variant.type}}</span>
                </v-container>
              </v-col>
            </v-row>
          </v-card>
      </template>

      <v-list class="list" style="overflow-y:auto" max-height="500px">
        <v-list-item-group v-model="selected" mandatory color="rgba(255,153,1,.08)" :key="selected">
        <v-list-item
          :class="index === selected ? 'listItem selected after ': 'listItem'"
          :style="$i18n.locale === 'il' ? {'justify-content': 'flex-end','padding-right': '80px','padding-left': '10px'}: {}"
          v-for="(item, index) in getVariantsProduct"
          @change="updateVariant(variant.index, item)"
          :key="index">
          <div v-if="index === selected" :class="$i18n.locale === 'il' ? 'checkSelectedRtl' :'checkSelected'"/>
          <v-icon  v-if="index === selected" :class="$i18n.locale === 'il' ? 'checkIconSelectedRtl' :'checkIconSelected'" small color="white" >mdi-check</v-icon>
          <div class="left" :style="$i18n.locale === 'il' ? {order: '1', position: 'absolute', right: '0', ...(index === selected ?  {'border-left': '2px solid #f9c550', 'border-right': 'none'}: {'border-left': '1px solid #b4b4b4'})} : {}">
            <v-img max-height="64px" max-width="64px" :src="item.src"/>
          </div>
          <div class="right" :style="$i18n.locale === 'il' ? {order: '0'}: {}">
            <v-container class="pa-1 pl-2">
              <span class="font-weight-bold v-size--small black--text" >{{item.title}} </span> <span class="black--text">{{item.type}}</span>
            </v-container>
          </div>
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="this.$vuetify.breakpoint.xsOnly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card height="90px" outlined class="variant__card" v-bind="attrs" v-on="on">
          <v-icon class="toggle" color="#3F99E7" small :style="attrs['aria-expanded'] === 'true' ? {'transform': 'rotate(180deg)' }: {}">mdi-arrow-down-drop-circle-outline</v-icon>
          <v-row align="center" no-gutters class="pl-3 pr-2 fill-height"  justify="center">
            <v-col cols="1" class="variant__card__number text-right font-weight-bold">
              {{variant.index + 1}}
            </v-col>
            <v-col cols="4" class="text-center variant__img">
              <div class="variant__container">
                <v-img class="pa-4"
                       :src="variant.src"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="7">
              <v-container class="pa-1 pl-2">
                <span class="font-weight-bold">{{ variant.title }} </span> <span>{{variant.type}}</span>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </template>

      <v-list class="list" style="overflow-y:auto">
        <v-list-item-group v-model="selected" mandatory color="rgba(255,153,1,.08)" :key="selected">
          <v-list-item
            :class="index === selected ? 'listItem selected after ': 'listItem'"
            :style="$i18n.locale === 'il' ? {'justify-content': 'flex-end','padding-right': '80px','padding-left': '10px'}: {}"
             v-for="(item, index) in getVariantsProduct"
            @change="updateVariant(variant.index, item); dialog= !dialog"
            :key="index">
            <div v-if="index === selected" :class="$i18n.locale === 'il' ? 'checkSelectedRtl' :'checkSelected'"/>
            <v-icon  v-if="index === selected" :class="$i18n.locale === 'il' ? 'checkIconSelectedRtl' :'checkIconSelected'" small color="white" >mdi-check</v-icon>
            <div class="left" :style="$i18n.locale === 'il' ? {order: '1', position: 'absolute', right: '0', ...(index === selected ?  {'border-left': '2px solid #f9c550', 'border-right': 'none'}: {'border-left': '1px solid #b4b4b4'})} : {}">
              <v-img max-height="64px" max-width="64px" :src="item.src"/>
            </div>
            <div class="right" :style="$i18n.locale === 'il' ? {order: '0'}: {}">
              <v-container class="pa-1 pl-2">
                <span class="font-weight-bold v-size--small black--text" >{{item.title}} </span> <span class="black--text">{{item.type}}</span>
              </v-container>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-dialog>

  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: Object
    }
  },
  created () {
    this.selected = this.getVariantsProduct.findIndex(elem => elem.type === this.variant.type)
  },
  methods: {
    async updateVariant (indexVariantOrder, newVariant) {
      const variants = this.getVariantsOrder
      variants[indexVariantOrder] = newVariant
      await this.$store.dispatch('UPDATE_VARIANT', variants)
    }
  },
  computed: {
    getVariantsProduct () {
      return this.$store.getters.getVariantsProduct(this.$route.name)
    },
    getVariantsOrder () {
      return this.$store.getters.getVariantsOrder
    }
  },
  data: () => ({
    dialog: false,
    selected: 0
  })
}
</script>

<style lang="scss" scoped>
.variant {
  &__card {
    border-radius: 4px;
    border: 1px solid #b4b4b4;
    cursor: pointer;
    &__number {
      font-size: 100%;
      padding-right: 10px !important;
    }
  }
  &__container {
    border: 1px solid #f9c550;
    margin-right: 12px;
    border-radius: 6px;
    width: 65px;
    height: 65px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 4px;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border: 4px solid #f8e9d1;
      width: 100%;
      height: 100%;
    }
  }
  .toggle{
    cursor: pointer;
    height: 16px;
    width: 16px;
    transition: transform .2s;
    margin-left: auto;
    background-repeat: no-repeat;
    flex-shrink: 0;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .toggle.rtl {
    right: auto;
    left: 6px;
    top: 6px;
  };
}
.list {
  padding: 25px 20px 10px;
  height: 100%;
}
.listItem {
  padding-left: 0;
  display: flex;
  width: 100%;
  height: 70px;
  border: 1px solid #b4b4b4;
  box-sizing: border-box;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &:hover{
    border-color: #ffc438;
  }
  &.selected {
    border: 2px solid #ffc438;
    background: rgba(255,153,1,.08);
  }
}
.checkSelected {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: #ffc580 #ffc580 transparent transparent;
  border-style: solid;
  border-width: 14px;
}
.checkIconSelected {
  top: 0;
  right: 0;
  position: absolute;
}

.checkSelectedRtl {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-color: #ffc580 transparent transparent  #ffc580;
  border-style: solid;
  border-width: 14px;
}
.checkIconSelectedRtl {
  top: 0;
  left: 0;
  position: absolute;
}

.left {
  height: 100%;
  padding: 2px;
  width: 70px;
  border-right: 1px solid #b4b4b4;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.right{
  font-size: smaller;
}

.listItem:hover .left {
  border-color: #ffc438 !important;
}
.listItem.selected .left {
  border-right:2px solid #ffc438;
}
</style>
