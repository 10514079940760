<template>
  <section class="section_congrats text-center align-center">
    <v-container class="container_congrats">
    <h4 class="message_congrats">
      <strong v-html="metadata.bannerCongrats"/>
    </h4>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    metadata: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.section_congrats {
  padding: 0px 15px;
  @media (max-width: 960px) {
    padding:10px!important;
    margin:0!important;
  }
}

.container_congrats {
  padding-top: 15px;
  @media (min-width: 1904px){
    max-width: 1300px;
  }
  @media (max-width: 960px) {
    padding:0!important;
    margin:0!important;
  }
}
.message_congrats {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 22px;
  color:rgba(0, 0, 0, 0.72);
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  line-height: 28px;

  @media (max-width: 960px) {
    font-size: 25px;
    line-height: 29px;  }
}
</style>
