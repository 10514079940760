export default {
  environment: 'PRODUCTION',
  buttonColor: 'white',
  baseRequest: {
    apiVersion: 2,
    apiVersionMinor: 0
  },
  allowedCardNetworks: [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA'
  ],
  allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
  merchantInfo: {
    merchantName: process.env.VUE_APP_MERCHANT_IDENTIFIER,
    merchantId: 'BCR2DN4TYSNYH5JH'
  },
  transactionInfo: {
    totalPriceStatus: 'FINAL',
    totalPrice: '1.00',
    currencyCode: 'EUR',
    countryCode: 'FR'
  },
  tokenizationSpecification: {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'checkoutltd',
      gatewayMerchantId: process.env.VUE_APP_CHECKOUT_PUBLIC_KEY
    }
  },
  baseCardPaymentMethod: {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: [
        'AMEX',
        'DISCOVER',
        'INTERAC',
        'JCB',
        'MASTERCARD',
        'VISA'
      ]
    }
  },
  cardPaymentMethod: null,
  paymentDataRequest: {
    baseRequest: {
      apiVersion: 2,
      apiVersionMinor: 0
    },
    allowedPaymentMethods: null,
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPrice: '1.00',
      currencyCode: 'EUR',
      countryCode: 'FR'
    },
    merchantInfo: {
      merchantName: process.env.VUE_APP_MERCHANT_IDENTIFIER,
      merchantId: 'BCR2DN4TYSNYH5JH'
    }
  }
}
