<template>
    <div class="product__section" v-if="active">
      <v-container class="product__container">
        <v-card class="product__card elevation-0">
          <v-row class="ma-0">
          <v-col cols="7" class="product__listing">
            <h1 class="product__title">{{product.title}} </h1>
            <div class="col d-flex flex-column pa-0 justify-content-start align-items-start text-al">
              <div v-for="(n,index) in 3   " :key="n"  class="d-flex flex-row justify-content-start align-items-center">
                <img src="@/assets/CheckoutOrder/list-checkmark.svg" alt="checkmark" width="20px" height="auto" class="product__icon"/>
                <span class="product__text">{{ product.caracteristics[index] }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="4" class="my-auto d-flex flex-row align-items-center justify-content-center custom-header-img h-full pa-0">
            <img  class="product__img" :src="product.thirdImg.src" :alt="product.thirdImg.alt" />
          </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean
    },
    product: {
      type: Object
    }
  }
}
</script>
<style lang="scss" scoped>
.product {
  &__section {
    position: relative !important;
    padding-top: 32px;
    z-index: 100;
    overflow: hidden;
    display: block !important;
    background: #000;
    background-image: url('@/assets/CheckoutOrder/product-section-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: 100%;
    top: 0;

    &:after {
      content: "";
      display: block;
      background: #f7f7f7;
      position: absolute;
      width: 100%;
      left: 0;
      height: 70%;
      bottom: -34%;
      border-radius: 50%;
      z-index: -100;
      overflow: hidden;
    }

    @media screen and (max-width: 767px) {
      display:none !important;
    }
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 970px;
    padding: 0 15px !important;
    margin-bottom: 8px;
    @media screen and (min-width: 992px) {
      max-width: 1030px;
    }
    @media screen and (min-width: 768px) {
      max-width: 970px;
    }
  }

  &__card {
    border: 1px solid #dee2e6!important;
    border-radius: 2.5px;
    padding: 30px
  }

  &__listing {
    font-family: Work Sans, sans-serif !important;
    font-size: 15px;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 1px;
    margin-bottom: 1px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__list {
    padding: 0;

    li {
      background-size: 20px;
      list-style: none;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__title {
    font-size: 39px;
    font-family: Work Sans, sans-serif !important;
    line-height: 1.2;
    margin-bottom: 15px !important;
    @media screen and (min-width: 768px) {
      margin-bottom: 30px !important;
    }
  }

  &__text {
    padding: 10px 0 10px 0;
  }

  &__img {
    max-height: 300px;
    width: auto;
  }
}
</style>
