import Vue from 'vue'
import VueRouter from 'vue-router'
import Thank from '../views/Thank.vue'
import Inter2 from '../views/UpsellTemplate.vue'
import Inter from '@/views/Inter'
import Checkout from '../views/Checkout'
import CheckoutOrder from '../views/CheckoutOrder'
import Home from '../views/Home'
import Error from '../views/Error.vue'
import { languages } from '../locales/index'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: []
})

router.addRoute({ path: '/', component: Home, name: 'home' })

function previousUpsellMatches (path, routerName) {
  return path.includes(routerName)
}

function getPreviousUpsellIndices (product, routerName) {
  const baseIndex = product.upsell.findIndex(upsellFind =>
    upsellFind.routerName === routerName ||
    upsellFind?.abtesting?.routerName === routerName ||
    upsellFind?.downsell?.routerName === routerName
  )
  const previousIndex = baseIndex >= 0 ? baseIndex - 1 : -1
  return [baseIndex, previousIndex]
}

function interBeforeEnter (to, from, next, product, upsell, language) {
  const isCheckout = from.path.includes('/checkout') || from.path.includes('/CheckoutOrder')
  const hasCkoSessionId = 'cko-session-id' in to.query
  const hasToken = 'token' in to.query
  const upsellRouterNames = [upsell?.routerName, upsell?.abtesting?.routerName, upsell?.downsell?.routerName].filter(Boolean)

  const previousUpsellMatchesArray = upsellRouterNames.map(routerName => {
    const [, previousIndex] = getPreviousUpsellIndices(product, routerName)
    const matchesCurrent = previousUpsellMatches(from.path, routerName)
    const matchesPrevious = previousIndex >= 0 ? previousUpsellMatches(from.path, product.upsell[previousIndex]?.routerName) : false
    const matchesPreviousAbtesting = previousIndex >= 0 ? previousUpsellMatches(from.path, product.upsell[previousIndex]?.abtesting?.routerName) : false
    const matchesPreviousDownsell = previousIndex >= 0 ? previousUpsellMatches(from.path, product.upsell[previousIndex]?.downsell?.routerName) : false
    return matchesCurrent || matchesPrevious || matchesPreviousAbtesting || matchesPreviousDownsell
  })

  const currentUpsellMatches = upsellRouterNames.some(routerName => previousUpsellMatches(from.path, routerName))

  const shouldContinue = (
    isCheckout ||
    hasCkoSessionId ||
    hasToken ||
    previousUpsellMatchesArray.some(Boolean) ||
    currentUpsellMatches
  ) && !store.state.Order.finish

  if (shouldContinue) {
    return next()
  } else {
    const redirectPath = store.state.Order.finish ? `/${language}/${product.routerName}/thank` : `/${language}/${product.routerName}/checkout`
    return next(redirectPath)
  }
}

function addRoutesForLanguage (language) {
  for (const product of store.state.Product) {
    const productPath = `/${language}/${product.routerName}`
    router.addRoute({ path: `${productPath}/checkout`, component: Checkout, name: product.routerName })
    router.addRoute({ path: `${productPath}/CheckoutOrder`, component: CheckoutOrder, name: product.routerName })
    router.addRoute({ path: `${productPath}/error`, component: Error, name: product.routerName })

    if (product.upsell.length) {
      for (const upsell of product.upsell) {
        const upsellPath = `${productPath}/upsell/${upsell.routerName}`
        const interPath = `${productPath}/inter/${upsell.routerName}`

        router.addRoute({
          path: upsellPath,
          name: product.routerName,
          component: Inter2,
          beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
        })
        router.addRoute({
          path: interPath,
          name: product.routerName,
          component: Inter,
          beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
        })

        if (upsell.abtesting) {
          const abtestingUpsellPath = `${productPath}/upsell/${upsell.abtesting.routerName}`
          const abtestingInterPath = `${productPath}/inter/${upsell.abtesting.routerName}`

          router.addRoute({
            path: abtestingUpsellPath,
            name: product.routerName,
            component: Inter2,
            beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
          })
          router.addRoute({
            path: abtestingInterPath,
            name: product.routerName,
            component: Inter,
            beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
          })
        }

        if (upsell.downsell) {
          const downsellUpsellPath = `${productPath}/upsell/${upsell.downsell.routerName}`
          const downsellInterPath = `${productPath}/inter/${upsell.downsell.routerName}`

          router.addRoute({
            path: downsellUpsellPath,
            name: product.routerName,
            component: Inter2,
            beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
          })
          router.addRoute({
            path: downsellInterPath,
            name: product.routerName,
            component: Inter,
            beforeEnter: (to, from, next) => interBeforeEnter(to, from, next, product, upsell, language)
          })
        }
      }
    }

    router.addRoute({
      path: `${productPath}/thank`,
      name: product.routerName,
      component: Thank,
      beforeEnter: (to, from, next) => {
        if (from.path.includes('/inter') || from.path.includes('/upsell') || store.state.Order.finish || 'cko-session-id' in to.query || 'token' in to.query) {
          return next()
        } else {
          return next(`${productPath}/checkout`)
        }
      }
    })
  }
}

const languagesArray = Object.keys(languages)
languagesArray.forEach(language => addRoutesForLanguage(language))

router.beforeEach(function (to, from, next) {
  const isCheckout = to.path.includes('/checkout') || to.path.includes('/CheckoutOrder')
  const hasCkoSessionId = 'cko-session-id' in to.query
  const hasToken = 'token' in to.query

  if (isCheckout && store.state.Order.finish && (hasCkoSessionId || hasToken)) {
    return next(to.path)
  }

  if (isCheckout && (hasCkoSessionId || hasToken) && 'success' in to.query && to.query.success === 'false' && store.state.Order.finish) {
    return next(to.path)
  }

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)

  next()
})

Sentry.init({
  Vue,
  dsn: 'https://d4a21dda358240949f67eccf7dcbd010@o4505271761502208.ingest.us.sentry.io/4505271762812928',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'sante-healthclean.com', /^\//]
    })
  ],
  ignoreErrors: [
    /Blocked a frame with origin/,
    'TypeError: (0 , i.openBlock) is not a function',
    'render$3(frames-vue/dist/frames-vue.esm)',
    '(0 , vue__WEBPACK_IMPORTED_MODULE_0__.openBlock) is not a function',
    '(0 , n.openBlock) is not a function',
    '(0 , n.openBlock) is not a function\n',
    /(0 , .*\.)?openBlock is not a function/ // Regex to ignore variations
  ],
  tracesSampleRate: 1.0
})

export default router
