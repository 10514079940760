<template>
  <div>
    <OverlayLoading :overlay="overlay" :loading="loading" />
    <header class="banner">
      <v-container>
        <v-img
          :src="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.src"
          :alt="$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.alt"
          width="444"
          class="banner__img"
        />
      </v-container>
    </header>
    <div class="main">
      <ImgTextSection />
      <SpecialOfferSection />
      <FormSection  @loading="(callback) => overlay = callback"/>
    </div>
<!--    <PopupViewer-->
<!--      :key="this.$i18n.locale"-->
<!--      :src="getImageSource()"-->
<!--      :alt="getMainImageAlt()"-->
<!--      :article="getProductName()"-->
<!--      :popup1="getPopupValue(1)"-->
<!--      :popup2="getPopupValue(2)"-->
<!--      :popup3="getPopupValue(3)"-->
<!--      :init-count="getPopupTimerValue()"></PopupViewer>-->
  </div>
</template>

<script>
import ImgTextSection from '@/components/Checkout/ImgTextSection.vue'
import SpecialOfferSection from '@/components/Checkout/SpecialOfferSection.vue'
import FormSection from '@/components/Checkout/FormSection.vue'
import * as Sentry from '@sentry/browser'
import OverlayLoading from '@/components/OverlayLoading'
import Brevo from '@/utils/Brevo'
export default {
  name: 'home-view',

  components: {
    OverlayLoading,
    ImgTextSection,
    SpecialOfferSection,
    FormSection,
  },
  methods: {
    getStatusUserIfFailed () {
      return {
        mail: this.$store.state.Order.contact.email,
        locale: this.$i18n.locale,
        name: this.$store.state.Order.contact.name,
        productImg: this.$store.state.Product.find(p => p.routerName === this.$route.name).mainImg.src,
        productName: this.$store.state.Product.find(p => p.routerName === this.$route.name).name,
        productUrl: process.env.VUE_APP_URL + this.$route.path.substring(1),
        logo: this.$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.src,
        productDescription: this.$store.state.Product.find(p => p.routerName === this.$route.name).caracteristics[0]
      }
    },
    getImageSource () {
      const product = this.getProduct()
      return product.list[0].src_img || product.secondImg.src
    },
    getMainImageAlt () {
      return this.getProduct().mainImg.alt
    },
    getProductName () {
      return this.getProduct().name
    },
    getPopupValue (index) {
      const popups = this.getProduct().popups
      return popups && popups[`popup${index}`] ? popups[`popup${index}`] : true
    },
    getPopupTimerValue () {
      const popups = this.getProduct().popups
      return popups ? popups.timer || 1 : 1
    },
    getProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
  },
  computed: {
  },
  data () {
    return {
      overlay: false,
      loading: false,
      approved: null
    }
  },
  async mounted () {
    if (this.$route.query.click_id) {
      this.$store.commit('SET_CLICK_ID', this.$route.query.click_id)
    }
    if (this.$route.query.token) {
      this.overlay = true
      this.loading = true
      const responsePaypal = await this.$store.dispatch('GET_PAYMENTS_BY_PAYPAL', this.$route.query.token)
      if (responsePaypal.status === 'APPROVED') {
        const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
        this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], payment_type: 'paypal' })
        const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
        if (!commandOverseeExist.length) {
          await this.$store.dispatch('SEND_COMMAND_OVERSEE')
          new Brevo('order_completed', this.$store, this.$route).track()
        }
        if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
        this.loading = false
        this.approved = true
        this.$store.commit('SET_FINISH', false)
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
      } else {
        const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
        this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
        await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    if (this.$route.query['cko-session-id']) {
      this.overlay = true
      this.loading = true
      try {
        const responseCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', this.$route.query['cko-session-id'])
        if (responseCheckout.approved) {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: responseCheckout.source.id })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.loading = false
          this.approved = true
          this.$store.commit('SET_FINISH', false)
          if (responseCheckout.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    this.overlay = false
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>

.banner {
  &__img {
    margin: 0 auto;
  }
}

.main {
  background: #f1f4f8;
  padding-top: 18px;
  padding-bottom: 32px;
}

</style>
