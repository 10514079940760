import axios from 'axios'

class UserTracker {
  constructor (router, store) {
    this.actions = []
    this.visitedPages = new Set()
    this.router = router
    this.router.afterEach(this.trackPage.bind(this))
    this.store = store
    this.url = 'https://logs-app-84a4327d42d8.herokuapp.com/'
    axios.interceptors.request.use((config) => {
      if (!config.url.includes(this.url + 'logs') && config.method !== 'get') {
        this.trackRequest(config)
      }
      return config
    })
  }

  trackPage () {
    const currentPage = this.router.currentRoute.fullPath
    const { fullPath, hash, name, params, path, query } = this.router.currentRoute
    if (!this.visitedPages.has(currentPage)) {
      this.visitedPages.add(currentPage)

      const pageData = {
        type: 'page_view',
        page: { fullPath, hash, name, params, path, query },
        timestamp: new Date(),
      }
      this.actions.push(pageData)
      this.sendRequest(pageData)
    }
  }

  trackRequest (config) {
    const requestData = {
      type: 'request',
      method: config.method,
      url: config.url,
      data: config.data,
      timestamp: new Date()
    }
    this.actions.push(requestData)
    this.sendRequest(requestData)
  }

  sendRequest (data) {
    axios.post(this.url + 'logs', {
      ...data,
      product: this.store.state.Product.find(p => p.routerName === this.router.currentRoute.name),
      order: this.store.state.Order,
      base_url: process.env.VUE_APP_URL
    })
      .then(response => {
        const index = this.actions.indexOf(data)
        if (index !== -1) {
          this.actions.splice(index, 1)
        }
      })
      .catch(error => {
        console.error('Erreur lors de l\'envoi de la requête:', error)
      })
  }
}

export default UserTracker
