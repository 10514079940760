import i18n from '../plugins/i18n'

export const required = (value) => !value ? i18n.tc('validation.required') : true
// eslint-disable-next-line
const emailRegex = new RegExp('^(?=.{2,30}@)[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}$')
export const isEmail = (value) => !emailRegex.test(value) ? i18n.tc('validation.isEmail') : true
export const isPhone = (value) => (value || '').length >= 6 && (value || '').length < 25 && digitRegex.test(value)
export const minPhoneNumber = v => (v || '').length >= 6 || i18n.tc('validation.minPhoneNumber')
export const maxPhoneNumber = v => (v || '').length < 25 || i18n.tc('validation.maxPhoneNumber')
const digitRegex = /^[0-9]+$/
export const onlyDigit = (value) => !digitRegex.test(value) ? i18n.tc('validation.isDigit') : true
