import { render, staticRenderFns } from "./variantCard.vue?vue&type=template&id=03184f18&scoped=true&"
import script from "./variantCard.vue?vue&type=script&lang=js&"
export * from "./variantCard.vue?vue&type=script&lang=js&"
import style0 from "./variantCard.vue?vue&type=style&index=0&id=03184f18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03184f18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VDialog,VIcon,VImg,VList,VListItem,VListItemGroup,VMenu,VRow})
