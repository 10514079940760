<template>
  <div class="contact">
    <h3>{{$t('landingPage.stepTwo')}}</h3>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.firstNameLabel')"
          :placeholder="$t('landingPage.firstNamePlaceholder')"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'first_name',
              value: $event,
            })
          "
          :value="$store.state.Order.contact.first_name"
          :rules="[
            required
          ]"
          persistent-placeholder
          outlined
        />
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-text-field
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.lastNameLabel')"
          :placeholder="$t('landingPage.lastNamePlaceholder')"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'last_name',
              value: $event,
            })"
          :value="$store.state.Order.contact.last_name"
          :rules="[
            required
          ]"
          persistent-placeholder
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-1">
        <v-text-field
          ref="email"
          id="email"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.emailLabel')"
          :placeholder="$t('landingPage.emailPlaceholder')"
          @blur="SendEmail"
          @click="resetError"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'email',
              value: $event,
            })
          "
          :error-messages="errors"
          :value="$store.state.Order.contact.email"
          :rules="[
            required,
            isEmail
          ]"
          persistent-placeholder
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-1">
        <v-text-field
          type="phone"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.phoneLabel')"
          :placeholder="$t('landingPage.phonePlaceholder')"
          @blur="SendPhone"
          @input="
            $store.dispatch('SET_CONTACT_BY_KEY', {
              key: 'phone',
              value: $event,
            })
          "
          :value="$store.state.Order.contact.phone"
          :rules="[
            required,
            minPhoneNumber,
            maxPhoneNumber,
            onlyDigit
          ]"
          persistent-placeholder
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, isEmail, minPhoneNumber, maxPhoneNumber, onlyDigit, isPhone } from '@/utils/validation'
import axios from 'axios'
import Brevo from '@/utils/Brevo'
export default {
  data () {
    return {
      errors: '',
    }
  },
  methods: {
    required,
    isEmail,
    minPhoneNumber,
    maxPhoneNumber,
    onlyDigit,
    isPhone,

    async zeroBounce (value) {
      try {
        const responseZeroBounce = await axios.get(`https://overseee-api.herokuapp.com/user/verify_email/${value}`)
        if (responseZeroBounce.data.error) {
          this.errors = ''
          this.$refs.email.valid = true
          return
        }
        if (responseZeroBounce.status === 200) {
          if (responseZeroBounce.data.status === 'valid' || responseZeroBounce.data.status === 'unknown' || responseZeroBounce.data.status === 'catch-all') {
            this.errors = ''
            this.$refs.email.valid = true
          } else {
            this.errors = this.$i18n.t('validation.isEmail')
            this.$refs.email.valid = false
          }
        } else {
          this.errors = ''
          this.$refs.email.valid = true
        }
      } catch (error) {
        this.errors = ''
        this.$refs.email.valid = true
      }
    },

    resetError () {
      this.errors = ''
      this.$refs.email.valid = true
    },
    async SendEmail () {
      await this.zeroBounce(this.$store.state.Order.contact.email)
      if (isEmail(this.$store.state.Order.contact.email) === true && isPhone(this.$store.state.Order.contact.phone) === false && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_TRACKING', {
        //   type: 'email',
        //   tags: this.$route.name,
        //   id: this.$store.state.Order.contact.email,
        //   countryCode: this.$i18n.locale
        // })
      }
      if ((isEmail(this.$store.state.Order.contact.email) === true) &&
        isPhone(this.$store.state.Order.contact.phone) === true && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_CUSTOM_TRACKING',
        //   { tags: this.$route.name, countryCode: this.$i18n.locale })
      }
    },
    async SendPhone () {
      if (isPhone(this.$store.state.Order.contact.phone) === true &&
        isEmail(this.$store.state.Order.contact.email) === false && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_TRACKING', {
        //   type: 'phone',
        //   tags: this.$route.name,
        //   id: this.$store.state.Order.contact.phone,
        //   countryCode: this.$i18n.locale
        // })
      }
      if ((isEmail(this.$store.state.Order.contact.email) === true) &&
        isPhone(this.$store.state.Order.contact.phone) === true && this.$refs.email.valid) {
        new Brevo('checkout_started', this.$store, this.$route).track()
        // await this.$store.dispatch('SEND_CUSTOM_TRACKING',
        //   { tags: this.$route.name, countryCode: this.$i18n.locale })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
