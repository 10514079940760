var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('h3',[_vm._v(_vm._s(_vm.$t('landingPage.stepTwo')))]),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.firstNameLabel'),"placeholder":_vm.$t('landingPage.firstNamePlaceholder'),"value":_vm.$store.state.Order.contact.first_name,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":""},on:{"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'first_name',
            value: $event,
          })}}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.lastNameLabel'),"placeholder":_vm.$t('landingPage.lastNamePlaceholder'),"value":_vm.$store.state.Order.contact.last_name,"rules":[
          _vm.required
        ],"persistent-placeholder":"","outlined":""},on:{"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'last_name',
            value: $event,
          })}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{ref:"email",attrs:{"id":"email","reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.emailLabel'),"placeholder":_vm.$t('landingPage.emailPlaceholder'),"error-messages":_vm.errors,"value":_vm.$store.state.Order.contact.email,"rules":[
          _vm.required,
          _vm.isEmail
        ],"persistent-placeholder":"","outlined":""},on:{"blur":_vm.SendEmail,"click":_vm.resetError,"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'email',
            value: $event,
          })}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"phone","reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.phoneLabel'),"placeholder":_vm.$t('landingPage.phonePlaceholder'),"value":_vm.$store.state.Order.contact.phone,"rules":[
          _vm.required,
          _vm.minPhoneNumber,
          _vm.maxPhoneNumber,
          _vm.onlyDigit
        ],"persistent-placeholder":"","outlined":""},on:{"blur":_vm.SendPhone,"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'phone',
            value: $event,
          })}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }