<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row class="form-reduction" no-gutters>
        <v-col cols="auto" class="px-2 py-0" :style="$i18n.locale === 'il' ? {order: '1'}:{}">
          <img
            :src="urlImg"
            alt="50% off badge"
            class="form-reduction__img"
          />
        </v-col>
        <v-col class="px-2 py-0" :style="$i18n.locale === 'il' ? {order: '0'}:{}">
          <p class="form-reduction__label body-1">
            <span class="form-reduction__label--bold">
              {{ $t('landingPage.yourDiscountCode') }}
              <b class="red--text"> {{ $t('landingPage.percentage') }}</b>
              {{ $t('landingPage.hasBeenApplied') }}<br/>
              <b class="red--text">
                {{ $t('landingPage.codeExpires') }} {{ minutes }}:{{ seconds }}
              </b>
            </span><br/>
          </p>
          <template v-if="$vuetify.breakpoint.smAndUp">
            <p class="mb-2"><i>{{ $t('landingPage.notClosePage') }}</i><br/></p>
            <p>{{ $t('landingPage.freeDeliveryDay') }}</p>
          </template>
        </v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <v-col class="pt-0">
          <p class="mb-2"><i>{{ $t('landingPage.notClosePage') }}</i><br/></p>
          <p>{{ $t('landingPage.freeDeliveryDay') }}</p>
        </v-col>
      </v-row>
      <FormArticle
        v-if="this.$route.name && getCurrentProduct"
        :key="getCurrentProduct.id"
      />
      <FormVariant
        v-if="getVariantsProduct.length"
        :article="getSelectedArticle[0]"
        :key="getSelectedArticle[0].name"
      />
      <FormContact/>
    </v-card-text>
  </v-card>
</template>

<script>
import FormArticle from '@/components/Checkout/FormElements/FormArticle.vue'
import FormContact from '@/components/Checkout/FormElements/FormContact.vue'
import FormVariant from '@/components/Checkout/FormElements/FormVariant.vue'

export default {
  components: {
    FormVariant,
    FormArticle,
    FormContact
  },

  data () {
    return {
      duration: 60 * 30,
      minutes: '30',
      seconds: '00'
    }
  },
  computed: {
    getSelectedArticle () {
      return this.$store.getters.getSelectedArticle
    },
    getVariantsProduct () {
      return this.$store.getters.getVariantsProduct(this.$route.name)
    },
    getCurrentProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
    urlImg () {
      try {
        return require(`@/assets/${this.$i18n.locale}-50off.png`)
      } catch (e) {
        return require('@/assets/en-50off.png')
      }
    }
  },
  mounted () {
    this.startTimer(this.duration)
  },

  methods: {
    startTimer (duration) {
      let timer = duration
      setInterval(() => {
        this.minutes = parseInt(timer / 60, 10)
        this.seconds = parseInt(timer % 60, 10)

        this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes
        this.seconds = this.seconds < 10 ? '0' + this.seconds : this.seconds

        if (--timer < 0) {
          timer = duration
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-reduction {
  margin: 0 -12px;

  &__img {
    width: 107px;
    height: auto;
  }

  &__label {
    margin-bottom: 0;

    &--bold {
      font-weight: bold;
    }
  }

  &__label,
  &__label span {
    line-height: 1;
  }

  &__label span {
    font-size: 18px !important;
  }
}
</style>
