import { render, staticRenderFns } from "./OverlayLoading.vue?vue&type=template&id=4d89725e&scoped=true&"
import script from "./OverlayLoading.vue?vue&type=script&lang=js&"
export * from "./OverlayLoading.vue?vue&type=script&lang=js&"
import style0 from "./OverlayLoading.vue?vue&type=style&index=0&id=4d89725e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d89725e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VOverlay})
