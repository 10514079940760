<template>
  <div class="shipping mb-6">
    <v-col cols="12">
      <v-radio-group v-model="$store.state.Order.payment_method">
        <v-row justify="center" >
          <div class="methodCard " @click="choicePaymentMethod('card')">
            <v-col type="radio" cols="6" :class=" $store.state.Order.payment_method === 'card' ? ' creditLabel__isActive creditLabel d-flex': 'creditLabel creditLabel__noActive d-flex'"
                  :style="$i18n.locale === 'il' ? {'border-top-right-radius': '4px','border-right': 'solid 1px #c2c2c2','border-top': 'solid 1px #c2c2c2', 'border-top-left-radius':'0px', 'border-left': '0'}:{}"
            >
              <v-radio :class="$store.state.Order.payment_method === 'card' ? 'labelCreditCard__isActive' :'labelCreditCard'" :label="$t('landingPage.creditCard')" value="card"></v-radio>
            </v-col>
            <v-col cols="6" :class="$store.state.Order.payment_method === 'card' ? 'creditLogo__isActive d-flex'   : ' creditLogo creditLogo__noActive d-flex'"
                   :style="$i18n.locale === 'il' ? {'border-top-left-radius': '4px','border-left': 'solid 1px #c2c2c2','border-top': 'solid 1px #c2c2c2', 'border-top-right-radius':'0px', 'border-right': '0'}:{}"
            >
              <div style="width: 100%;text-align: right;">
              <img
                src="@/assets/visamastercard3logo.png"
                alt="credit card"
                style="right: 0"
                height="40px"
              />
              </div>
            </v-col>
          </div>
          <v-col cols="12" :class="$store.state.Order.payment_method === 'card' ? 'cardCredit__isActive' : 'cardCredit'" v-show="$store.state.Order.payment_method==='card'">
            <Frames
              :config="config"
              @ready="ready"
              @frameFocus="frameFocus"
              @cardTokenized="cardTokenized"
            />
            <label for="card-number">{{$t('landingPage.cardNumber')}}</label>
            <div class="input-container card-number">
              <div class="icon-container">
                <img
                  id="icon-card-number"
                  src="@/assets/card.svg"
                  alt="PAN"
                />
              </div>
              <div class="card-number-frame"></div>

            </div>
            <v-row class="pb-5">
              <v-col cols="6">
                <label for="card-number">{{$t('landingPage.expirationDate')}}</label>

                <div class="checkout pa-3 expiry-date-frame">

                  <!-- expiry date will be added here -->
                </div>
              </v-col>
              <v-col cols="6">
                <label for="card-number">{{$t('landingPage.securityCode')}}</label>

                <div class="checkout pa-3 cvv-frame">
                  <!-- cvv frame will be added here -->
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="appleCheck">
            <v-row  :class="$store.state.Order.payment_method === 'applePay' ? 'sofort__isActive d-flex': 'sofort d-flex'" :style="paypalCheck ? {'border-bottom-left-radius': 0,'border-bottom-right-radius': 0} : '' ">
              <div class="methodCard" @click="choicePaymentMethod('applePay')">
                <v-col type="radio" cols="6">
                <v-radio :class="$store.state.Order.payment_method === 'applePay' ? 'sofortLabel__isActive' : 'sofortLabel' " label="Apple Pay" value="applePay">
                </v-radio>
              </v-col>
              <v-col cols="6" style="padding: 0; margin: 0" class="align-content-end" align="right">
                  <img  src="@/assets/apple-pay.png"
                        alt="apple pay" class="applePay"/>
              </v-col>
              </div>
              <transition name="fade">
                <v-col v-if="$store.state.Order.payment_method === 'applePay'" cols="12" class="textSofort"  @click="choicePaymentMethod('applePay')">
                  {{ $t('landingPage.sofort') }}
                </v-col>
                </transition>
             </v-row>
          </v-col>
          <PaypalCheckout v-if="paypalCheck"/>
        </v-row>
      </v-radio-group>
    </v-col>
  </div>
</template>

<script>
import { Frames } from 'frames-vue'
import PaypalCheckout from '@/components/Paypal/PaypalCheckout'

export default {
  components: { Frames, PaypalCheckout },
  data () {
    return {
      disable: false,
      check: true,
      test: '',
      appleCheck: false,
      config: {
        debug: false,
        publicKey: process.env.VUE_APP_CHECKOUT_PUBLIC_KEY,
        localization: {
          cardNumberPlaceholder: this.$t('landingPage.123'),
          expiryMonthPlaceholder: this.$t('landingPage.mm'),
          expiryYearPlaceholder: this.$t('landingPage.yy'),
          cvvPlaceholder: this.$t('landingPage.cvc')
        },
        style: {
          base: {
            fontSize: '14px'
          }
        }
      }
    }
  },
  async created () {
    await this.$store.dispatch('SET_PAYMENT_METHOD', 'card')

    if (window.ApplePaySession) {
      this.appleCheck = true
    }
  },
  computed: {
    paypalCheck () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name).paypal
    }
  },
  methods: {
    async choicePaymentMethod (value) {
      await this.$store.dispatch('SET_PAYMENT_METHOD', value)
    },
    cardTokenized (e) { this.$store.commit('SET_TOKEN', e.token) },
    ready (e) { },
    frameFocus (e) { },
    submitCard () { Frames.submitCard() }
  }
}

</script>

<style>

</style>

<style lang="scss" scoped>
.googlePay {
  margin-right: 30px;
  height: 25px;
  margin-top: 10px;
  width: 50px;
}
.applePay {
  margin-right: 30px;
  height: 50px;
  margin-top:   0px;
  width: 50px;
}
.size{
  height: 50px;
}
.textSofort {
  font-size: 14px;
  line-height: 1.43;
  margin: 0;
  padding: 0 40px 10px 43px;
  &:hover {
    cursor: pointer;
  }
}

.labelCreditCard{
  &__isActive {
    font-size: 16px;
    font-weight: 500;
    transition: text-decoration-color 300ms;
  }
}
.sofortLabel {
 &__isActive {
   font-size: 16px;
   font-weight: 500;
   transition: text-decoration-color 300ms;
 }
}
.sofort {
  border: solid 1px #c2c2c2;
  border-top: none;
  border-bottom-left-radius: 4px ;
  border-bottom-right-radius: 4px ;

  &:hover {
    cursor: pointer;
  }

  &__isActive {
    border-bottom-left-radius: 4px ;
    border-bottom-right-radius: 4px ;
    border: solid 2px black;
  }

}

.one-liner {
  width: 100%;
}
.methodCard {
  display: flex;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}
.creditLabel {
  border-left:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
  border-top-left-radius: 4px ;
  &__isActive {
    border-top-left-radius: 4px ;
    border-left: solid 2px black;
    border-top: solid 2px black;
  }
  &__base {
    border-top-left-radius: 4px ;
    border-left: solid 1px #c2c2c2;
    border-top: solid 1px #c2c2c2;
  }
  &__noActive {
    border-bottom:solid 1px #c2c2c2; ;
  }
}
.creditLogo {
  border-top-right-radius: 4px ;
  border-right:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
  &__isActive {
    border-top-right-radius: 4px ;
    border-right: solid 2px black;
    border-top: solid 2px black;
  }
  &__base {
    border-top-right-radius: 4px ;
    border-right: solid 1px #c2c2c2;
    border-top: solid 1px #c2c2c2;
  }
  &__noActive {
    border-bottom:solid 1px #c2c2c2; ;
  }
}

.cardCredit {
  width: 100%;
  display: flex;
  border-top: solid 1px #c2c2c2;
  &__isActive{
    border: solid 2px black;
  }
  &__base {
    border: solid 1px #c2c2c2;
  }
}
.checkout {
  height: 100px;
}
.credit {
  border: solid 1px #c2c2c2;
  border-radius: 4px;
  &__isActive {
    border-bottom: none;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-top: solid 2px black;
  }
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: gray;
}

.checkout {
  height: 50px;
  border-radius: 2px;
  border: 1px solid #ddd;
  font-size: 15px;
  color: #9b9b9b;
  font-family: Work Sans,sans-serif!important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  width: 280px;
  margin: 0 auto;
}

label {
  display: block;
  height: 10px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 8px;
}

.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  flex: 1 1 auto;
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}
.icon-container img {
  width: 20px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px lightgray;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}
.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}

</style>
