<template>
  <section class="mb-10">
  <div  v-for="(meta, i) in upsell.metadata" :key="i">
    <UpsellBannerCongrats :metadata="meta" />
      <v-container class="container__metadata mb-3">
        <v-row class="row__metadata align-self-end">
          <v-col cols="12" md="6" class="d-flex justify-center align-center col-img">
            <img v-if="meta.img"
                 loading="lazy"
                 :src="meta.img.src"
                 :alt="meta.img.alt"
                 class="product-img"
            />

          </v-col>
          <v-col cols="12" md="6" class="col_meta">
            <div class="meta__title " v-if="meta.title" v-html="meta.title"/>
              <p class="description__message" v-if="meta.description" v-html="meta.description"/>
          </v-col>
        </v-row>
      </v-container>
    <UpsellBannerWarning v-if="meta.bannerWarning" :message="meta.bannerWarning"/>
    <UpsellMessageBox v-if="meta.messageBox" :message="meta.messageBox"/>
    <UpsellComparative v-if="upsell.initialPrice && upsell.remisedPrice && meta.textComparativeLeft && meta.textComparativeRight" :upsell="upsell" :metadata="meta"/>
    <UpsellButton v-if="meta.messageButton && meta.buttonAccept" :metadata="meta"/>
    <UpsellLastMessage v-if="upsell.finalMessage || meta.textRed" :metadata="meta"/>

  </div>
  </section>
</template>

<script>
import UpsellBannerCongrats from '@/components/Upsell/UpsellBannerCongrats.vue'
import UpsellBannerWarning from '@/components/Upsell/UpsellBannerWarning.vue'
import UpsellMessageBox from '@/components/Upsell/UpsellMessageBox.vue'
import UpsellComparative from '@/components/Upsell/UpsellComparative.vue'
import UpsellButton from '@/components/Upsell/UpsellButton.vue'
import UpsellLastMessage from '@/components/Upsell/UpsellLastMessage.vue'

export default {
  components: { UpsellBannerCongrats, UpsellBannerWarning, UpsellMessageBox, UpsellComparative, UpsellButton, UpsellLastMessage },
  props: {
    upsell: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

.container__metadata {
  @media (min-width: 1904px) {
    max-width: 1300px;
  }
}
.row__metadata {
  @media (max-width: 800px) {
    margin-left: -14px !important;
  }
}
.product-img {
  display: block;
  max-width: 100%;
  object-fit: contain;
  width: 300px;
  margin-right: 20px;
  @media (max-width: 800px) {
    margin-right: 0px;
  }
}
.meta__title {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 42px;
  color: rgba(11, 158, 11, 1);
  font-family: "open-sans", sans-serif;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  line-height: 1;
  margin-bottom: 15px;

  @media (max-width: 800px) {
    margin-bottom: 0px;
    font-size: 25px;
    line-height: 27px;
  }
}
.col_meta {
  @media (min-width: 1904px){
    padding-top: 13px !important;
    padding: 10px;
  }
  @media (max-width: 800px) {
  padding: 0px!important;
    padding-bottom: 5px!important;
  }
}
.description__message {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: normal;
  @media (max-width: 800px) {
    font-size: 18px;
  }
}

.col-img {
  padding: 0!important;
  padding-top: 4px!important;
}
</style>
