var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"textField",attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.firstNameLabel'),"filled":"","value":_vm.$store.state.Order.contact.first_name,"rules":[
          _vm.required
        ]},on:{"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'first_name',
            value: $event,
          })}}}),_c('v-text-field',{staticClass:"textField",attrs:{"reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.lastNameLabel'),"value":_vm.$store.state.Order.contact.last_name,"rules":[
          _vm.required
        ],"dense":"","filled":""},on:{"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'last_name',
            value: $event,
          })}}}),_c('v-text-field',{staticClass:"textField",attrs:{"type":"email","reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.emailLabel'),"value":_vm.$store.state.Order.contact.email,"rules":[
          _vm.required,
          _vm.isEmail
        ],"dense":"","filled":""},on:{"blur":_vm.SendEmail,"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'email',
            value: $event,
          })}}}),_c('v-text-field',{staticClass:"textField",attrs:{"type":"phone","reverse":this.$i18n.locale === 'il',"label":_vm.$t('landingPage.phoneLabel'),"value":_vm.$store.state.Order.contact.phone,"rules":[
          _vm.required,
          _vm.minPhoneNumber,
          _vm.maxPhoneNumber,
          _vm.onlyDigit
        ],"dense":"","filled":""},on:{"blur":_vm.SendPhone,"input":function($event){return _vm.$store.dispatch('SET_CONTACT_BY_KEY', {
            key: 'phone',
            value: $event,
          })}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }