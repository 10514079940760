<template>
  <v-card elevation="0">
    <v-card-text>
      <v-row class="form-right__header">
        <v-col cols="6">
          <p class="form-right__header__name">
            {{ $store.state.Product.find(p => p.routerName === this.$route.name).name }}
          </p>
          <p class="form-right__header__free-shipping">{{$t('landingPage.freeDelivery').toUpperCase()}}</p>
        </v-col>
        <v-col cols="6">
          <img v-if="$store.state.Product.find(p => p.routerName === this.$route.name).secondImg"
            :src="$store.state.Product.find(p => p.routerName === this.$route.name).secondImg.src"
            :alt="$store.state.Product.find(p => p.routerName === this.$route.name).secondImg.alt"
            width="134px"
            class="form-right__header__img"
          />
        </v-col>
      </v-row>

      <FormAddress />
      <FormCheckout :key="$store.state.Order.address.country" v-if="$store.state.Product.find(p => p.routerName === this.$route.name).checkout"/>
      <FormStripe  v-if="$store.state.Product.find(p => p.routerName === this.$route.name).stripe || $store.state.Product.find(p => p.routerName === this.$route.name).stripe_2"/>
      <label for="input-warranty" class="form-right__warranty__label">
      <HighlightedCard
        class="mb-5"
        use-bestseller-img
        :bestseller-img-position="$i18n.locale === 'il' ? 'left' : 'right'">
          <v-row align="center" justify="center">
            <v-col cols="auto" style="line-height: 1  ">
              <input
                type="checkbox"
                id="input-warranty"
                @change="$store.dispatch('SET_WARRANTY', $event.target.checked)"
                :checked="$store.state.Order.warranty"
              />
            </v-col>
            <v-col class="pl-0">
              {{ $store.state.Product.find(p => p.routerName === this.$route.name).warranty.name }}: {{ $store.state.Product.find(p => p.routerName === this.$route.name).warranty.label_price }}
            </v-col>
          </v-row>
      </HighlightedCard>
      </label>
      <v-btn
        type="submit"
        color="#1fba34"
        elevation="0"
        class="form-right__checkout-button"
        dark
        block
        >
        {{$t('landingPage.yesSend')}}
        <span class="no-text-transform">{{ $store.state.Product.find(p => p.routerName === this.$route.name).name }} </span>
        {{$t('landingPage.now')}}

      </v-btn>
      <p class="form-right__purchases">
        {{$t('landingPage.yourPurchasesWillAppearAs')}}
        <span class="font-weight-bold"> {{$t('landingPage.domainName', {applicationName})}} </span>
        {{$t('landingPage.onYourCardStatement')}}
      </p>
      <img
        src="@/assets/safe_checkout_logo.png"
        alt="security logos"
        class="form-right__secure-img"
      />

      <p class="form-right__secure-msg">
        <i class="icon-lock mr-2"></i>
        {{$t('landingPage.transactionsEncrypted')}}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import FormAddress from '@/components/Checkout/FormElements/FormAddress.vue'
import FormCheckout from '@/components/Checkout/FormElements/FormCheckout.vue'
import FormStripe from '@/components/Stripe/formStripe.vue'
import HighlightedCard from '@/components/Checkout/elements/HighlightedCard.vue'

export default {
  components: {
    FormAddress,
    HighlightedCard,
    FormCheckout,
    FormStripe,
  },
  data () {
    return {
      applicationName: process.env.VUE_APP_APPLICATION_NAME
    }
  }
}
</script>

<style lang="scss">
.form-right {
  &__header {
    &__img {
      display: block;
      margin-left: auto;
    }

    &__name {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 4px !important;

      @include media("md-and-down") {
        font-size: 22px;
      }
    }

    &__free-shipping {
      font-size: 14px;
      text-transform: uppercase;

      @include media("md-and-down") {
        font-size: 14px;
      }
    }
  }

  &__purchases {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 16px;
    text-align: center;
  }

  &__checkout-button {
    font-size: 16px !important;
    font-weight: normal !important;
    height: auto !important;
    display: block !important;
    white-space: initial !important;
    padding: 16px !important;
    min-width: 100% !important;

    .v-btn__content {
      flex: unset;
      display: inline;
    }
  }

  &__secure-img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  &__secure-msg {
    font-size: 16px;
    text-align: center;
    margin-top: 0.625em;
  }

  &__warranty {
    &__label {
      font-size: 12px;
      font-weight: bold;
      cursor: pointer;
    }
  }

}

.icon-lock {
  vertical-align: baseline;
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url("@/assets/locksm.png");
}
</style>
