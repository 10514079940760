<template>
  <section class="form">
    <SectionProduct :active="true" :product="product"/>
    <v-container class="form__container">
      <v-form
        @submit.prevent="onSubmit"
        ref="form"
        id="submit"
        lazy-validation
      >
        <v-row class="no-gutters" style="width: 100%">
          <v-col cols="12" md="7" class="pr-0">
          <FormSectionLeft />
          </v-col>
          <v-col cols="12" md="5" class="colFormSectionRight">
            <FormSectionRight />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-snackbar
      v-if="isCardValid"
      :timeout="4000"
      v-model="isCardValid"
      bottom
      color="red"
      outlined
      right
    >
      {{$t('validation.isCardValid')}}
    </v-snackbar>
  </section>
</template>

<script>
import SectionProduct from '@/components/CheckoutOrder/product/SectionProduct.vue'
import FormSectionLeft from '@/components/CheckoutOrder/FormSectionColumns/FormSectionLeft.vue'
import FormSectionRight from '@/components/CheckoutOrder/FormSectionColumns/FormSectionRight.vue'

import * as Sentry from '@sentry/browser'
import Brevo from '@/utils/Brevo'

export default {
  props: {
    product: {
      type: Object
    }
  },
  components: {
    FormSectionRight,
    FormSectionLeft,
    SectionProduct
  },
  data () {
    return {
      isCardValid: false
    }
  },
  methods: {
    async paymentByApplePay () {
      try {
        // Define PaymentMethodData
        const paymentMethodData = [{
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
            version: 3,
            merchantIdentifier: process.env.VUE_APP_MERCHANT_IDENTIFIER,
            merchantCapabilities: [
              'supports3DS'
            ],
            supportedNetworks: [
              'amex',
              'discover',
              'masterCard',
              'interac',
              'visa',
              'privateLabel'
            ],
            countryCode: this.$store.state.Order.address.country
          }
        }]
        // Define PaymentDetails
        const paymentDetails = {
          total: {
            label: process.env.VUE_APP_APPLICATION_NAME.toString().toLowerCase(),
            amount: {
              value: this.$store.getters.getAmount / 100,
              currency: this.$store.getters.getArticle.currency
            }
          }
        }
        // Define PaymentOptions
        const paymentOptions = {
          requestPayerName: false,
          requestBillingAddress: false,
          requestPayerEmail: false,
          requestPayerPhone: false,
          requestShipping: false
        }

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions)

        request.onmerchantvalidation = async event => {
          // Call your own server to request a new merchant session.
          // eslint-disable-next-line no-undef
          const merchantSessionPromise = await this.$store.dispatch('GET_APPLE_PAY_SESSION')
          event.complete(merchantSessionPromise.data)
        }

        request.onpaymentmethodchange = event => {
          if (event.methodDetails.type !== undefined) {
            // Define PaymentDetailsUpdate based on the selected payment method.
            // No updates or errors needed, pass an object with the same total.
            const paymentDetailsUpdate = {
              total: paymentDetails.total
            }
            event.updateWith(paymentDetailsUpdate)
          } else if (event.methodDetails.couponCode !== undefined) {
            // Define PaymentDetailsUpdate based on the coupon code.
            // eslint-disable-next-line no-undef
            const total = calculateTotal(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const displayItems = calculateDisplayItem(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const shippingOptions = calculateShippingOptions(event.methodDetails.couponCode)
            // eslint-disable-next-line no-undef
            const error = calculateError(event.methodDetails.couponCode)

            event.updateWith({
              total: total,
              displayItems: displayItems,
              shippingOptions: shippingOptions,
              modifiers: [
                {
                  data: {
                    additionalShippingMethods: shippingOptions
                  }
                }
              ],
              error: error
            })
          }
        }

        const response = await request.show()
        const status = 'success'
        await response.complete(status)
        const responseCheckoutToken = await this.$store.dispatch('POST_TOKEN_APPLE_PAY', response.details.token.paymentData)
        this.$store.commit('SET_TOKEN', responseCheckoutToken.data.token)
        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'CheckoutOrder' })
        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: responseCheckout.data.id, data: this.$store.state.Order })
          const response = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
          const responseOrder = await this.$store.dispatch('GET_ORDER', response.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: response.source.id })
        }
        if (responseCheckout.data.approved) {
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        }
      } catch (e) {
        Sentry.captureMessage('Error payment Apple Pay')
        // Handle errors
      }
    },
    async onSubmit () {
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        return
      }
      this.$emit('loading', true)

      if (this.$store.state.Order.payment_method === 'applePay') {
        await this.paymentByApplePay()
      }

      if (this.$store.state.Order.payment_method === 'card') {
        if (!Frames.isCardValid()) {
          this.isCardValid = true
          return
        }
        try {
          /* global Frames */
          await Frames.submitCard()
        } catch (e) {
          Sentry.captureMessage('Frames SubmitCard')
        }

        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'checkout' })

        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', {
            id: responseCheckout.data.id,
            data: this.$store.state.Order
          })
        }
        if (responseCheckout.data.status === 'Pending' && responseCheckout.data['3ds'].upgrade_reason === 'sca_retry') {
          window.location.href = responseCheckout.data._links.redirect.href
        }

        if (responseCheckout.data.id && responseCheckout.data.approved && responseCheckout.data.status === 'Authorized') {
          try {
            const resultCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
            const responseOrder = await this.$store.dispatch('GET_ORDER', resultCheckout.id)
            this.$store.commit('SET_ORDER', {
              ...responseOrder.data[0].data[0],
              session_id: responseOrder.data[0].id_order,
              token_card: responseCheckout.data.source.id
            })
            const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
            if (!commandOverseeExist.length) {
              await this.$store.dispatch('SEND_COMMAND_OVERSEE')
              new Brevo('order_completed', this.$store, this.$route).track()
            }
            if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
            if (responseCheckout.data.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
            this.$store.commit('SET_FINISH', false)
            this.$emit('loading', false)
            return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
          } catch (e) {
            console.log(e)
            Sentry.withScope(scope => {
              scope.setExtra('name', 'CHECKOUT')
              scope.setExtra('state', this.$store.state.Order)
              scope.setExtra('response', JSON.stringify(e.response.data))
              Sentry.captureException(e)
            })
            await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
            this.$emit('loading', false)
            return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
          }
        }

        if (responseCheckout.data._links.redirect.href) {
          window.location.href = responseCheckout.data._links.redirect.href
        } else {
          Sentry.captureMessage('No links redirect after CHECKOUT_PAYMENTS_BY_CARD')
        }
      }
      if (this.$store.state.Order.payment_method === 'sofort') {
        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_SOFORT', { productName: this.$route.name, template: 'checkout' })
        if (responseCheckout.data._links.redirect.href) {
          window.location.href = responseCheckout.data._links.redirect.href
        } else {
          Sentry.captureMessage('No links redirect after CHECKOUT_PAYMENTS_BY_SOFORT')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.form {
  background: #f7f7f7;

  &__container {
    max-width: 970px;
    padding: 15px!important;
  }
}

.colFormSectionRight {
  padding-left: 16px !important;
  @media screen and (max-width: 959px) {
    margin-top: 15px;
    padding-left: 0px!important;
  }
}
</style>
