<template>
  <v-card elevation="0" class="card__order">
    <div class="card__title d-flex" >
      <img class="card__title__icon" height="20px" width="20px"   src="@/assets/CheckoutOrder/order.svg" alt="order"/>
      <span class="text-uppercase">{{$t('landingPage2.orderSummary')}}</span>
      <v-spacer/>
      <div class="price">{{$t('landingPage2.price')}}</div>
    </div>
    <div class="card__summary">
    <div class="summary__text d-flex flex-row align-items-center justify-content-between " style="padding: 5px 0px">
      <div>{{$store.state.Order.article}}</div>
      <v-spacer/>
      <div>{{getAmount}} €</div>
    </div>

    <div class="summary__text d-flex flex-row align-items-center justify-content-between" style="padding: 2.5px 0px 5px">
      <div>{{$t('landingPage2.shipping')}}:</div>
      <v-spacer/>
      <div class="summary__text__free text-uppercase">{{$t('landingPage2.free')}}</div>
    </div>

    <div v-if="$store.state.Order.warranty" class="summary__text d-flex flex-row align-items-center justify-content-between" style="padding: 0px 0px 5px">
      <div>{{ $t('landingPage2.warranty') }}:</div>
      <v-spacer/>
      <div>{{getSelectedWarranty}} €</div>
    </div>
    </div>
    <div class="summary__text font-weight-bold d-flex flex-row align-items-center justify-content-between pt-1 pb-2">
      <div>{{$t('landingPage2.todayTotal')}}:</div>
      <v-spacer/>
      <div class="summary__text font-weight-bold">{{ getTotal }} €</div>
    </div>

  </v-card>
</template>

<script>
import { formatPrice } from '@/utils/utils'
export default {
  data () {
    return {
    }
  },
  computed: {
    getSelectedArticle () {
      return this.$store.getters.getSelectedArticle
    },
    getAmount () {
      return formatPrice(this.getSelectedArticle[0].price / 100)
    },
    getSelectedWarranty () {
      const warranty = this.$store.getters.getSelectedWarranty[0].price / 100 * this.$store.getters.getSelectedArticle[0].quantity
      return warranty ? formatPrice(warranty) : 0
    },
    getTotal () {
      const getAmount = this.getSelectedArticle[0].price / 100
      if (this.$store.state.Order.warranty) {
        const warranty = this.$store.getters.getSelectedWarranty[0].price / 100 * this.$store.getters.getSelectedArticle[0].quantity
        return formatPrice(getAmount + warranty)
      }
      return formatPrice(getAmount)
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.card {
  &__order {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;

    &__icon {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  &__summary {
    border-bottom: 1px solid #ddd;
    border-radius: 0 !important;
  }
}
.price {
  font-weight: 700!important;
  line-height: 1.467;
  color: #333;
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
}

.summary__text {
  font-family: Work Sans,sans-serif!important;
  font-size: 14px!important;
  line-height: 1.467;
  color: #333;

  &__free {
    font-weight: 700;
    color: green;
  }
}
</style>
