<template>
  <section class="section__comparative">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" class="d-flex col__left justify-center">
          <v-spacer class="d-none d-md-block" />
          <div>
           <div class="textComparativeLeft" v-html="metadata.textComparativeLeft"/>
           <div class="initialPrice">{{upsell.initialPrice}}</div>
          </div>
       </v-col>
        <v-col cols="12" md="6" class="d-flex col__right justify-center">
          <div>
            <div class="textComparativeRight" v-html="metadata.textComparativeRight"/>
            <div class="remisedPrice" >{{upsell.remisedPrice}}</div>
          </div>
          <v-spacer class="d-none d-md-block" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    upsell: {
      type: Object,
      required: false,
    },
    metadata: {
      type: Object,
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
.col__left {
    padding-right: 80px!important;
  }
.col__right {
    padding-left:  80px!important;
}
.remisedPrice {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  text-align: center;
}

.textComparativeRight {
  word-break: break-word;
  transition: box-shadow 0.1s ease-in-out 0s;
  font-size: 25px;
  color: rgba(11, 158, 11, 1);
  font-family: "open-sans", sans-serif;
  text-align: center;
}

.textComparativeLeft {
  word-break: break-word;
  font-size: 25px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  text-align: center;
}
.initialPrice {
  word-break: break-word;
  font-size: 25px;
  color: rgb(0, 0, 0);
  font-family: "open-sans", sans-serif;
  text-align: center;
  font-weight: bold;
  text-decoration: line-through;
}

@media (max-width: 960px) {
  .col__left {
    padding-right: 12px!important;
  }
  .col__right {
    padding-left: 12px!important;
  }
}
</style>
