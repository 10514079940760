<template>
    <div class="shipping mb-6">
      <v-col cols="12">
        <v-radio-group v-model="$store.state.Order.payment_method">
          <v-row justify="center">
            <div class="methodCard" @click="choicePaymentMethod('stripeCard')">
              <v-col
                type="radio"
                cols="6"
                :class="{
                  'creditLabel__isActive creditLabel d-flex':
                    $store.state.Order.payment_method === 'stripeCard',
                  'creditLabel creditLabel__noActive d-flex':
                    $store.state.Order.payment_method !== 'stripeCard'
                }"
                :style="
                  $i18n.locale === 'il'
                    ? {
                        'border-top-right-radius': '4px',
                        'border-right': 'solid 1px #c2c2c2',
                        'border-top': 'solid 1px #c2c2c2',
                        'border-top-left-radius': '0px',
                        borderLeft: '0'
                      }
                    : {}
                "
              >
                <v-radio
                  :class="$store.state.Order.payment_method === 'stripeCard' ? 'labelCreditCard__isActive' :'labelCreditCard'"
                  :label="$t('landingPage.creditCard')"
                  value="stripeCard"
                ></v-radio>
              </v-col>
              <v-col
                cols="6"
                :class="{
                  'creditLogo__isActive d-flex':
                    $store.state.Order.payment_method === 'stripeCard',
                  'creditLogo creditLogo__noActive d-flex':
                    $store.state.Order.payment_method !== 'stripeCard'
                }"
                :style="
                  $i18n.locale === 'il'
                    ? {
                        'border-top-left-radius': '4px',
                        borderLeft: 'solid 1px #c2c2c2',
                        'border-top': 'solid 1px #c2c2c2',
                        'border-top-right-radius': '0px',
                        borderRight: '0'
                      }
                    : {}
                "
              >
                <v-img
                  src="@/assets/creditCards.png"
                  alt="Carte de crédit"
                />
              </v-col>
            </div>
            <v-col
              cols="12"
              :class="$store.state.Order.payment_method === 'stripeCard' ? 'cardCredit__isActive' : 'cardCredit'"
              v-show="$store.state.Order.payment_method === 'stripeCard'"
            >
              <label for="card-number">{{$t('landingPage.cardNumber')}}</label>
              <div class="checkout input-container card-number" id="card-input"
              >
                <div class="icon-container">
                  <img
                    style="max-width: 100%"
                    id="icon-card-number"
                    src="@/assets/card.svg"
                    alt="PAN"
                  />
                </div>
                <div
                  id="card-number"
                  class="card-number-frame pt-3"
                ></div>
              </div>
              <v-row class="pb-5">
                <v-col cols="6">
                  <label for="card-number"
                  >{{$t('landingPage.expirationDate')}}</label
                  >
                  <div class="checkout" id="expiry-input">
                    <div
                      id="card-expiry"
                      class=" pa-3 expiry-date-frame  pt-4"
                    >
                      <!-- date d'expiration sera ajoutée ici -->
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <label for="card-number"
                  >{{$t('landingPage.securityCode')}}</label
                  >

                  <div class="checkout" id="cvc-input">

                  <div
                    id="card-cvc"
                    class="pa-3 cvv-frame  pt-4"
                  >
                    <!-- cadre cvv sera ajouté ici -->
                  </div>
                  </div>
                </v-col>
                <div id="card-errors" class="pl-3 text-sm-subtitle-2 red--text"></div>
              </v-row>

            </v-col>
            <PaypalCheckout v-if="$store.state.Product.find(p => p.routerName === this.$route.name).paypal"/>
          </v-row>

        </v-radio-group>
      </v-col>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import PaypalCheckout from '@/components/Paypal/PaypalCheckout.vue'
import Brevo from '@/utils/Brevo'

export default {
  components: { PaypalCheckout },
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      clientSecret: null,
      stripe: null,
      elements: null
    }
  },
  async mounted () {
    await this.$store.dispatch('SET_PAYMENT_METHOD', 'stripeCard')
    const apiKeyStripe = this.$store.state.Product.find(p => p.routerName === this.$route.name).stripe
      ? process.env.VUE_APP_STRIPE
      : process.env.VUE_APP_STRIPE2
    const stripePromise = loadStripe(apiKeyStripe, { locale: this.$i18n.locale })
    this.stripe = await stripePromise
    this.elements = this.stripe.elements()
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#FF0000',
        iconColor: '#FF0000',
      },
    }
    this.cardNumber = this.elements.create('cardNumber', { style })
    this.cardNumber.mount('#card-number')
    this.cardExpiry = this.elements.create('cardExpiry', { style })
    this.cardExpiry.mount('#card-expiry')
    this.cardCvc = this.elements.create('cardCvc', { style })
    this.cardCvc.mount('#card-cvc')

    const card = document.querySelector('#card-input')
    const expiry = document.querySelector('#expiry-input')
    const cvc = document.querySelector('#cvc-input')

    this.cardNumber.addEventListener('focus', () => {
      card.style.setProperty('border', 'solid 1px #13395e')
      card.style.setProperty('box-shadow', '0 2px 5px 0 rgba(19, 57, 94, 0.15)')
    })
    this.cardNumber.addEventListener('blur', () => {
      card.style.removeProperty('border-color')
      card.style.removeProperty('box-shadow-color')
    })

    this.cardExpiry.addEventListener('focus', () => {
      expiry.style.setProperty('border', 'solid 1px #13395e')
      expiry.style.setProperty('box-shadow', '0 2px 5px 0 rgba(19, 57, 94, 0.15)')
    })
    this.cardExpiry.addEventListener('blur', () => {
      expiry.style.removeProperty('border-color')
      expiry.style.removeProperty('box-shadow-color')
    })

    this.cardCvc.addEventListener('focus', () => {
      cvc.style.setProperty('border', 'solid 1px #13395e')
      cvc.style.setProperty('box-shadow', '0 2px 5px 0 rgba(19, 57, 94, 0.15)')
    })
    this.cardCvc.addEventListener('blur', () => {
      cvc.style.removeProperty('border-color')
      cvc.style.removeProperty('box-shadow-color')
    })

    const cardErrorsElement = document.getElementById('card-errors')

    this.cardNumber.addEventListener('change', (event) => {
      if (event.error) {
        cardErrorsElement.textContent = event.error.message
      } else {
        cardErrorsElement.textContent = ''
      }
    })

    this.cardExpiry.addEventListener('change', (event) => {
      if (event.error) {
        cardErrorsElement.textContent = event.error.message
      } else {
        cardErrorsElement.textContent = ''
      }
    })

    this.cardCvc.addEventListener('change', (event) => {
      if (event.error) {
        cardErrorsElement.textContent = event.error.message
      } else {
        cardErrorsElement.textContent = ''
      }
    })
    this.$root.$on('stripeCardCheckout', async () => {
      await this.confirmCardPayment()
    })
  },
  beforeDestroy () {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  methods: {

    async confirmCardPayment () {
      try {
        console.log('routne .name', this.$route.name)
        const responseCheckout = await this.$store.dispatch('CHECKOUT_STRIPE', this.$route.name)
        const { paymentIntent } = await this.stripe.confirmCardPayment(responseCheckout.client_secret, {
          payment_method: {
            card: this.cardNumber
          },
        })
        if (paymentIntent?.status === 'succeeded') {
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        }
        return 'OK'
      } catch (e) {
        console.log('error ', e)
        return 'OK'
      }
    },

  }
}
</script>
<style lang="scss" scoped>
.googlePay {
  margin-right: 30px;
  height: 25px;
  margin-top: 10px;
  width: 50px;
}
.applePay {
  margin-right: 30px;
  height: 50px;
  margin-top:   0px;
  width: 50px;
}
.size{
  height: 50px;
}
.textSofort {
  font-size: 14px;
  line-height: 1.43;
  margin: 0;
  padding: 0 40px 10px 43px;
  &:hover {
    cursor: pointer;
  }
}

.labelCreditCard{
  &__isActive {
    font-size: 16px;
    font-weight: 500;
    transition: text-decoration-color 300ms;
  }
}
.one-liner {
  width: 100%;
}
.methodCard {
  display: flex;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
}

.creditLabel {
  border-left:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
  border-top-left-radius: 4px ;
  &__isActive {
    border-top-left-radius: 4px ;
    border-left: solid 2px black;
    border-top: solid 2px black;
  }
  &__base {
    border-top-left-radius: 4px ;
    border-left: solid 1px #c2c2c2;
    border-top: solid 1px #c2c2c2;
  }
  &__noActive {
    border-bottom:solid 1px #c2c2c2; ;
  }
}
.creditLogo {
  border-top-right-radius: 4px ;
  border-right:solid 1px #c2c2c2;
  border-top: solid 1px #c2c2c2;
  &__isActive {
    border-top-right-radius: 4px ;
    border-right: solid 2px black;
    border-top: solid 2px black;
  }
  &__base {
    border-top-right-radius: 4px ;
    border-right: solid 1px #c2c2c2;
    border-top: solid 1px #c2c2c2;
  }
  &__noActive {
    border-bottom:solid 1px #c2c2c2; ;
  }
}

.cardCredit {
  width: 100%;
  display: flex;
  border-top: solid 1px #c2c2c2;
  &__isActive{
    border: solid 2px black;
  }
  &__base {
    border: solid 1px #c2c2c2;
  }
}
.checkout {
  height: 100px;
}
.credit {
  border: solid 1px #c2c2c2;
  border-radius: 4px;
  &__isActive {
    border-bottom: none;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-top: solid 2px black;
  }
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: gray;
}

.checkout {
  height: 50px;
  opacity: 1;
  border: solid 1px lightgray;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
  font-size: 15px;
  color: #9b9b9b;
  font-family: Work Sans,sans-serif!important;
}

.checkoutFocus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  padding: 1rem;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

iframe {
  /* This fixes a mobile Safari bug */
  height: 38px !important;
}

#payment-form {
  width: 280px;
  margin: 0 auto;
}

label {
  display: block;
  height: 10px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  line-height: 10px;
  margin: 8px 0;
}

.date-and-code {
  display: flex;
  margin-bottom: 8px;
}

.date-and-code > div:nth-child(1) {
  width: 55.715%;
}
.date-and-code > div:nth-child(2) {
  width: 45.719%;
}

.input-container {
  position: relative;
  display: flex;
  height: 40px;
}
.icon-container:last-child {
  right: 0;
}
.icon-container.payment-method {
  right: 0;
}

.input-container.card-number {
  margin-bottom: 8px;
}
.input-container.expiry-date {
  margin-right: 8px;
}

.card-number-frame,
.expiry-date-frame,
.cvv-frame {
  flex: 1 1 auto;
  padding-left: 40px;
}

div + button {
  margin-top: 8px;
}

.icon-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 26px;
  margin: 0 7px;
}
.icon-container img {
  width: 20px;
}

.icon-container.payment-method {
  transform: translateY(-50%) rotateY(90deg);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  top: 50%;
}

.icon-container.payment-method.show {
  opacity: 1;
  transition: all 0.4s ease-out;
  transform: translateY(-50%) rotateY(0deg);
}

.icon-container.payment-method img {
  width: 100%;
}

[id$="-error"] {
  display: none;
}

.frame {
  opacity: 0;
}

.frame--activated {
  opacity: 1;
  border: solid 1px lightgray;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.2);
}

.frame--activated.frame--focus {
  border: solid 1px #13395e;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
}

.frame--activated.frame--invalid {
  border: solid 1px #d96830;
  box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
}

.error-message {
  display: block;
  color: #c9501c;
  font-size: 0.9rem;
  margin: 8px 0 0 1px;
  font-weight: 300;
}

#pay-button {
  border: none;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  height: 40px;
  width: 100%;
  background-color: #13395e;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:active {
  background-color: #0b2a49;
  box-shadow: 0 1px 3px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:hover {
  background-color: #15406b;
  box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.4);
}

#pay-button:disabled {
  background-color: #697887;
  box-shadow: none;
}

#pay-button:not(:disabled) {
  cursor: pointer;
}

.success-payment-message {
  color: #13395e;
  line-height: 1.4;
}
.token {
  color: #b35e14;
  font-size: 0.9rem;
  font-family: monospace;
}

/**
IE11-targeted optimisations
 */
_:-ms-fullscreen,
:root .icon-container {
  display: block;
}

_:-ms-fullscreen,
:root .icon-container img {
  top: 50%;
  -ms-transform: translateY(-50%);
  position: absolute;
}

_:-ms-fullscreen,
#icon-card-number,
_:-ms-fullscreen,
#icon-expiry-date,
_:-ms-fullscreen,
#icon-cvv {
  left: 7px;
}

#checkout-frames-card-number::-ms-clear {
  display: none;
}

</style>

<style scoped>

#card-error {
  color: red;
}
</style>
