<template>
  <div :id="id" />
</template>

<script>
import props from './propsGooglePay'
import * as Sentry from '@sentry/browser'

export default {
  props: {
    id: {
      type: String,
      required: false,
      default: 'google-pay-button'
    },
    options: {
      type: Object,
      required: false,
      default: function () {
        return props
      }
    }
  },
  data () {
    return {
      ...props,
      cardPaymentMethod: null,
      paymentsClient: null
    }
  },
  async mounted () {
    const varsSet = await this.assignVars()
    if (varsSet) this.injectGooglePayScript()
  },
  methods: {
    async ProcessCheckout (response) {
      try {
        const responseCheckoutToken = await this.$store.dispatch('POST_TOKEN_GOOGLE_PAY', response)
        this.$store.commit('SET_TOKEN', responseCheckoutToken.data.token)
        const responseCheckout = await this.$store.dispatch('CHECKOUT_PAYMENTS_BY_CARD', { productName: this.$route.name, template: 'CheckoutOrder' })
        if (responseCheckout.data.id) {
          await this.$store.dispatch('SEND_CHECKOUT_ORDER', { id: responseCheckout.data.id, data: this.$store.state.Order })
          const response = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', responseCheckout.data.id)
          const responseOrder = await this.$store.dispatch('GET_ORDER', response.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: response.source.id })
        }
        if (responseCheckout.data._links.redirect.href) {
          window.location.href = responseCheckout.data._links.redirect.href
        } else {
          Sentry.captureMessage('No links redirect after Google Pay')
        }
      } catch (e) {
        Sentry.captureMessage('Error payment Google Pay')
      }
    },
    async assignVars () {
      Object.assign(this, this.options)
      return await this.$nextTick()
    },
    injectGooglePayScript () {
      if (!this.paymentsClient) {
        // load google pay script
        const googlePayScript = document.createElement('script')
        googlePayScript.setAttribute('src', 'https://pay.google.com/gp/p/js/pay.js')
        googlePayScript.setAttribute('async', true)
        googlePayScript.setAttribute('defer', true)
        googlePayScript.onload = () => this.onGooglePayLoaded()
        document.head.appendChild(googlePayScript)
      }
    },
    async initPaymentsVars () {
      this.cardPaymentMethod = Object.assign(
        {},
        this.baseCardPaymentMethod,
        {
          tokenizationSpecification: this.tokenizationSpecification
        }
      )
      return await this.$nextTick()
    },
    getGoogleIsReadyToPayRequest () {
      return Object.assign({}, this.baseRequest, {
        allowedPaymentMethods: [this.baseCardPaymentMethod]
      })
    },
    getGooglePaymentsClient () {
      if (this.paymentsClient === null) {
        // eslint-disable-next-line
        this.paymentsClient = new google.payments.api.PaymentsClient({
          environment: this.environment
        })
      }
      return this.paymentsClient
    },
    addGooglePayButton () {
      const button = this.paymentsClient.createButton({
        onClick: () => this.googlePayButtonClick(),
        buttonType: this.buttonType,
        buttonColor: this.buttonColor
      })
      document.getElementById(this.id).appendChild(button)
    },
    async onGooglePayLoaded () {
      const varsSet = await this.initPaymentsVars()
      if (varsSet) {
        const paymentsClient = this.getGooglePaymentsClient()
        paymentsClient
          .isReadyToPay(this.getGoogleIsReadyToPayRequest())
          .then(response => {
            if (response.result) {
              this.addGooglePayButton()
            }
          })
          .catch(err => {
            // show error in developer console for debugging
            console.error(err)
          })
      }
    },
    getGooglePaymentDataRequest () {
      const paymentDataRequest = Object.assign({}, this.baseRequest)

      paymentDataRequest.environment = process.env.VUE_APP_ENV === 'PRODUCTION' ? 'PRODUCTION' : 'TEST'
      paymentDataRequest.allowedPaymentMethods = [
        this.cardPaymentMethod
      ]
      paymentDataRequest.transactionInfo = this.transactionInfo

      paymentDataRequest.transactionInfo.totalPrice = (this.$store.getters.getAmount / 100).toString()
      paymentDataRequest.transactionInfo.currencyCode = this.$store.getters.getArticle.currency.toUpperCase()
      paymentDataRequest.transactionInfo.countryCode = this.$store.state.Order.address.country.toUpperCase()

      paymentDataRequest.merchantInfo = {
        merchantId: this.merchantInfo.merchantId,
        merchantName: this.merchantInfo.merchantName
      }
      return paymentDataRequest
    },
    googlePayButtonClick () {
      const isValid = this.$parent.$parent.$parent.$parent.$parent.$refs.form.validate()
      if (!isValid) {
        return
      }
      // eslint-disable-next-line no-unreachable
      const paymentDataRequest = this.getGooglePaymentDataRequest()
      paymentDataRequest.transactionInfo = this.transactionInfo

      const paymentsClient = this.getGooglePaymentsClient()
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(paymentData => {
          this.ProcessCheckout(JSON.parse(paymentData.paymentMethodData.tokenizationData.token))
        })
        .catch(err => {
          console.error(err)
          if (err.statusCode === 'CANCELED') {
            Sentry.captureMessage('Error payment Google Pay - googlePayButtonClick')
          }
        })
    }
  }
}
</script>
