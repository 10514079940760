import { product } from './productList'
const initFunnel = {
  shopify_id: 0,
  name: '',
  routerName: 'preview',
  locales: '',
  bestSelling: true,
  bannerImg: { src: '', alt: '' },
  mainImg: { src: '', alt: '' },
  secondImg: { src: '', alt: '' },
  thirdImg: { src: '', alt: '' },
  price: 0,
  title: '',
  initialPrice: '',
  remisedPrice: '',
  currency: '',
  caracteristics: [],
  payment_methods_bottom: false,
  upsell: [
    {
      name: '',
      routerName: '',
      bannerImg: { src: '', alt: '' },
      currency: '',
      label_price: '',
      price: 0,
      shopify_id: '',
      reloadDescription: '',
      alertMessage: '',
      addUpsell: '',
      initialPrice: '',
      remisedPrice: '',
      quantity: 1,
      metadata: [],
      subscribe: false
    }
  ],
  testimonials: [
    {
      src_img: '',
      username: '',
      country: '',
      text: ''
    }
  ],
  links: [
    {
      label: '',
      href: '',
      rel: 'nofollow',
      target: '_blank'
    }
  ],
  list: [
    {
      name: '',
      label_price: '',
      price: 0,
      quantity: 0,
      isBestSeller: false,
      isSelected: false,
      shopify_id: '',
      hide_checkoutOrder: false,
      hide_checkout: false,
      BestSeller_checkoutOrder: false,
      src_img: ''
    }
  ],
  variants: [
    {
      src: '',
      title: '',
      type: '',
      shopify_id: ''
    }
  ],
  warranty: {
    name: '',
    label_price: '',
    price: 0,
    shopify_id: '',
    multiply_by_product: false
  }
}

export default {
  state: () => ([...product,
    initFunnel
  ]),

  getters: {
    getUpsell: (state) => (routerName) => {
      return state.find(p => p.routerName === routerName).upsell
    },
    getRouterNameUpsellByProductRouterName: (state) => (routerName, index) => {
      const upsell = state.find(p => p.routerName === routerName).upsell[index]
      if (upsell?.abtesting) {
        const randomValue = Math.random()
        if (randomValue < 0.5) {
          return (upsell.abtesting.template === 2) ? `/upsell/${upsell.abtesting.routerName}` : `/inter/${upsell.abtesting.routerName}`
        }
      }
      return (upsell.template === 2) ? `/upsell/${upsell.routerName}` : `/inter/${upsell.routerName}`
    },
    getDownsell: (state) => (routerName, index) => {
      const currentUpsell = state.find(p => p.routerName === routerName).upsell[index - 1]
      const upsell = state.find(p => p.routerName === routerName).upsell[index]
      if (currentUpsell?.downsell) {
        return (currentUpsell.downsell.template === 2) ? `/upsell/${currentUpsell.downsell.routerName}` : `/inter/${currentUpsell.downsell.routerName}`
      }
      if (upsell?.abtesting) {
        const randomValue = Math.random()
        if (randomValue < 0.5) {
          return (upsell.abtesting.template === 2) ? `/upsell/${upsell.abtesting.routerName}` : `/inter/${upsell.abtesting.routerName}`
        }
      }
      return (upsell.template === 2) ? `/upsell/${upsell.routerName}` : `/inter/${upsell.routerName}`
    },
    getVariantsProduct: (state) => (routerName) => {
      return state.find(p => p.routerName === routerName).variants || []
    }
  },
  mutations: {
  },
  actions: {
  }
}
