<template>
  <div>
    <OverlayLoading :overlay="overlay" />
    <DiscountApplied :active="true"/>
    <SecureCheckout :active="true" :product="getSelectedProduct"/>
    <FormSection :product="getSelectedProduct" @loading="(callback) => overlay = callback"/>
<!--    <PopupViewer-->
<!--      :key="$store.state.Order.address.country"-->
<!--      :src="getImageSource()"-->
<!--      :alt="getMainImageAlt()"-->
<!--      :article="getProductName()"-->
<!--      :popup1="getPopupValue(1)"-->
<!--      :popup2="getPopupValue(2)"-->
<!--      :popup3="getPopupValue(3)"-->
<!--      :popup4="getPopupValue(4)"-->
<!--      :init-count="getPopupTimerValue()"-->
<!--    ></PopupViewer>  -->
  </div>
</template>

<script>

import * as Sentry from '@sentry/browser'
import FormSection from '@/components/CheckoutOrder/FormSection'
import DiscountApplied from '@/components/CheckoutOrder/headers/DiscountApplied'
import SecureCheckout from '@/components/CheckoutOrder/headers/SecureCheckout'
import OverlayLoading from '@/components/OverlayLoading'
import Brevo from '@/utils/Brevo'

export default {
  name: 'home-view2',

  components: {
    FormSection,
    DiscountApplied,
    SecureCheckout,
    OverlayLoading
  },
  methods: {
    getStatusUserIfFailed () {
      return {
        mail: this.$store.state.Order.contact.email,
        locale: this.$i18n.locale,
        name: this.$store.state.Order.contact.name,
        productImg: this.$store.state.Product.find(p => p.routerName === this.$route.name).mainImg.src,
        productName: this.$store.state.Product.find(p => p.routerName === this.$route.name).name,
        productUrl: process.env.VUE_APP_URL + this.$route.path.substring(1),
        logo: this.$store.state.Product.find(p => p.routerName === this.$route.name).bannerImg.src,
        productDescription: this.$store.state.Product.find(p => p.routerName === this.$route.name).caracteristics[0]
      }
    },
    getImageSource () {
      const product = this.getProduct()
      return product.list[0].src_img || product.secondImg.src
    },
    getMainImageAlt () {
      return this.getProduct().mainImg.alt
    },
    getProductName () {
      return this.getProduct().name
    },
    getPopupValue (index) {
      const popups = this.getProduct().popups
      return popups && popups[`popup${index}`] ? popups[`popup${index}`] : true
    },
    getPopupTimerValue () {
      const popups = this.getProduct().popups
      return popups ? popups.timer || 12 : 12
    },
    getProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    },
  },
  data () {
    return {
      overlay: false,
      loading: false,
      approved: null
    }
  },
  computed: {
    getSelectedProduct () {
      return this.$store.state.Product.find(p => p.routerName === this.$route.name)
    }
  },
  async mounted () {
    if (this.$route.query.click_id) {
      this.$store.commit('SET_CLICK_ID', this.$route.query.click_id)
    }
    if (this.$route.query.token) {
      this.overlay = true
      this.loading = true
      const responsePaypal = await this.$store.dispatch('GET_PAYMENTS_BY_PAYPAL', this.$route.query.token)
      if (responsePaypal.status === 'APPROVED') {
        const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
        this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], payment_type: 'paypal' })
        const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
        if (!commandOverseeExist.length) {
          await this.$store.dispatch('SEND_COMMAND_OVERSEE')
          new Brevo('order_completed', this.$store, this.$route).track()
        }
        if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
        this.loading = false
        this.approved = true
        this.$store.commit('SET_FINISH', false)
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
      } else {
        const responseOrder = await this.$store.dispatch('GET_ORDER', responsePaypal.id)
        this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
        await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    if (this.$route.query['cko-session-id']) {
      this.overlay = true
      this.loading = true
      try {
        const responseCheckout = await this.$store.dispatch('GET_PAYMENTS_BY_CHECKOUT', this.$route.query['cko-session-id'])
        if (responseCheckout.approved) {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0], session_id: responseOrder.data[0].id_order, token_card: responseCheckout.source.id })
          const { data: commandOverseeExist } = await this.$store.dispatch('COMMAND_OVERSEE_EXIST')
          if (!commandOverseeExist.length) {
            await this.$store.dispatch('SEND_COMMAND_OVERSEE')
            new Brevo('order_completed', this.$store, this.$route).track()
          }
          if (this.$store.state.Order.click_id && !commandOverseeExist.length) await this.$store.dispatch('SEND_TRACK')
          this.loading = false
          this.approved = true
          this.$store.commit('SET_FINISH', false)
          if (responseCheckout.source.type === 'sofort') return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/thank`)
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}` + this.$store.getters.getRouterNameUpsellByProductRouterName(this.$route.name, 0))
        } else {
          const responseOrder = await this.$store.dispatch('GET_ORDER', responseCheckout.id)
          this.$store.commit('SET_ORDER', { ...responseOrder.data[0].data[0] })
          await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
          return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('name', 'CHECKOUT')
          scope.setExtra('state', this.$store.state.Order)
          scope.setExtra('response', JSON.stringify(e.response.data))
          Sentry.captureException(e)
        })
        await this.$store.dispatch('SEND_MAIL_3D_SECURE_FAILED', this.getStatusUserIfFailed())
        return await this.$router.push('/' + this.$i18n.locale + `/${this.$route.name}/error/`)
      }
    }
    this.overlay = false
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>

.section__product {
  background: #f7f7f7;
}
.banner {
  &__img {
    margin: 0 auto;
  }
}

.main {
  background: #f1f4f8;
  padding-top: 18px;
  padding-bottom: 32px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
