import VueI18n from 'vue-i18n'
import { languages } from '../locales/index'
import Vue from 'vue'

Vue.use(VueI18n)
const messages = languages

const locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')

const i18n = new VueI18n({
  locale: locale || 'en',
  fallbackLocale: 'en',
  messages
})

export default i18n
