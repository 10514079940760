<template>
  <section class="section__button">
    <v-container class="container__button">
      <div class="buttonAcceptChecked" @click="sendRootEventAccept()">
        <div class="text-center">
          <v-icon
            color="black"
            :size="$vuetify.breakpoint.mdAndUp ? '36px' : '24px'"
            class="mr-3"
            :style="$vuetify.breakpoint.mdAndUp ? {'top': '-5px'} : {'top': '-2px'}"
          >
            mdi-checkbox-blank-outline
          </v-icon>
          <span v-html="metadata.messageButton" />
        </div>
        <div class="messageCheckOption text-center" v-html="metadata.messageCheckOption" />
      </div>
      <div class="text-center justify-center align-center" style="width: 90%; margin: auto">
        <div class="buttonAccept" @click="sendRootEventAccept()">
          <span v-html="metadata.messageButton" />
        </div>
      </div>

      <div class="text-center justify-center align-center" style="width: 90%; margin: auto">
        <div class="textAccept" @click="sendRootEventAccept()">
          <span v-html="metadata.messageButton" />
        </div>
      </div>

      <div class="text-center justify-center align-center" style="width: 90%; margin: auto">
        <div class="textRefused" @click="sendRootEventRefused()">
          <span v-html="metadata.messageRefused" />
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  props: {
    upsell: {
      type: Object,
      required: false,
    },
    metadata: {
      type: Object,
      required: false
    }
  },
  methods: {
    sendRootEventAccept () {
      this.$root.$emit('btn_accept')
    },
    sendRootEventRefused () {
      this.$root.$emit('btn_refused')
    }
  }
}
</script>

<style lang="scss" scoped>

.container__button {
  max-width: 960px;
}

.textRefused {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  align-self: center;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding-right: 65px;
  padding-bottom: 8px;
  padding-left: 78px;
  font-size: 18px;
  color: rgb(74, 74, 74);
  border-radius: 0px;
  border-style: none;
  box-shadow: none;
  line-height: 31px;
  @media (max-width: 800px) {
    width: 100%;
    align-self: center;
    padding: 0px;
    font-size: 14px;
    box-shadow: none;
    line-height: 18px;
  }
}
.textAccept {
  margin-top: 15px;
  width: 100%;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  background-color: rgb(255, 255, 255);
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 23px;
  color: rgb(33, 6, 243);
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  border-radius: 5px;
  border-style: none;
  box-shadow: none;
  line-height: 21px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
}

.messageCheckOption {
  font-size: 17px;
  color: rgb(74, 74, 74);
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  @media (max-width: 800px) {
    font-size: 16px;
  }
}
.buttonAccept {
  margin-top: 30px;
  width: 100%;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  background-color: rgb(251, 250, 2);
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 28px;
  color: rgb(33, 6, 243);
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  border-radius: 5px;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(33, 6, 243);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
  line-height: 26px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
}
.buttonAcceptChecked {
  padding: 15px;
  width: 100%;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  background-color: rgba(14, 179, 87, 0);
  font-size: 28px;
  color: rgb(0, 0, 0);
  font-family: "lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  border-radius: 5px;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px;
  line-height: 26px;
  @media (max-width: 800px) {
    font-size: 18px;
  }
}
</style>
