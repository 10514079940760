<template>
  <div class="shipping">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-text-field
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.streetLabel')"
          @input="
            $store.dispatch('SET_ADDRESS_BY_KEY', {
              key: 'street',
              value: $event,
            })
          "
          :value="$store.state.Order.address.street"
          :rules="[
            required
          ]"
          dense
          filled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-text-field
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.cityLabel')"
          @input="
            $store.dispatch('SET_ADDRESS_BY_KEY', {
              key: 'city',
              value: $event,
            })
          "
          :value="$store.state.Order.address.city"
          :rules="[
            required
          ]"
          dense
          filled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-autocomplete
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.countryLabel')"
          :items="countriesComputed"
          item-text="label"
          item-value="value"
          v-model="$store.state.Order.address.country"
          :search-input.sync="search"
          @change="
            $store.dispatch('SET_ADDRESS_BY_KEY', {
              key: 'country',
              value: $event,
            })
          "
          :value="$store.state.Order.address.country"
          :rules="[
            required
          ]"
          dense
          filled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-text-field
          class="textField"
          :reverse="this.$i18n.locale === 'il'"
          :label="$t('landingPage.postalCodeLabel')"
          @input="
            $store.dispatch('SET_ADDRESS_BY_KEY', {
              key: 'zip_code',
              value: $event,
            })
          "
          :value="$store.state.Order.address.zip_code"
          :rules="[
            required
          ]"
          dense
          filled
        />
      </v-col>
        <v-col cols="12" class="py-0"  v-if="$store.state.Order.address.country === 'BR'">
          <v-tooltip v-model="show" top close-delay="0">
            <template v-slot:activator="{  }">
              <v-text-field
                class="textField"

                :label="$t('landingPage.cpf')"
                :placeholder="$t('landingPage.cpfPlaceholder')"
                @input="
            $store.dispatch('SET_ADDRESS_BY_KEY', {
              key: 'vat',
              value: $event,
            })
          "
                :value="$store.state.Order.address.vat"
                :rules="[
            required
          ]"
                dense
                filled
                append-icon="mdi-help-circle-outline"
                @mouseover="show = !show"
                @mouseleave="show = !show"
              >
              </v-text-field>
            </template>
            <span>{{ $t('landingPage.cpf').toUpperCase() }}<br/>{{ $t('landingPage.cpfText') }}</span>
          </v-tooltip>

        </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from '@/utils/validation'
import data from '@/assets/data/currencyCode.json'

const isoCountriesLanguages = require('iso-countries-languages')

export default {
  data () {
    return {
      search: '',
      show: false,
      isLoading: false,
      countries: [
        { value: 'AF', label: 'Afghanistan' },
        { value: 'ZA', label: 'Afrique du Sud' },
        { value: 'AL', label: 'Albanie' },
        { value: 'DZ', label: 'Algérie' },
        { value: 'DE', label: 'Allemagne' },
        { value: 'AD', label: 'Andorre' },
        { value: 'AO', label: 'Angola' },
        { value: 'AI', label: 'Anguilla' },
        { value: 'AQ', label: 'Antarctique' },
        { value: 'AG', label: 'Antigua-et-Barbuda' },
        { value: 'SA', label: 'Arabie saoudite' },
        { value: 'AR', label: 'Argentine' },
        { value: 'AM', label: 'Arménie' },
        { value: 'AW', label: 'Aruba' },
        { value: 'AU', label: 'Australie' },
        { value: 'AT', label: 'Autriche' },
        { value: 'AZ', label: 'Azerbaïdjan' },
        { value: 'BS', label: 'Bahamas' },
        { value: 'BH', label: 'Bahreïn' },
        { value: 'BD', label: 'Bangladesh' },
        { value: 'BB', label: 'Barbade' },
        { value: 'BE', label: 'Belgique' },
        { value: 'BZ', label: 'Belize' },
        { value: 'BM', label: 'Bermudes' },
        { value: 'BT', label: 'Bhoutan' },
        { value: 'BY', label: 'Biélorussie' },
        { value: 'BO', label: 'Bolivie' },
        { value: 'BA', label: 'Bosnie-Herzégovine' },
        { value: 'BW', label: 'Botswana' },
        { value: 'BN', label: 'Brunéi Darussalam' },
        { value: 'BR', label: 'Brésil' },
        { value: 'BG', label: 'Bulgarie' },
        { value: 'BF', label: 'Burkina Faso' },
        { value: 'BI', label: 'Burundi' },
        { value: 'BJ', label: 'Bénin' },
        { value: 'KH', label: 'Cambodge' },
        { value: 'CM', label: 'Cameroun' },
        { value: 'CA', label: 'Canada' },
        { value: 'CV', label: 'Cap-Vert' },
        { value: 'CL', label: 'Chili' },
        { value: 'CN', label: 'Chine' },
        { value: 'CY', label: 'Chypre' },
        { value: 'CO', label: 'Colombie' },
        { value: 'KM', label: 'Comores' },
        { value: 'CG', label: 'Congo-Brazzaville' },
        { value: 'CD', label: 'Congo-Kinshasa' },
        { value: 'KP', label: 'Corée du Nord' },
        { value: 'KR', label: 'Corée du Sud' },
        { value: 'CR', label: 'Costa Rica' },
        { value: 'HR', label: 'Croatie' },
        { value: 'CU', label: 'Cuba' },
        { value: 'CW', label: 'Curaçao' },
        { value: 'CI', label: 'Côte d’Ivoire' },
        { value: 'DK', label: 'Danemark' },
        { value: 'DJ', label: 'Djibouti' },
        { value: 'DM', label: 'Dominique' },
        { value: 'ES', label: 'Espagne' },
        { value: 'EE', label: 'Estonie' },
        { value: 'SZ', label: 'Eswatini' },
        { value: 'FJ', label: 'Fidji' },
        { value: 'FI', label: 'Finlande' },
        { value: 'FR', label: 'France' },
        { value: 'GA', label: 'Gabon' },
        { value: 'GM', label: 'Gambie' },
        { value: 'GH', label: 'Ghana' },
        { value: 'GI', label: 'Gibraltar' },
        { value: 'GD', label: 'Grenade' },
        { value: 'GL', label: 'Groenland' },
        { value: 'GR', label: 'Grèce' },
        { value: 'GP', label: 'Guadeloupe' },
        { value: 'GU', label: 'Guam' },
        { value: 'GT', label: 'Guatemala' },
        { value: 'GG', label: 'Guernesey' },
        { value: 'GN', label: 'Guinée' },
        { value: 'GQ', label: 'Guinée équatoriale' },
        { value: 'GW', label: 'Guinée-Bissau' },
        { value: 'GY', label: 'Guyana' },
        { value: 'GF', label: 'Guyane française' },
        { value: 'GE', label: 'Géorgie' },
        { value: 'GS', label: 'Géorgie du Sud et îles Sandwich du Sud' },
        { value: 'HT', label: 'Haïti' },
        { value: 'HN', label: 'Honduras' },
        { value: 'HU', label: 'Hongrie' },
        { value: 'IN', label: 'Inde' },
        { value: 'ID', label: 'Indonésie' },
        { value: 'IQ', label: 'Irak' },
        { value: 'IR', label: 'Iran' },
        { value: 'IE', label: 'Irlande' },
        { value: 'IS', label: 'Islande' },
        { value: 'IL', label: 'Israël' },
        { value: 'IT', label: 'Italie' },
        { value: 'JM', label: 'Jamaïque' },
        { value: 'JP', label: 'Japon' },
        { value: 'JE', label: 'Jersey' },
        { value: 'JO', label: 'Jordanie' },
        { value: 'KZ', label: 'Kazakhstan' },
        { value: 'KE', label: 'Kenya' },
        { value: 'KG', label: 'Kirghizistan' },
        { value: 'KI', label: 'Kiribati' },
        { value: 'XK', label: 'Kosovo' },
        { value: 'KW', label: 'Koweït' },
        { value: 'RE', label: 'La Réunion' },
        { value: 'LA', label: 'Laos' },
        { value: 'LS', label: 'Lesotho' },
        { value: 'LV', label: 'Lettonie' },
        { value: 'LB', label: 'Liban' },
        { value: 'LY', label: 'Libye' },
        { value: 'LR', label: 'Libéria' },
        { value: 'LI', label: 'Liechtenstein' },
        { value: 'LT', label: 'Lituanie' },
        { value: 'LU', label: 'Luxembourg' },
        { value: 'MK', label: 'Macédoine du Nord' },
        { value: 'MG', label: 'Madagascar' },
        { value: 'MY', label: 'Malaisie' },
        { value: 'MW', label: 'Malawi' },
        { value: 'MV', label: 'Maldives' },
        { value: 'ML', label: 'Mali' },
        { value: 'MT', label: 'Malte' },
        { value: 'MA', label: 'Maroc' },
        { value: 'MQ', label: 'Martinique' },
        { value: 'MU', label: 'Maurice' },
        { value: 'MR', label: 'Mauritanie' },
        { value: 'YT', label: 'Mayotte' },
        { value: 'MX', label: 'Mexique' },
        { value: 'MD', label: 'Moldavie' },
        { value: 'MC', label: 'Monaco' },
        { value: 'MN', label: 'Mongolie' },
        { value: 'MS', label: 'Montserrat' },
        { value: 'ME', label: 'Monténégro' },
        { value: 'MZ', label: 'Mozambique' },
        { value: 'MM', label: 'Myanmar (Birmanie)' },
        { value: 'NA', label: 'Namibie' },
        { value: 'NR', label: 'Nauru' },
        { value: 'NI', label: 'Nicaragua' },
        { value: 'NE', label: 'Niger' },
        { value: 'NG', label: 'Nigéria' },
        { value: 'NU', label: 'Niue' },
        { value: 'NO', label: 'Norvège' },
        { value: 'NC', label: 'Nouvelle-Calédonie' },
        { value: 'NZ', label: 'Nouvelle-Zélande' },
        { value: 'NP', label: 'Népal' },
        { value: 'OM', label: 'Oman' },
        { value: 'UG', label: 'Ouganda' },
        { value: 'UZ', label: 'Ouzbékistan' },
        { value: 'PK', label: 'Pakistan' },
        { value: 'PW', label: 'Palaos' },
        { value: 'PA', label: 'Panama' },
        { value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
        { value: 'PY', label: 'Paraguay' },
        { value: 'NL', label: 'Pays-Bas' },
        { value: 'BQ', label: 'Pays-Bas caribéens' },
        { value: 'PH', label: 'Philippines' },
        { value: 'PL', label: 'Pologne' },
        { value: 'PF', label: 'Polynésie française' },
        { value: 'PR', label: 'Porto Rico' },
        { value: 'PT', label: 'Portugal' },
        { value: 'PE', label: 'Pérou' },
        { value: 'QA', label: 'Qatar' },
        { value: 'HK', label: 'R.A.S. chinoise de Hong Kong' },
        { value: 'MO', label: 'R.A.S. chinoise de Macao' },
        { value: 'RO', label: 'Roumanie' },
        { value: 'GB', label: 'Royaume-Uni' },
        { value: 'RU', label: 'Russie' },
        { value: 'RW', label: 'Rwanda' },
        { value: 'CF', label: 'République centrafricaine' },
        { value: 'DO', label: 'République dominicaine' },
        { value: 'EH', label: 'Sahara occidental' },
        { value: 'BL', label: 'Saint-Barthélemy' },
        { value: 'KN', label: 'Saint-Christophe-et-Niévès' },
        { value: 'SM', label: 'Saint-Marin' },
        { value: 'MF', label: 'Saint-Martin' },
        { value: 'SX', label: 'Saint-Martin (partie néerlandaise)' },
        { value: 'PM', label: 'Saint-Pierre-et-Miquelon' },
        { value: 'VC', label: 'Saint-Vincent-et-les-Grenadines' },
        { value: 'SH', label: 'Sainte-Hélène' },
        { value: 'LC', label: 'Sainte-Lucie' },
        { value: 'SV', label: 'Salvador' },
        { value: 'WS', label: 'Samoa' },
        { value: 'AS', label: 'Samoa américaines' },
        { value: 'ST', label: 'Sao Tomé-et-Principe' },
        { value: 'RS', label: 'Serbie' },
        { value: 'SC', label: 'Seychelles' },
        { value: 'SL', label: 'Sierra Leone' },
        { value: 'SG', label: 'Singapour' },
        { value: 'SK', label: 'Slovaquie' },
        { value: 'SI', label: 'Slovénie' },
        { value: 'SO', label: 'Somalie' },
        { value: 'SD', label: 'Soudan' },
        { value: 'SS', label: 'Soudan du Sud' },
        { value: 'LK', label: 'Sri Lanka' },
        { value: 'CH', label: 'Suisse' },
        { value: 'SR', label: 'Suriname' },
        { value: 'SE', label: 'Suède' },
        { value: 'SJ', label: 'Svalbard et Jan Mayen' },
        { value: 'SY', label: 'Syrie' },
        { value: 'SN', label: 'Sénégal' },
        { value: 'TJ', label: 'Tadjikistan' },
        { value: 'TZ', label: 'Tanzanie' },
        { value: 'TW', label: 'Taïwan' },
        { value: 'TD', label: 'Tchad' },
        { value: 'CZ', label: 'Tchéquie' },
        { value: 'TF', label: 'Terres australes françaises' },
        { value: 'IO', label: 'Territoire britannique de l’océan Indien' },
        { value: 'PS', label: 'Territoires palestiniens' },
        { value: 'TH', label: 'Thaïlande' },
        { value: 'TL', label: 'Timor oriental' },
        { value: 'TG', label: 'Togo' },
        { value: 'TK', label: 'Tokelau' },
        { value: 'TO', label: 'Tonga' },
        { value: 'TT', label: 'Trinité-et-Tobago' },
        { value: 'TN', label: 'Tunisie' },
        { value: 'TM', label: 'Turkménistan' },
        { value: 'TR', label: 'Turquie' },
        { value: 'TV', label: 'Tuvalu' },
        { value: 'UA', label: 'Ukraine' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'VU', label: 'Vanuatu' },
        { value: 'VE', label: 'Venezuela' },
        { value: 'VN', label: 'Vietnam' },
        { value: 'WF', label: 'Wallis-et-Futuna' },
        { value: 'YE', label: 'Yémen' },
        { value: 'ZM', label: 'Zambie' },
        { value: 'ZW', label: 'Zimbabwe' },
        { value: 'EG', label: 'Égypte' },
        { value: 'AE', label: 'Émirats arabes unis' },
        { value: 'EC', label: 'Équateur' },
        { value: 'ER', label: 'Érythrée' },
        { value: 'VA', label: 'État de la Cité du Vatican' },
        { value: 'FM', label: 'États fédérés de Micronésie' },
        { value: 'US', label: 'États-Unis' },
        { value: 'ET', label: 'Éthiopie' },
        { value: 'BV', label: 'Île Bouvet' },
        { value: 'CX', label: 'Île Christmas' },
        { value: 'NF', label: 'Île Norfolk' },
        { value: 'IM', label: 'Île de Man' },
        { value: 'KY', label: 'Îles Caïmans' },
        { value: 'CC', label: 'Îles Cocos' },
        { value: 'CK', label: 'Îles Cook' },
        { value: 'FO', label: 'Îles Féroé' },
        { value: 'HM', label: 'Îles Heard et McDonald' },
        { value: 'FK', label: 'Îles Malouines' },
        { value: 'MP', label: 'Îles Mariannes du Nord' },
        { value: 'MH', label: 'Îles Marshall' },
        { value: 'PN', label: 'Îles Pitcairn' },
        { value: 'SB', label: 'Îles Salomon' },
        { value: 'TC', label: 'Îles Turques-et-Caïques' },
        { value: 'VG', label: 'Îles Vierges britanniques' },
        { value: 'VI', label: 'Îles Vierges des États-Unis' },
        { value: 'UM', label: 'Îles mineures éloignées des États-Unis' },
        { value: 'AX', label: 'Îles Åland' }
      ]
    }
  },
  created () {
    const localeToISO6391 = {
      br: 'pt',
      de: 'de',
      en: 'en',
      es: 'es',
      fr: 'fr',
      il: 'he',
      it: 'it',
      jp: 'ja'
    }
    let countries = isoCountriesLanguages.getCountries(localeToISO6391[this.$i18n.locale])
    if (Object.entries(countries).length === 0) {
      countries = isoCountriesLanguages.getCountries('en')
    }
    this.countries = Object.entries(countries).map(v => ({ value: v[0], label: v[1] })).sort(function (a, b) {
      const textA = a.label.toUpperCase()
      const textB = b.label.toUpperCase()
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
    })
  },
  computed: {
    countriesComputed () {
      return this.countries.length !== 0
        ? this.countries
        : this.countries.map(country => ({
          ...country,
          label: data.countries.country.find(c => c.countryCode === country.value).countryName
        }))
          .sort(function (a, b) {
            const textA = a.label.toUpperCase()
            const textB = b.label.toUpperCase()
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
          })
    }
  },
  methods: {
    required
  }
}
</script>

<style lang="scss" scoped>

</style>
